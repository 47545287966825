import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { jaJP, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as AddIcon } from '../../icons/images/add-icon.svg';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import InsufficientLicenseDialog from '~/components/dialog/insufficient-license-dialog';
import LicenseStatementDialog from '~/components/dialog/license-statement-dialog';
import { GalleryData } from '~/components/gallery-list';
import HomeBtn from '~/components/home-btn';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { VIEW_MODE } from '~/enum/common';
import { env } from '~/env';
import {
  LicenseType,
  ListMyShopsDocument,
  MyShopQueryKey,
  QueryOperator,
  useBuyLicenseMutation,
  useDuplicateMyShopMutation,
  useGetInfoUsageLazyQuery,
  useListMyShopsQuery,
  useRemoveMyShopMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { SHOP_TYPE } from '~/types/my-shop';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export interface DisplayedMyShopData {
  id: string;
  [MyShopQueryKey.CreatedAt]: Date;
  url: string | null | undefined;
  [MyShopQueryKey.Title]: string | null | undefined;
  domain: string | null | undefined;
  category: string | null | undefined;
  description: string | null | undefined;
  [MyShopQueryKey.Publish]: boolean | null | undefined;
}

enum Action {
  Create = 'create',
  Duplicate = 'duplicate',
}

const getInitQuery = (shopType: string) => {
  return {
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(MyShopQueryKey, getLocalStorage(shopType + '_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage(shopType + '_list_order')),
    where: {
      fields: [
        {
          value: [shopType],
          operator: QueryOperator.Contains,
          key: MyShopQueryKey.ShopType,
        },
      ],
    },
  };
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTable: {
    '.image-list-thumbnail': {
      height: '64px',
      width: '64px',
      borderRadius: '4px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  wrapperTopTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 8px 8px',
  },
  shopURL: {
    color: '#000000DE',
    textDecoration: 'none',
    '&:hover': {
      color: '#1976d2',
      textDecoration: 'underline',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

const MyShop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { show } = usePaymentMethodRequired();
  const { plan: currentPlan, isLocked } = useAccount();
  const { showSuccess, showError, showErrorByKey } = useNotify();

  const [action, setAction] = useState<Action>();
  const [isChecking, setIsChecking] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DisplayedMyShopData>();
  const [openLicenseStatement, setOpenLicenseStatement] = useState(false);
  const [openRemoveMyShopDialog, setOpenRemoveMyShopDialog] = useState(false);

  const { shopType, isMemberSite, textClassification } = useMemo(() => {
    const _shopType = location.pathname.startsWith(AppRouteEnum.MemberSite) ? SHOP_TYPE.MEMBER : SHOP_TYPE.SHOP;
    const _isMemberSite = _shopType === SHOP_TYPE.MEMBER;
    const _textClassification = _isMemberSite
      ? {
          licenseItemId: LicenseType.AdditionalMemberSiteFee,
          licenseName: 'license.member_site',
          title: 'member_site.member_site',
          domain: AppRouteEnum.BuyerMemberSite,
          defaultOgp: '/default/ogp-member-site.png',
          detailRoute: AppRouteEnum.MemberSiteDetail,
          duplicated: 'toast_message.member_site_duplicated',
          deleted: 'toast_message.member_site_deleted',
          listRoute: AppRouteEnum.MemberSite,
          headerName: 'site_name',
          createRoute: AppRouteEnum.CreateMemberSite,
          createTitle: 'member_site.create_new_member_site',
          tableName: 'membersite',
          searchLabel: 'site_name',
          noDataTitle: 'create_new_membersite',
          noDataDescription: 'no_data.member_site',
          deleteTitle: 'member_site.delete_member_site',
          deleteDescription: 'member_site.remove_membersite',
          requiredPaymentDesc: 'member_sites_reached_limit_miss_payment',
        }
      : {
          licenseItemId: LicenseType.AdditionalShopFee,
          licenseName: 'license.shop',
          title: 'my_shop.shops',
          domain: AppRouteEnum.Shop,
          defaultOgp: '/default/ogp-shop.png',
          detailRoute: AppRouteEnum.ShopDetail,
          duplicated: 'toast_message.shop_duplicated',
          deleted: 'toast_message.shop_deleted',
          listRoute: AppRouteEnum.MyShop,
          headerName: 'my_shop.shop_name',
          createRoute: AppRouteEnum.CreateShop,
          createTitle: 'my_shop.create_shop',
          tableName: 'shop',
          searchLabel: 'my_shop.shop_name',
          noDataTitle: 'create_new_shop',
          noDataDescription: 'no_data.shop',
          deleteTitle: 'my_shop.message.delete_shop_message',
          deleteDescription: 'my_shop.message.confirm_delete_shop',
          requiredPaymentDesc: 'shops_reached_limit_miss_payment',
        };
    return { shopType: _shopType, isMemberSite: _isMemberSite, textClassification: _textClassification };
  }, [location]);

  useEffect(() => {
    document.title = t(textClassification.title);
  }, [t, textClassification]);

  const [shopQuery, setShopQuery] = useState(getInitQuery(shopType));

  useEffect(() => {
    setLocalStorageItems({
      [shopType + '_list_order']: shopQuery?.orderBy,
      [shopType + '_list_sort']: shopQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopQuery?.orderBy, shopQuery?.sortBy]);

  useEffect(() => {
    if (shopQuery?.where?.fields?.[0]?.value?.[0] !== shopType) {
      setShopQuery(getInitQuery(shopType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopType]);

  useEffect(() => {
    return () => {
      setMenuAnchorEl(undefined);
      setSelectedItem(undefined);
      setOpenRemoveMyShopDialog(false);
    };
  }, [shopType, i18n.language]);

  const updateShopQuery = (newValue: any) => setShopQuery((value: any) => ({ ...value, ...newValue }));

  const [buyLicense] = useBuyLicenseMutation();
  const [removeMyShop] = useRemoveMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
    onCompleted: () => updateShopQuery({ page: 1 }),
  });
  const [duplicateMyShop] = useDuplicateMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
    onCompleted: () => updateShopQuery({ page: 1 }),
  });
  const [getInfoUsage] = useGetInfoUsageLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: dataShops, loading: loadingListMyShops } = useListMyShopsQuery({
    fetchPolicy: 'cache-and-network',
    variables: shopQuery,
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShops?.listMyShops?.items || [];
    const pagination: ListTablePagination = dataShops?.listMyShops?.pagination || {};

    const _items: DisplayedMyShopData[] = items.map((item) => {
      return {
        id: item.uuid,
        domain: env.REACT_APP_CLIENT_URL + textClassification.domain.replace(/:shopName/g, item.domain?.name as string),
        [MyShopQueryKey.CreatedAt]: item.createdAt,
        [MyShopQueryKey.Title]: item.siteSetting?.title,
        category: item.siteSetting?.category,
        description: item.siteSetting?.description,
        [MyShopQueryKey.Publish]: item.publish,
        url: item.siteSetting?.ogp
          ? env.REACT_APP_API_MEDIA + '/' + item.siteSetting?.ogp
          : textClassification.defaultOgp,
      } as DisplayedMyShopData;
    });
    return { items: _items, pagination };
  }, [dataShops?.listMyShops?.items, dataShops?.listMyShops?.pagination, textClassification]);

  // Handle Menu
  const onOpenMenu = (myShop: DisplayedMyShopData) => (e: any) => {
    setSelectedItem(myShop);
    setMenuAnchorEl(e.currentTarget);
  };
  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  // Handle Remove Shop Dialog
  const onOpenRemoveMyShopDialog = () => {
    setOpenRemoveMyShopDialog(true);
    onCloseMenu();
  };
  const onCloseRemoveMyShopDialog = async () => {
    setOpenRemoveMyShopDialog(false);
  };

  // Handle Open Warning Dialog
  const handleOpenWarningDialog = () => {
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = async () => {
    setOpenWarningDialog(false);
  };

  // Handle Open License Statement
  const handleOpenLicenseStatement = () => {
    handleCloseWarningDialog();
    setOpenLicenseStatement(true);
  };
  const handleCloseLicenseStatement = async () => {
    setOpenLicenseStatement(false);
  };

  const licenseInfo = {
    quantity: 1,
    type: textClassification.licenseItemId,
  };

  const validateAvailability = async (callback: () => Promise<void> | void) => {
    if (isLocked) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }
    setIsChecking(true);
    const infoUsageRes = await getInfoUsage();
    const infoUsage = infoUsageRes.data?.getInfoUsage;
    if (!!infoUsageRes.error || !infoUsage) {
      setIsChecking(false);
      showError(infoUsageRes.error);
      return;
    }
    const enoughLicenses = isMemberSite
      ? infoUsage.numberMemberSite < infoUsage.memberSiteLicenses
      : infoUsage.numberShop < infoUsage.shopLicenses;
    if (enoughLicenses) {
      await callback();
      setIsChecking(false);
      return;
    }
    const notEnoughFreeLicenses = isMemberSite
      ? infoUsage.memberSiteLicenses >= (currentPlan?.numberOfMemberSites || 0)
      : infoUsage.shopLicenses >= (currentPlan?.numberOfShops || 0);
    if (notEnoughFreeLicenses) {
      const openSuccess = await show({
        title: 'license_limit_reached',
        description: textClassification.requiredPaymentDesc,
      });
      if (openSuccess) {
        setIsChecking(false);
        return;
      }
      handleOpenWarningDialog();
    } else {
      await buyLicense({
        variables: {
          input: licenseInfo,
        },
      });
      await validateAvailability(callback);
    }
    setIsChecking(false);
  };

  const redirectToCreateScreen = async () => {
    setAction(Action.Create);
    await validateAvailability(() => {
      navigate(textClassification.createRoute);
    });
  };

  const handleDuplicate = async () => {
    const duplicatedMyShop = await duplicateMyShop({ variables: { myShopUuid: selectedItem?.id || '' } });
    if (duplicatedMyShop) {
      navigate(textClassification.detailRoute.replace(/:id/g, duplicatedMyShop.data?.duplicateMyShop?.uuid || ''));
      showSuccess(textClassification.duplicated);
    }
  };

  const handleDuplicated = async () => {
    try {
      setMenuAnchorEl(undefined);
      setAction(Action.Duplicate);
      await validateAvailability(handleDuplicate);
    } catch (err) {
      showError(err);
    }
  };

  const handleRemove = async () => {
    try {
      if (selectedItem) {
        await removeMyShop({ variables: { myShopUuid: selectedItem?.id || '' } });
        showSuccess(textClassification.deleted);
      }
    } catch (err) {
      showError(err);
    }
  };

  const redirectToDetailScreen = useCallback(
    (id: string | undefined) => {
      if (!id) {
        return;
      }
      navigate(textClassification.listRoute + `/${id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textClassification, navigate]
  );

  const handleClickGalleryCard = (item: GalleryData) => {
    navigate(textClassification.listRoute + `/${item?.id}`);
  };

  const handleSuccessfulPurchase = async () => {
    if (action === Action.Create) {
      navigate(textClassification.createRoute);
    } else if (action === Action.Duplicate) {
      await handleDuplicate();
    }
  };

  const columns: GridColDef<DisplayedMyShopData>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value || '/default/ogp-shop.png'} onClick={() => redirectToDetailScreen(row.id)} />
            </Box>
          );
        },
      },
      {
        field: MyShopQueryKey.Title,
        headerName: t(textClassification.headerName),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MyShopQueryKey.Publish,
        headerName: t('status'),
        width: JSON.parse(columnsSize).name || 150,
        renderCell: ({ row }) => (
          <Chip
            label={row[MyShopQueryKey.Publish] ? t('open') : t('close')}
            color={row[MyShopQueryKey.Publish] ? 'success' : 'error'}
          />
        ),
      },
      {
        field: 'domain',
        sortable: false,
        headerName: t('url'),
        width: JSON.parse(columnsSize).domain || 400,
        renderCell: ({ value }) => {
          return (
            <Link target="_blank" className={classes.shopURL} href={value}>
              {value}
            </Link>
          );
        },
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: MyShopQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: (params) => {
          return [
            <IconButton onClick={onOpenMenu(params.row)}>
              <MoreVertIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            </IconButton>,
          ];
        },
      },
    ];
  }, [t, classes.shopURL, textClassification, redirectToDetailScreen]);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.secondary">{t(textClassification.title)}</Typography>
      </Breadcrumbs>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={() => redirectToDetailScreen(selectedItem?.id)}>{t('my_shop.show_detail')}</MenuItem>
        <MenuItem onClick={handleDuplicated}>{t('my_shop.duplicate')}</MenuItem>
        <MenuItem onClick={onOpenRemoveMyShopDialog}>{t('delete')}</MenuItem>
      </Menu>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={t(textClassification.title)}
          headerAction={
            <HeaderAction
              menus={[
                {
                  title: t(textClassification.createTitle),
                  onClick: redirectToCreateScreen,
                },
              ]}
            />
          }
          cardContent={
            <Box className={classes.wrapperTable}>
              <ListTable
                notSquare
                noBorder
                rows={items}
                columns={columns}
                isLoading={loadingListMyShops}
                tableName={textClassification.tableName}
                searchLabel={t(textClassification.searchLabel)}
                noDataProps={{
                  title: t(textClassification.noDataTitle),
                  description: t(textClassification.noDataDescription),
                  buttonTitle: t(textClassification.createTitle),
                  onClick: redirectToCreateScreen,
                }}
                onlyMode={VIEW_MODE.LIST}
                localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
                noRowsMessage={t(textClassification.noDataDescription)}
                onClickImage={handleClickGalleryCard}
                search={shopQuery.searchText}
                onSearch={(v) => updateShopQuery({ page: 1, searchText: v || '' })}
                paginationData={pagination}
                onPagination={updateShopQuery}
                sort={{
                  sortBy: shopQuery.sortBy,
                  orderBy: shopQuery.orderBy,
                }}
                onSort={updateShopQuery}
              />
            </Box>
          }
        />
        <Box className={classes.addBtn}>
          <Button
            color="primary"
            variant="contained"
            className="add-btn"
            disabled={isChecking}
            onClick={redirectToCreateScreen}
          >
            {isChecking ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <AddIcon />}
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog
        open={openRemoveMyShopDialog}
        title={t(textClassification.deleteTitle)}
        content={t(textClassification.deleteDescription)}
        onConfirm={handleRemove}
        onClose={onCloseRemoveMyShopDialog}
      />
      <InsufficientLicenseDialog
        open={openWarningDialog}
        type={textClassification.licenseItemId}
        onClose={handleCloseWarningDialog}
        onSubmit={handleOpenLicenseStatement}
      />
      <LicenseStatementDialog
        license={licenseInfo}
        open={openLicenseStatement}
        onNext={handleSuccessfulPurchase}
        onClose={handleCloseLicenseStatement}
      />
    </>
  );
};

export default memo(MyShop);
