import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Navigate } from 'react-router-dom';

import { useSession } from './with-session';

import CreateFirstOrganization from '~/components/create-first-organization';
import ScreenLoading from '~/components/screen-loading';
import SelectOrganization from '~/components/select-organization';
import TermsOfUse from '~/components/terms-of-use';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  GetCurrentPlanQuery,
  OrganizationRoleUser,
  OrganizationUserRole,
  UserOrs,
  useGetCurrentPlanQuery,
  useGetIsLockedShopQuery,
  useListAllOpenInvoicesQuery,
} from '~/graphql/member/types';

const AccountContext = createContext<{
  account: UserOrs;
  isLocked: boolean;
  lockTime: Date | undefined;
  hasUnpaidInvoices: boolean;
  selectedOrganization: OrganizationRoleUser;
  plan?: GetCurrentPlanQuery['getCurrentPlan'] | null;
  setSelectedOrganization: (uuid: string) => void;
}>({} as any);

export const useAccount = () => useContext(AccountContext);

export const useCheckPermissions = (
  rolesGroups: ((role: OrganizationUserRole) => Array<OrganizationUserRole[]>) | Array<OrganizationUserRole[]>
) => {
  const { selectedOrganization } = useAccount();
  const role = selectedOrganization.role;
  const result = [];
  if (typeof rolesGroups === 'function') {
    rolesGroups = rolesGroups(role);
  }
  for (let roles of rolesGroups) {
    result.push((roles || []).includes(role));
  }
  return result;
};

export const WithAccount: React.FC<React.PropsWithChildren> = (props) => {
  const { account, loadingAccount, errorAccount } = useSession();

  const [selectedOrganizationUuid, setSelectedOrganizationUuid] = useState(
    localStorage.getItem('organizationUuid') || ''
  );

  const organizationsObject = useMemo(() => {
    if (!account?.organizations) {
      return undefined;
    }
    return new Map(account.organizations.map((org) => [org.uuid, org]));
  }, [account?.organizations]);

  const selectedOrganization = organizationsObject?.get(selectedOrganizationUuid!);

  const { data: getIsLocked, loading: loadingGetIsLocked } = useGetIsLockedShopQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selectedOrganization,
  });
  const { data: listOpenInvoicesRes, loading: loadingOpenInvoices } = useListAllOpenInvoicesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selectedOrganization,
  });
  const { data: currentPlanRes, loading: loadingCurrentPlan } = useGetCurrentPlanQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selectedOrganization,
  });

  const lockInfo = getIsLocked?.getIsLockedShop;
  const currentPlan = currentPlanRes?.getCurrentPlan;
  const hasUnpaidInvoices = !!listOpenInvoicesRes?.listAllOpenInvoices.length;

  const selectOrganizationUuid = useCallback(async (uuid: string) => {
    localStorage.setItem('organizationUuid', uuid || '');
    setSelectedOrganizationUuid(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((!account && loadingAccount) || loadingCurrentPlan || loadingOpenInvoices || loadingGetIsLocked) {
    return <ScreenLoading />;
  }

  if (!!errorAccount) {
    return <Navigate to={AppRouteEnum.SignIn} replace={true} />;
  }

  if (!account) {
    return <TermsOfUse />;
  }

  if (!account.organizations.length) {
    return <CreateFirstOrganization user={account} />;
  }

  if (!selectedOrganization) {
    return <SelectOrganization organizations={account.organizations} onSelectedOrganization={selectOrganizationUuid} />;
  }

  return (
    <AccountContext.Provider
      value={{
        account,
        plan: currentPlan,
        hasUnpaidInvoices,
        selectedOrganization,
        lockTime: lockInfo?.lockTime,
        isLocked: !!lockInfo?.locked,
        setSelectedOrganization: selectOrganizationUuid,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
