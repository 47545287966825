import { ChangeEvent, useCallback, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ConditionPublish from '../ConditionPublish';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import InsufficientLicenseDialog from '~/components/dialog/insufficient-license-dialog';
import LicenseStatementDialog from '~/components/dialog/license-statement-dialog';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  GetMyShopDocument,
  GetPublishShopDocument,
  LicenseType,
  useDuplicateMyShopMutation,
  useGetInfoUsageLazyQuery,
  useRemoveMyShopMutation,
  useSetOpenShopMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';

const useStyles = makeStyles()((theme) => ({
  root: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: 'unset',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '.MuiFormControlLabel-root': {
      marginLeft: '5px',
      marginRight: '0px',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.MuiTypography-root': {
      color: '#111',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  publishDate: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: 400,
  },
}));

const HeaderAction = () => {
  const { data, editingAt, publishDate } = useShopDetail();

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { isLocked } = useAccount();
  const { show } = usePaymentMethodRequired();
  const { showError, showErrorByKey, showSuccess } = useNotify();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openPublishCondition, setOpenPublishCondition] = useState(false);
  const [openLicenseStatement, setOpenLicenseStatement] = useState(false);
  const [openCloseMyShopDialog, setOpenCloseMyShopDialog] = useState(false);
  const [openRemoveMyShopDialog, setOpenRemoveMyShopDialog] = useState(false);

  const isOpen = data?.publish || false;
  const isEditing = !!editingAt;

  const [setOpenShop] = useSetOpenShopMutation({
    refetchQueries: [GetMyShopDocument, GetPublishShopDocument],
  });
  const [removeMyShop] = useRemoveMyShopMutation({
    variables: {
      myShopUuid: id || '',
    },
  });
  const [duplicateMyShop] = useDuplicateMyShopMutation({
    variables: {
      myShopUuid: id || '',
    },
  });
  const [getInfoUsage] = useGetInfoUsageLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleOpen = useCallback(
    async (newValue: boolean) => {
      try {
        await setOpenShop({
          variables: {
            isOpen: newValue,
            myShopUuid: id ?? '',
          },
        });
        showSuccess('my_shop.message.update_successful');
      } catch (err: any) {
        showErrorByKey('toast_message.open_shop_unsuccessfully');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeOpen = async (_: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    if (isLocked) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }
    if (newValue) {
      setOpenPublishCondition(true);
    } else {
      setOpenCloseMyShopDialog(true);
    }
  };

  const handleClose = () => {
    setOpenPublishCondition(false);
  };

  // Handle Open Warning Dialog
  const handleOpenWarningDialog = () => {
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = async () => {
    setOpenWarningDialog(false);
  };

  // Handle Open License Statement
  const handleOpenLicenseStatement = () => {
    handleCloseWarningDialog();
    setOpenLicenseStatement(true);
  };
  const handleCloseLicenseStatement = async () => {
    setOpenLicenseStatement(false);
  };

  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveMyShopDialog(false);
  }, []);

  const handleRemoveShop = useCallback(async () => {
    try {
      await removeMyShop();
      navigate(AppRouteEnum.MyShop.replace(/:id/g, ''));
      showSuccess('toast_message.shop_deleted');
    } catch (err) {
      showError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, t]);

  const onOpenRemoveMyShopDialog = () => {
    setOpenRemoveMyShopDialog(true);
    onCloseMenu();
  };

  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const handleDuplicate = async () => {
    const duplicatedMyShop = await duplicateMyShop();
    if (duplicatedMyShop) {
      navigate(AppRouteEnum.ShopDetail.replace(/:id/g, duplicatedMyShop.data?.duplicateMyShop?.uuid || ''));
      showSuccess('toast_message.shop_duplicated');
    }
  };

  const handleDuplicated = async () => {
    try {
      setMenuAnchorEl(undefined);
      if (isLocked) {
        showErrorByKey('toast_message.organization_locked');
        return;
      }
      const infoUsageRes = await getInfoUsage();
      const infoUsage = infoUsageRes.data?.getInfoUsage;
      if (!infoUsage) {
        return;
      }
      const enoughLicenses = infoUsage.numberShop < infoUsage.shopLicenses;
      if (enoughLicenses) {
        await handleDuplicate();
        return;
      }
      const openSuccess = await show({
        title: 'license_limit_reached',
        description: 'shops_reached_limit_miss_payment',
      });
      if (openSuccess) {
        return;
      }
      handleOpenWarningDialog();
    } catch (err) {
      showError(err);
    }
    setMenuAnchorEl(undefined);
  };

  const licenseInfo = {
    quantity: 1,
    type: LicenseType.AdditionalShopFee,
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography>{t('my_shop.basic_site_setting')}</Typography>
        <Box>
          <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography className={classes.publishDate}>
          {`${t('my_shop.last_published_date')} : ${
            !!publishDate ? moment(publishDate).format(t('date_time_format')) : '-'
          }`}
        </Typography>
        <FormControlLabel
          control={<Switch checked={isOpen} disabled={isEditing} onChange={handleChangeOpen} />}
          label={isOpen ? <Chip color="success" label={t('open')} /> : <Chip color="error" label={t('close')} />}
        />
      </Box>
      <Box className={classes.menu}>
        <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={handleDuplicated}>{t('my_shop.duplicate')}</MenuItem>
        <MenuItem onClick={onOpenRemoveMyShopDialog}>{t('delete')}</MenuItem>
      </Menu>
      <ConditionPublish open={openPublishCondition} onClose={handleClose} onPassed={handleOpen} />
      <ConfirmationDialog
        open={openRemoveMyShopDialog}
        title={t('my_shop.message.delete_shop_message')}
        content={t('my_shop.message.confirm_delete_shop')}
        onConfirm={handleRemoveShop}
        onClose={onCloseRemoveMyShopDialog}
      />
      <ConfirmationDialog
        open={openCloseMyShopDialog}
        title={t('my_shop.message.close_shop_message')}
        content={t('my_shop.message.confirm_close_shop')}
        onConfirm={() => handleOpen(false)}
        onClose={() => setOpenCloseMyShopDialog(false)}
        confirmTitle={t('close')}
      />
      <InsufficientLicenseDialog
        open={openWarningDialog}
        type={LicenseType.AdditionalShopFee}
        onClose={handleCloseWarningDialog}
        onSubmit={handleOpenLicenseStatement}
      />
      <LicenseStatementDialog
        license={licenseInfo}
        open={openLicenseStatement}
        onNext={handleDuplicate}
        onClose={handleCloseLicenseStatement}
      />
    </Box>
  );
};

export default HeaderAction;
