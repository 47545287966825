import { FC, memo, useEffect, useRef, useState } from 'react';

import { IpfsImg, useIpfsGateways } from '@gu-corp/react-ipfs-media';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { formEditNFTCaptionSchema } from '~/config/yup';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { Currency } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import i18nMain from '~/i18n';
import { useEditShop } from '~/pages/edit-shop';
import { CardData, NFT_SHOP_TYPE, OBJECT_FIT, STATUS } from '~/types/my-shop';
import { priceWithSymbol } from '~/utils/common';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { getErrorText } from '~/utils/yup.util';

interface ProductCardProps {
  data: CardData;
  customName: string | undefined;
  onSaveEditCaption?: (info: CardData) => (data: { caption: string }) => Promise<void>;
}

const useStyles = makeStyles<{
  nftCardTextColor: string;
  nftCardBackgroundColor: string;
  collectionNftCardImageMode?: OBJECT_FIT;
  positionLabelSample?: (number | undefined)[] | null;
}>()(
  ({ breakpoints }, { nftCardTextColor, positionLabelSample, nftCardBackgroundColor, collectionNftCardImageMode }) => {
    const sm = breakpoints.up('sm').replace('@media', '@container');
    const smDown = breakpoints.down('sm').replace('@media', '@container');
    return {
      card: {
        height: '100%',
        display: 'flex',
        boxShadow: 'none',
        overflow: 'visible',
        borderRadius: '8px',
        flexDirection: 'column',
        backgroundColor: nftCardBackgroundColor || 'transparent',
        '& > .MuiCardContent-root': {
          flex: 1,
          minHeight: 0,
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '8px !important',
          [sm]: {
            padding: '12px 16px',
            paddingBottom: '12px !important',
          },
        },
      },
      wrapperImage: {
        width: '100%',
        cursor: 'pointer',
        paddingTop: '100%',
        overflow: 'hidden',
        position: 'relative',
        containerType: 'size',
        borderRadius: '8px 8px 0 0',
        '.MuiSkeleton-root': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        },
        '.soldOut': {
          zIndex: 2,
          top: '4%',
          right: '4%',
          height: '13cqw',
          fontSize: '6cqw',
          position: 'absolute',
          borderRadius: '13cqw',
          textTransform: 'uppercase',
          '.MuiChip-label': {
            paddingLeft: '4cqw',
            paddingRight: '4cqw',
          },
        },
        '.sample': {
          zIndex: 2,
          position: 'absolute',
          top: `${positionLabelSample?.[0] || 50}%`,
          left: `${positionLabelSample?.[1] || 50}%`,
          transform: 'translate(-50%, -50%) rotate(-15deg)',
          '.MuiTypography-subtitle2': {
            margin: 0,
            fontWeight: 300,
            fontSize: '14cqw',
            color: '#686465',
            whiteSpace: 'nowrap',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textShadow:
              '0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff',
          },
        },
      },
      image: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: collectionNftCardImageMode || OBJECT_FIT.COVER,
      },
      titleBox: {
        gap: '4px',
        display: 'flex',
        marginBottom: '4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.network': {
          width: '16px',
          aspectRatio: '1 / 1',
          position: 'relative',
          [sm]: {
            width: '28px',
          },
        },
      },
      title: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        '.MuiFormControl-root': {
          margin: 0,
          '.MuiInputBase-input': {
            padding: 0,
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '15px',
            [sm]: {
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        '.MuiIconButton-root': {
          padding: '4px',
          marginLeft: '4px',
          '.MuiSvgIcon-root': {
            fontSize: '12px',
          },
          [sm]: {
            padding: '5px',
            '.MuiSvgIcon-root': {
              fontSize: '14px',
            },
          },
        },
      },
      nftName: {
        fontWeight: 400,
        fontSize: '14px',
        overflow: 'hidden',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        color: nftCardTextColor,
        letterSpacing: '0.15px',
        textOverflow: 'ellipsis',
        [sm]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
      remain: {
        fontWeight: 500,
        display: 'block',
        fontSize: '14px',
        overflow: 'hidden',
        lineHeight: '32px',
        marginBottom: '6px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      desc: {
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.15px',
      },
      prices: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [smDown]: {
          justifyContent: 'center',
        },
        '.price': {
          fontWeight: 500,
          fontSize: '16px',
          overflow: 'hidden',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          textOverflow: 'ellipsis',
          '&:not(.free)': {
            color: nftCardTextColor,
          },
          [sm]: {
            fontSize: '20px',
            lineHeight: '32px',
          },
        },
      },
      shopDetailBox: {
        flex: 1,
        gap: '4px',
        minHeight: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [smDown]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      btnText: {
        minWidth: 0,
        minHeight: 0,
        padding: '2px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.46px',
        [smDown]: {
          width: '100%',
        },
        [sm]: {
          padding: '3px 7px',
          letterSpacing: '0.46px',
        },
        '.MuiButton-endIcon': {
          marginLeft: '4px',
          marginRight: '0',
        },
      },
    };
  }
);

const localTheme = createTheme();

interface NFTCaptionValue extends yup.InferType<typeof formEditNFTCaptionSchema> {}

const ProductCard: FC<ProductCardProps> = ({ data, customName, onSaveEditCaption }) => {
  const { name, nameJa, price, collectionUuid, additionalInfos, networkSymbol } = data;
  const { control: editShopControl, exchangeCurrency } = useEditShop();
  const { data: myShop } = useShopDetail();

  const { showError } = useNotify();
  const { t, i18n } = useTranslation();
  const { ipfsGateways } = useIpfsGateways();
  const { t: tMain } = useTranslation('translation', { i18n: i18nMain });

  const [showLabelSample, positionLabelSample, nftCardTextColor, nftCardBackgroundColor, collectionNftCardImageMode] =
    useWatch({
      control: editShopControl,
      name: [
        'showLabelSample',
        'positionLabelSample',
        'style.nftCardTextColor',
        'style.nftCardBackgroundColor',
        'style.collectionNftCardImageMode',
      ],
    });
  const { classes } = useStyles({
    nftCardTextColor,
    positionLabelSample,
    nftCardBackgroundColor,
    collectionNftCardImageMode: collectionNftCardImageMode as OBJECT_FIT,
  });

  const [loading, setLoading] = useState(true);
  const [isEditNFTCaption, setIsEditNFTCaption] = useState(false);
  const [extraInfos, setExtraInfos] = useState<{ url: string; ownerAddress: string }>();

  const ipfsImgRef = useRef<HTMLImageElement>(null);

  const isFree = price === 0;
  const isEnglish = i18n.language === 'en';
  const url = data?.url || extraInfos?.url;
  const defaultCaption = customName || (isEnglish ? name : nameJa) || '...';
  const isPreMint = myShop?.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;
  const isSoldOut = data.status === STATUS.SOLD_OUT || Number(data.maxMint) <= Number(data.currentMint);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NFTCaptionValue>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      caption: '',
    },
    resolver: yupResolver(formEditNFTCaptionSchema),
  });

  const handleLoadImage = () => {
    setLoading(false);
  };

  const handleEditCaption = async (params: { caption: string }) => {
    if (!!onSaveEditCaption) {
      await onSaveEditCaption(data)(params);
      setIsEditNFTCaption(false);
    }
  };

  useEffect(() => {
    setIsEditNFTCaption(false);
    reset({
      caption: defaultCaption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customName, i18n.language]);

  useEffect(() => {
    (async () => {
      try {
        if (!isPreMint) {
          const metadata = await getNFTMetadata(collectionUuid!, additionalInfos, ipfsGateways);
          setExtraInfos({
            url: metadata.metadataContent.image,
            ownerAddress: metadata.ownerAddress,
          });
        }
      } catch (err) {
        showError(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ipfsImgNode = ipfsImgRef.current;
    if (!isPreMint && !!url) {
      ipfsImgNode?.addEventListener('load', handleLoadImage);
    }
    return () => {
      ipfsImgNode?.removeEventListener('load', handleLoadImage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <Card className={classes.card}>
      <Box className={classes.wrapperImage}>
        {loading ? (
          <Box sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: 1 }}>
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
        ) : (
          showLabelSample &&
          isPreMint && (
            <Box className="sample">
              <Typography variant="subtitle2">{t('sample')}</Typography>
            </Box>
          )
        )}
        {!url || isPreMint ? (
          <CardMedia
            component="img"
            image={url || '/default/favicon-shop.png'}
            alt="Thumbnails"
            loading="lazy"
            className={classes.image}
            onLoad={handleLoadImage}
          />
        ) : (
          <IpfsImg crossOrigin="anonymous" ref={ipfsImgRef} url={url} loading="lazy" className={classes.image} />
        )}
        {isSoldOut && (
          <ThemeProvider theme={localTheme}>
            <Chip
              color="error"
              variant="filled"
              className="soldOut"
              label={t(isFree ? 'my_shop.out_of_stock' : 'my_shop.sold_out')}
            />
          </ThemeProvider>
        )}
      </Box>
      <CardContent>
        <Box className={classes.titleBox}>
          {!!defaultCaption && (
            <Box className={classes.title}>
              {isEditNFTCaption && !!onSaveEditCaption ? (
                <>
                  <Controller
                    name="caption"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        variant="standard"
                        error={!!errors.caption?.message}
                        helperText={getErrorText(errors.caption?.message, tMain)}
                        {...field}
                      />
                    )}
                  />
                  <IconButton onClick={handleSubmit(handleEditCaption)}>
                    <CheckCircleIcon color="info" />
                  </IconButton>
                </>
              ) : (
                <>
                  <Tooltip
                    title={defaultCaption}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className={classes.nftName}>{defaultCaption}</Typography>
                  </Tooltip>
                  {!!onSaveEditCaption && (
                    <IconButton onClick={() => setIsEditNFTCaption(true)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
          )}
          {!!networkSymbol && <img src={networkSymbol} alt="Network Symbol" className="network" />}
        </Box>
        <Box className={classes.shopDetailBox}>
          {typeof price === 'number' && (
            <Box className={classes.prices}>
              {isFree ? (
                <Typography variant="h6" color="primary" textTransform="uppercase" className="price free">
                  {t('free')}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" className="price">
                    {priceWithSymbol(price, myShop?.paymentMethod?.baseCurrency || Currency.Jpy)}
                  </Typography>
                  {myShop.siteSetting?.showPriceUsd && (
                    <Typography variant="subtitle2">
                      {`≈ ${priceWithSymbol(price * (exchangeCurrency || 1), Currency.Usd, 2)}`}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
          <Button variant="outlined" className={classes.btnText}>
            {isFree ? t('get_nft') : t('my_shop.purchase')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(ProductCard);
