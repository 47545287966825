import React, { useCallback, useState } from 'react';

import { WalletConnectorDialog, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as DefaultAvatar } from '../../../../icons/images/default-avatar.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../../../icons/images/expand-more.svg';

import UserMenu from './user-menu';

import { HEADER_HEIGHT } from '~/constants/layout';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';
import { truncateEthAddress } from '~/utils/string.utils';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    height: HEADER_HEIGHT,
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbarContentRight: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  avatar: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  avatarImg: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  languageText: {
    marginRight: 10,
  },
  logo: {
    color: '#3E5B72',
    height: 23,
    display: 'block',
    pointerEvents: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  connectBox: {
    color: '#757575',
  },
  userName: {
    color: '#757575',
    fontWeight: 500,
    fontSize: '14px',
  },
  userEmail: {
    color: '#757575',
    fontSize: '14px',
    paddingRight: '16px',
    borderRight: '1px solid #D7D7D7',
  },
  selectedOrg: {
    color: '#757575',
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: '16px',
    textOverflow: 'ellipsis',
    borderRight: '1px solid #D7D7D7',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '.MuiSvgIcon-root': {
      fill: '#0000008a',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  onToggleDrawer: () => void;
}
export const START_GUIDE_URL = 'https://doc.tokenstudio.gu.net/';

const HeaderBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, onToggleDrawer } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account: accountAddress } = useWalletAccount();
  const { account, selectedOrganization } = useAccount();

  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [openWalletConnectorDialog, setOpenWalletConnectorDialog] = useState(false);

  const onOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setUserMenuAnchorEl(event.currentTarget);
    },
    [setUserMenuAnchorEl]
  );
  const onCloseUserMenu = useCallback(() => {
    setUserMenuAnchorEl(null);
  }, [setUserMenuAnchorEl]);

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, t]);

  const onOpenWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(true);
  }, []);

  const onCloseWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(false);
  }, []);

  return (
    <>
      <UserMenu
        open={Boolean(userMenuAnchorEl)}
        onClose={onCloseUserMenu}
        anchorEl={userMenuAnchorEl}
        setAnchorEl={setUserMenuAnchorEl}
      />
      <WalletConnectorDialog open={openWalletConnectorDialog} onClose={onCloseWalletConnectorDialog} />
      <AppBar position="sticky" className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            <RouterLink to="/">
              <img className={classes.logo} src="/images/token-studio-logo.svg" alt="" />
            </RouterLink>
            <div className={classes.toolbarContentRight}>
              {!isTemporarySideBar && (
                <>
                  <ListItem sx={{ minWidth: 0, width: 'auto' }} disablePadding>
                    <Box className={classes.selectedOrg}>{selectedOrganization.name}</Box>
                  </ListItem>
                  <Typography className={classes.userName}>{account?.displayName}</Typography>
                  <Typography className={classes.userEmail}>{account?.email || '...'}</Typography>
                  <Box className={classes.connectBox}>
                    {!accountAddress ? (
                      <Button variant="outlined" onClick={onOpenWalletConnectorDialog}>
                        {t('connect_wallet')}
                      </Button>
                    ) : (
                      <CopyToClipboard text={accountAddress} onCopy={onCopyAddress}>
                        <Typography sx={{ cursor: 'pointer', fontSize: 14 }}>
                          {truncateEthAddress(accountAddress)}
                        </Typography>
                      </CopyToClipboard>
                    )}
                  </Box>
                  <div className={classes.avatar} onClick={onOpenUserMenu}>
                    {account?.photoURL ? (
                      <Avatar src={account?.photoURL} sx={{ width: 27, height: 27, marginRight: '4px' }} />
                    ) : (
                      <DefaultAvatar width={30} height={30} style={{ marginRight: '4px' }} />
                    )}
                    <ArrowDropDownIcon />
                  </div>
                </>
              )}

              {isTemporarySideBar && (
                <IconButton onClick={onToggleDrawer}>
                  <MenuIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderBar;
