import Stack from '@mui/material/Stack';

import DeleteOrganization from './DeleteOrganization';
import Operation from './Operation';

const General = () => {
  return (
    <Stack gap={2}>
      <Operation />
      <DeleteOrganization />
    </Stack>
  );
};

export default General;
