import { FC, MouseEventHandler, memo, useCallback, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { DisplayedCollectionsList } from '.';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { GalleryData } from '~/components/gallery-list';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { VIEW_MODE } from '~/enum/common';
import {
  GetMyShopDocument,
  ListMyShopCollectionsDocument,
  ListMyShopCollectionsFilter,
  MyShopCollectionQueryKey,
  OrderBy,
  useUnAttachMyShopCollectionsMutation,
} from '~/graphql/member/types';
import { COLLECTION_TYPE, MEMBER_SITE_NFT_STATUS, STATUS, SalesMethodEnum } from '~/types/my-shop';
import { getAddressLink, truncateEthAddress } from '~/utils/string.utils';

interface SalesCollectionsTabProps {
  loadingData: boolean;
  rows: DisplayedCollectionsList[];
  pagination: ListTablePagination;
  queryData: {
    page?: number;
    limit?: number;
    orderBy?: OrderBy;
    searchText?: string;
    sortBy?: MyShopCollectionQueryKey;
    where?: ListMyShopCollectionsFilter;
  };
  updateQuery: (params: {
    page?: number;
    limit?: number;
    orderBy?: OrderBy;
    searchText?: string;
    sortBy?: MyShopCollectionQueryKey;
    where?: ListMyShopCollectionsFilter;
  }) => void;
  openAttachCollectionDialog: () => void;
}
export interface CustomAnchorElProps {
  id: string;
  anchorEl: null | HTMLElement;
}

export const salesMethod = (t: TFunction) => ({
  [SalesMethodEnum.NFT_LIST_ONLY]: {
    title: t('my_shop.nft_list_only'),
    value: SalesMethodEnum.NFT_LIST_ONLY,
  },
  [SalesMethodEnum.ADVANCED_ONLY]: {
    title: t('my_shop.advanced_only'),
    value: SalesMethodEnum.ADVANCED_ONLY,
  },
  [SalesMethodEnum.NFT_LIST_AND_ADVANCED]: {
    title: t('my_shop.nft_list_and_advanced'),
    value: SalesMethodEnum.NFT_LIST_AND_ADVANCED,
  },
});

export const collectionStatus = (t: TFunction<'translation', undefined>) => {
  return {
    [STATUS.NOW_ON_SALE]: {
      title: t('my_shop.now_on_sale'),
      value: STATUS.NOW_ON_SALE,
    },
    [STATUS.SOLD_OUT]: {
      title: t('my_shop.sold_out'),
      value: STATUS.SOLD_OUT,
    },
    [STATUS.NOT_SELECTED]: {
      title: t('my_shop.not_selected'),
      value: STATUS.NOT_SELECTED,
    },
    [STATUS.SUSPENSION]: {
      title: t('suspension'),
      value: STATUS.SUSPENSION,
    },
    [MEMBER_SITE_NFT_STATUS.INVALID]: {
      title: t('member_site.invalid'),
      value: MEMBER_SITE_NFT_STATUS.INVALID,
    },
    [MEMBER_SITE_NFT_STATUS.VALID]: {
      title: t('member_site.validity'),
      value: MEMBER_SITE_NFT_STATUS.VALID,
    },
    [MEMBER_SITE_NFT_STATUS.EXPIRED]: {
      title: t('member_site.expired'),
      value: MEMBER_SITE_NFT_STATUS.EXPIRED,
    },
  };
};

export const collectionType = (t: TFunction<'translation', undefined>) => {
  return {
    [COLLECTION_TYPE.DIGITAL_COLLECTIVE]: {
      title: t('digital_collective'),
      value: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
    },
    [COLLECTION_TYPE.MEMBERSHIP]: {
      title: t('membership'),
      value: COLLECTION_TYPE.MEMBERSHIP,
    },
    [COLLECTION_TYPE.PROMOTION]: {
      title: t('promotion'),
      value: COLLECTION_TYPE.PROMOTION,
    },
  };
};

const SalesCollectionTab: FC<SalesCollectionsTabProps> = ({
  rows,
  loadingData,
  pagination,
  queryData,
  updateQuery,
  openAttachCollectionDialog,
}) => {
  const { data: memberSite } = useShopDetail();
  const { supportedNetworks } = useSupportedNetworks();

  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedCollection, setSelectedCollection] = useState<DisplayedCollectionsList>();
  const [openRemoveAttachCollectionDialog, setOpenRemoveAttachCollectionDialog] = useState(false);
  const [customAnchorEl, setCustomAnchorEl] = useState<{
    info: GalleryData;
    anchorEl: null | HTMLElement;
  } | null>(null);

  const [unattachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
  });

  // Handle Remove Collection Dialog
  const handleOpenRemoveCollectionDialog = () => {
    setCustomAnchorEl(null);
    setOpenRemoveAttachCollectionDialog(true);
    handleCloseMenu();
  };

  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveAttachCollectionDialog(false);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setCustomAnchorEl(null);
  }, []);

  const handleShowCollectionDetail = useCallback(
    (specifiedId?: string) => () => {
      navigate(
        AppRouteEnum.MemberSiteCollectionDetail.replace(/:id/g, id || '').replace(
          /:collectionId/g,
          specifiedId || selectedCollection?.[MyShopCollectionQueryKey.CollectionUuid] || ''
        )
      );
      handleCloseMenu();
    },
    [navigate, id, selectedCollection, handleCloseMenu]
  );

  const columns: GridColDef<DisplayedCollectionsList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage
                src={value}
                onClick={handleShowCollectionDetail(row[MyShopCollectionQueryKey.CollectionUuid])}
              />
            </Box>
          );
        },
      },
      {
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).name || 150,
        field: MyShopCollectionQueryKey.CollectionName,
      },
      {
        field: MyShopCollectionQueryKey.CollectionSymbol,
        headerName: t('symbol'),
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionContractAddress,
        headerName: t('contract_address'),
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                <Link
                  target="_blank"
                  href={getAddressLink(
                    supportedNetworks?.[row[MyShopCollectionQueryKey.CollectionNetwork]]?.blockExplorer,
                    formattedValue
                  )}
                >
                  {truncateEthAddress(formattedValue)}
                </Link>
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).contractAddress || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionOwnerAddress,
        headerName: t('owner_address'),
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                {truncateEthAddress(formattedValue)}
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionNetwork,
        headerName: t('network'),
        valueFormatter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
        width: JSON.parse(columnsSize).network || 150,
      },
      {
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        field: MyShopCollectionQueryKey.CollectionCreatedAt,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <IconButton onClick={(event) => handleClickMenu(event, row)}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
  }, [supportedNetworks, handleShowCollectionDetail, t]);

  const handleClickGalleryCard = (collection: any) => {
    navigate(
      AppRouteEnum.ShopCollectionDetail.replace(/:id/g, id || '').replace(
        /:collectionId/g,
        collection?.[MyShopCollectionQueryKey.CollectionUuid] || ''
      )
    );
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, row: DisplayedCollectionsList) => {
    setSelectedCollection(row);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteCollectionFromShop = async () => {
    try {
      await unattachCollectionShop({
        variables: {
          input: {
            shopUuid: memberSite?.uuid,
            collectionUuids: [selectedCollection?.[MyShopCollectionQueryKey.CollectionUuid]] as string[],
          },
        },
      });
      setAnchorEl(null);
      setOpenRemoveAttachCollectionDialog(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const updateShopQuery = (newValue: any) => updateQuery(newValue);

  const handleClickMoreMenu =
    (info: GalleryData): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      const newAnchorEl = {
        info,
        anchorEl: event.currentTarget,
      };
      setCustomAnchorEl(newAnchorEl);
      const newSelectCollection = rows.find((row) => row.id === newAnchorEl.info.id);
      setSelectedCollection(newSelectCollection);
    };

  return (
    <>
      <ListTable
        isMenu
        noBorder
        rows={rows}
        columns={columns}
        onlyMode={VIEW_MODE.LIST}
        search={queryData.searchText}
        tableName="sales_collection_tabs"
        searchLabel={t('my_shop.collection_name')}
        isLoading={loadingData}
        noDataProps={{
          title: t('attach_collection'),
          description: t('no_data.attach_collection_membersite'),
          buttonTitle: t('my_shop.attach_new_collection'),
          onClick: openAttachCollectionDialog,
        }}
        onSearch={(v) => updateQuery({ page: 1, searchText: v || '' })}
        onClickMenu={handleClickMoreMenu}
        onClickImage={handleClickGalleryCard}
        noRowsMessage={t('my_shop.message.no_attached_collection')}
        paginationData={pagination}
        onPagination={updateQuery}
        sort={{
          sortBy: queryData.sortBy,
          orderBy: queryData.orderBy,
        }}
        onSort={updateShopQuery}
      />
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleShowCollectionDetail()}>{t('my_shop.show_detail')}</MenuItem>
        <MenuItem onClick={handleOpenRemoveCollectionDialog}>{t('member_site.remove_from_membersite')}</MenuItem>
      </Menu>
      <Menu open={!!customAnchorEl} anchorEl={customAnchorEl?.anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleShowCollectionDetail()}>{t('my_shop.show_detail')}</MenuItem>
        <MenuItem onClick={handleOpenRemoveCollectionDialog}>{t('member_site.remove_from_membersite')}</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openRemoveAttachCollectionDialog}
        title={t('member_site.remove_from_membersite')}
        content={t('member_site.remove_confirm_from_membersite')}
        confirmTitle={t('remove')}
        onClose={onCloseRemoveMyShopDialog}
        onConfirm={handleDeleteCollectionFromShop}
      />
    </>
  );
};

export default memo(SalesCollectionTab);
