import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import CustomCardTable from '~/components/custom-card-table';
import DeleteOrganizationDialog from '~/components/dialog/delete-organization-dialog';

const DeleteOrganization = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <CustomCardTable
      cardTitle={t('settings.general.delete_organization')}
      cardContent={
        <Box paddingBottom={2}>
          <Typography variant="body1">{t('settings.general.delete_org_caption')}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button color="error" variant="contained" onClick={handleOpenDialog}>
              {t('delete')}
            </Button>
          </Box>
          <DeleteOrganizationDialog open={openDialog} onClose={handleCloseDialog} />
        </Box>
      }
    />
  );
};

export default DeleteOrganization;
