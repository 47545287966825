import { Navigate } from 'react-router-dom';

import { useSession } from './with-session';

import ScreenLoading from '~/components/screen-loading';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import ErrorPage from '~/pages/error';

export const WithoutAccount: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { account, loadingAccount, errorAccount } = useSession();

  if (loadingAccount) {
    return <ScreenLoading />;
  }

  if (errorAccount && !errorAccount.graphQLErrors.some((error) => error.message === 'Unauthenticated')) {
    return <ErrorPage code="401" />;
  }

  if (account && !errorAccount) {
    return <Navigate to={AppRouteEnum.Home} replace={true} />;
  }

  return <>{props.children}</>;
};
