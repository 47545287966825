import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog, { ICustomDialog } from '../custom-dialog';

import { NumberStringTextField } from '~/components/NumberStringTextField';
import { MAX_NUMBER } from '~/constants/common';
import { Currency, useExchangeCurrencyQuery } from '~/graphql/member/types';
import { getErrorText } from '~/utils/yup.util';

const formCurrencyChangeWarning = yup.object({
  exchangeRate: yup.string().minNumber(0).maxNumber(MAX_NUMBER).required(),
});

export interface FormCurrencyChangeWarningValues extends yup.InferType<typeof formCurrencyChangeWarning> {}

interface CurrencyChangeWarningDialogProps extends ICustomDialog {
  oldCurrency: Currency;
  newCurrency: Currency;
  onConfirm: (data: FormCurrencyChangeWarningValues) => Promise<void>;
}

const CurrencyChangeWarningDialog: FC<CurrencyChangeWarningDialogProps> = ({
  open,
  oldCurrency,
  newCurrency,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormCurrencyChangeWarningValues>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(formCurrencyChangeWarning),
  });

  const { loading: loadingExchangeRate } = useExchangeCurrencyQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      input: {
        from: oldCurrency,
        to: newCurrency,
      },
    },
    onCompleted: (data) => reset({ exchangeRate: data.exchangeCurrency.toFixed(4) }),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleConfirm = async (data: FormCurrencyChangeWarningValues) => {
    await onConfirm(data);
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      dialogTitle={t('my_shop.site_setting.currency_changed')}
      dialogContent={
        loadingExchangeRate ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="caption" whiteSpace="pre-wrap">
              {t('my_shop.site_setting.currency_change_warning_desc')}
            </Typography>
            <Stack gap={1} flexDirection="row" alignItems="center" marginTop={1}>
              <Typography variant="h6">{`1 ${oldCurrency} ≈`}</Typography>
              <Controller
                name="exchangeRate"
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    required
                    fullWidth
                    decimal={4}
                    margin="dense"
                    sx={{ flex: 1 }}
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors.exchangeRate?.message}
                    label={t('my_shop.site_setting.exchange_rate')}
                    helperText={getErrorText(errors.exchangeRate?.message, t)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{newCurrency}</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </>
        )
      }
      actions={[
        <Button color="primary" variant="outlined" onClick={handleClose} disabled={isSubmitting || loadingExchangeRate}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={isSubmitting || loadingExchangeRate}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(handleConfirm)}
        >
          {t('confirm')}
        </Button>,
      ]}
      onClose={handleClose}
    />
  );
};

export default CurrencyChangeWarningDialog;
