import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ReactComponent as FincodeLogo } from '../../../icons/images/fincode_logo.svg';
import { ReactComponent as StripeLogo } from '../../../icons/images/stripe_logo.svg';
import CustomDialog from '../custom-dialog';

import { formEditPaymentConfigSchema } from '~/config/yup';
import { SUPPORTED_CURRENCIES } from '~/constants/common';
import { ListPaymentConfigsDocument, PaymentGateway, useAddPaymentConfigMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import FincodeSetupGuide from '~/pages/setting/shop-payment/FincodeSetupGuide';
import StripeSetupGuide from '~/pages/setting/shop-payment/StripeSetupGuide';
import { getErrorText } from '~/utils/yup.util';

interface IAddPaymentConfigDialog {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}

interface FormValues extends yup.InferType<typeof formEditPaymentConfigSchema> {}

export const PAYMENT_GATEWAY_INFO = {
  [PaymentGateway.Fincode]: {
    title: 'Fincode',
    icons: <FincodeLogo width={20} />,
  },
  [PaymentGateway.Stripe]: {
    title: 'Stripe',
    icons: <StripeLogo width={20} />,
  },
};

const paymentGatewayOptions = Object.keys(PaymentGateway).map((gateway) => {
  const value = PaymentGateway[gateway as keyof typeof PaymentGateway];
  return (
    <MenuItem key={value} value={value}>
      {PAYMENT_GATEWAY_INFO[value].title}
    </MenuItem>
  );
});

const AddPaymentConfigDialog: FC<IAddPaymentConfigDialog> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useNotify();

  const [addPaymentConfig] = useAddPaymentConfigMutation({
    refetchQueries: [ListPaymentConfigsDocument],
  });

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      publicKey: '',
      secretKey: '',
      paymentGateway: PaymentGateway.Stripe,
    },
    resolver: yupResolver(formEditPaymentConfigSchema),
  });

  const paymentGateway = watch('paymentGateway');

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await addPaymentConfig({
        variables: {
          input: data,
        },
      });
      showSuccess('toast_message.added_successfully');
      handleClose();
    } catch (err) {
      showError(err);
    }
  };

  const guideline = paymentGateway === PaymentGateway.Fincode ? <FincodeSetupGuide /> : <StripeSetupGuide />;

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={handleClose}
      dialogTitle={t('settings.shop_payment.add_payment_config')}
      dialogContent={
        <>
          <Controller
            control={control}
            name="paymentGateway"
            render={({ field }) => (
              <TextField
                select
                fullWidth
                margin="dense"
                variant="outlined"
                disabled={isSubmitting}
                label={t('settings.shop_payment.payment_gateway')}
                error={!!errors.paymentGateway?.message}
                helperText={getErrorText(errors.paymentGateway?.message, t)}
                {...field}
              >
                {paymentGatewayOptions}
              </TextField>
            )}
          />
          <Typography variant="caption">{`${t('settings.shop_payment.supported_currencies')}: ${SUPPORTED_CURRENCIES[
            paymentGateway
          ].join(', ')}`}</Typography>
          {guideline}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label={t('name.thing')}
                disabled={isSubmitting}
                error={!!errors.name?.message}
                helperText={getErrorText(errors.name?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            name="publicKey"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={isSubmitting}
                label={t('settings.shop_payment.public_key')}
                error={!!errors.publicKey?.message}
                helperText={getErrorText(errors.publicKey?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            name="secretKey"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={isSubmitting}
                label={t('settings.shop_payment.secret_key')}
                error={!!errors.secretKey?.message}
                helperText={getErrorText(errors.secretKey?.message, t)}
                {...field}
              />
            )}
          />
        </>
      }
      actions={[
        <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('add')}
        </Button>,
      ]}
    />
  );
};

export default AddPaymentConfigDialog;
