/**
 * Deeply compares two values to determine if they are equal.
 * Supports objects, arrays, and primitive values.
 * The order of object keys and array elements does not affect the result.
 */
export const isDeepEqual = <T>(obj1: T, obj2: T): boolean => {
  // If both references are the same, return true immediately
  if (obj1 === obj2) return true;

  // If either value is not an object or is null, return false
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  // Get object keys and compare their length
  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];
  if (keys1.length !== keys2.length) return false;

  // Iterate over keys to compare values
  for (let key of keys1) {
    if (!keys2.includes(key)) return false;

    const val1 = obj1[key];
    const val2 = obj2[key];

    // If both values are arrays, use array comparison
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!isArrayEqual(val1 as unknown[], val2 as unknown[])) return false;
    }
    // Recursively compare objects and primitive values
    else if (!isDeepEqual(val1, val2)) {
      return false;
    }
  }

  return true;
};

/**
 * Compares two arrays deeply without considering element order.
 */
const isArrayEqual = (arr1: unknown[], arr2: unknown[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  // Clone and sort arrays for accurate comparison
  const sortedArr1 = [...arr1].sort(compareValues);
  const sortedArr2 = [...arr2].sort(compareValues);

  return sortedArr1.every((val, index) => isDeepEqual(val, sortedArr2[index]));
};

/**
 * Custom sorting function for array elements.
 * It ensures objects are compared based on their JSON string representation.
 */
const compareValues = (a: unknown, b: unknown): number => {
  // If both values are objects, compare their JSON string representations
  if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
    return JSON.stringify(a).localeCompare(JSON.stringify(b));
  }

  // If both values are strings, compare them directly
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  // If both values are numbers, compare numerically
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  // If both values are booleans, compare them as numbers (true = 1, false = 0)
  if (typeof a === 'boolean' && typeof b === 'boolean') {
    return Number(a) - Number(b);
  }

  // Default case: Convert to string and compare lexicographically
  return String(a).localeCompare(String(b));
};
