import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import 'moment/locale/ja';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CollapsibleRow from './CollapsibleRow';

import LoaderCenter from '~/components/loader-center';
import { INVOICE_ITEM_NAME, ITEMS_PER_PAGE } from '~/constants/common';
import { InvoiceType } from '~/enum/common';
import { useGetPlanLazyQuery, useListInvoicesQuery } from '~/graphql/member/types';
import useExportCSV from '~/hooks/useExportCSV';
import { CustomInvoiceType, MonthlyTransactionsType } from '~/types/billing';

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const MonthlyTransactions: MonthlyTransactionsType = ({ pageModel, filterValue, onPageModel }, ref) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { exportToCSV } = useExportCSV();

  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const transactions = useRef<CustomInvoiceType[]>([]);

  const { data: transactionsRes, loading: loadingTransactions } = useListInvoicesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        createdGte: moment(filterValue, 'YYYY/MM').toDate(),
        createdLte: moment(filterValue, 'YYYY/MM').endOf('month').toDate(),
        startingAfter: pageModel.pageParams[pageModel.page],
      },
    },
  });

  const [getPlan] = useGetPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const hasMore = transactionsRes?.listInvoices.hasMore;
  const transactionHistory = transactionsRes?.listInvoices.items;
  const pagination = { currentPage: pageModel.page, itemsPerPage: ITEMS_PER_PAGE.LIST };

  const processingData = useCallback(async () => {
    setIsLoading(true);
    if (!transactionHistory?.length) {
      transactions.current = [];
      setIsLoading(false);
      setRowCount(0);
      return;
    }
    const totalPreviousPages = ITEMS_PER_PAGE.LIST * pagination.currentPage;
    const currentPageLength = transactionHistory.length;
    const estimateNextPage = hasMore ? ITEMS_PER_PAGE.LIST : 0;

    const _rowCount = totalPreviousPages + currentPageLength + estimateNextPage;
    setRowCount(_rowCount);

    const _transactions: CustomInvoiceType[] = [];
    const plansList: Record<string, string> = {};
    for (let invoice of transactionHistory) {
      const metadata = invoice.metadata;
      const type = metadata?.type as InvoiceType;
      let name = t(INVOICE_ITEM_NAME[type]);

      if (type === InvoiceType.PurchasePlan && !!metadata?.uuid) {
        if (!plansList[metadata.uuid]) {
          const planRes = await getPlan({
            variables: {
              uuid: metadata.uuid,
            },
          });
          plansList[metadata.uuid] = planRes.data?.getPlan.planName || '';
        }
        const planName = plansList[metadata.uuid];
        if (!!planName) {
          moment.locale(i18n.language);
          const nextMonth = moment().add(1, 'month').format('MMMM');
          const isRenew = invoice.billingReason === 'subscription_cycle';

          name = isRenew
            ? t('settings.billing.renew_plan_desc', { planName, month: nextMonth })
            : t('settings.billing.register_plan', { planName });

          moment.locale('en');
        }
      }
      _transactions.push({
        ...invoice,
        name,
      });
    }
    transactions.current = _transactions;

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, hasMore, transactionHistory]);

  useImperativeHandle(
    ref,
    () => ({
      handleExportCurrentPage: () =>
        exportToCSV(
          transactions.current.map((trans) => ({
            'Invoice Number': trans.number,
            Description: trans.name,
            Status: trans.status,
            Currency: trans.currency,
            Total: trans.total,
            Tax: trans.tax,
            Date: moment(trans.createdAt).format(t('date_time_format')),
          })),
          `invoices-${moment(filterValue).format('MM-YYYY')}-page-${pagination.currentPage + 1}`
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, filterValue, pagination.currentPage]
  );

  const handleChangePage = (_: any, newPage: number) => {
    onPageModel((prev) => ({
      ...prev,
      page: newPage,
    }));
    if (!!pageModel.pageParams?.[newPage] || !hasMore || newPage <= 0) return;

    const lastId = transactionHistory?.[transactionHistory.length - 1]?.id;
    if (!lastId) return;

    onPageModel((prev) => ({
      ...prev,
      pageParams: {
        ...prev.pageParams,
        [newPage]: lastId,
      },
    }));
  };

  const listStatus = useMemo(
    () => ({
      paid: {
        color: 'success',
        label: t('success'),
        icon: <CheckCircleIcon fontSize="small" />,
      },
      open: {
        color: 'warning',
        label: t('pending'),
        icon: <BlurCircularIcon fontSize="small" />,
      },
      draft: {
        color: undefined,
        label: t('draft'),
        icon: <DescriptionOutlinedIcon fontSize="small" />,
      },
      void: {
        color: 'error',
        label: t('failed'),
        icon: <CancelIcon fontSize="small" />,
      },
    }),
    [t]
  );

  useEffect(() => {
    processingData();
  }, [processingData]);

  return (
    <Box className={classes.wrapper}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell width="66px" />
              <TableCell>{t('item_name')}</TableCell>
              <TableCell>{t('settings.amount')}</TableCell>
              <TableCell>{t('status')}</TableCell>
              <TableCell>{t('settings.payment_method')}</TableCell>
              <TableCell>{t('created_at')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || loadingTransactions ? (
              <TableRow sx={{ height: '200px' }}>
                <TableCell colSpan={6}>
                  <LoaderCenter />
                </TableCell>
              </TableRow>
            ) : !transactions.current.length ? (
              <TableRow sx={{ height: '200px' }}>
                <TableCell colSpan={6} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            ) : (
              transactions.current.map((row, idx) => <CollapsibleRow key={idx} row={row} listStatus={listStatus} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        page={pagination.currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={ITEMS_PER_PAGE.LIST}
        rowsPerPageOptions={[ITEMS_PER_PAGE.LIST]}
      />
    </Box>
  );
};

export default forwardRef(MonthlyTransactions);
