import React, { useCallback, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { WalletConnectorDialog, useDisconnect, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import AddIcon from '@mui/icons-material/Add';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import PublicIcon from '@mui/icons-material/Public';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { orange, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import LanguageMenu from '../app-layout/user-layout/header-bar/language-menu';
import CreateOrganizationDialog from '../create-organization-dialog';
import LinkButton from '../link-button';
import PermissionsView from '../PermissionsView';

import CurrentPlanBox from './CurrentPlanBox';
import NavigatorItem, { NavigatorItemConfig } from './navigator-item';

import { ReactComponent as DefaultAvatar } from 'src/icons/images/default-avatar.svg';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { colors } from '~/constants/colors';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useLogout } from '~/hooks/use-logout';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';
import { truncateEthAddress } from '~/utils/string.utils';

const { VIEW } = SCREEN_PERMISSION.SETTING.BILLING;

const useStyles = makeStyles()(() => ({
  listItem: {
    maxHeight: 48,
    borderRadius: 4,
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
  itemText: {
    span: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
  },
  paper: {
    width: 290,
  },
  sideBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  languageMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#757575',
    '.MuiTypography-root': {
      marginLeft: '17px',
    },
    '.MuiButtonBase-root': {
      padding: '0!important',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  config: NavigatorItemConfig[];
  isTemporarySideBar?: boolean;
  onCloseDrawer?: () => void;
}

const NavigatorMenu: React.FC<Props> = (props) => {
  const { config, isTemporarySideBar, onCloseDrawer = () => {} } = props;
  const logout = useLogout();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { t, i18n } = useTranslation();
  const { disconnect } = useDisconnect();
  const { enqueueSnackbar } = useSnackbar();
  const { account: accountAddress } = useWalletAccount();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { account, isLocked, lockTime, hasUnpaidInvoices, selectedOrganization, setSelectedOrganization } =
    useAccount();

  const [loading, setLoading] = useState(false);
  const [orgMenuAnchorEl, setOrgMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [openWalletConnectorDialog, setOpenWalletConnectorDialog] = useState(false);
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<HTMLElement | null>(null);

  const LANGS = useMemo(
    () => ({
      en: t('english'),
      ja: t('japanese'),
    }),
    [t]
  );

  const onOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setLanguageMenuAnchorEl(event.currentTarget);
    },
    [setLanguageMenuAnchorEl]
  );
  const onCloseLanguageMenu = useCallback(() => {
    setLanguageMenuAnchorEl(null);
  }, [setLanguageMenuAnchorEl]);

  const onOpenOrgMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setOrgMenuAnchorEl(event.currentTarget);
    },
    [setOrgMenuAnchorEl]
  );
  const onCloseOrgMenu = useCallback(() => {
    setOrgMenuAnchorEl(null);
  }, [setOrgMenuAnchorEl]);

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, t]);

  const onOpenWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(true);
  }, []);

  const onCloseWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(false);
  }, []);

  const onCloseCreateOrganizationDialog = useCallback(() => {
    setOpenCreateOrganizationDialog(false);
  }, []);

  const onOpenCreateOrganizationDialog = useCallback(async () => {
    setOpenCreateOrganizationDialog(true);
  }, []);

  const closeDrawer = () => {
    if (isTemporarySideBar) onCloseDrawer();
  };

  const onLogout = async () => {
    try {
      setLoading(true);
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const renderNavigatorItems = useMemo(
    () =>
      config
        .filter((item) => {
          if (item === 'divider' || !item.roles) {
            return true;
          }

          return item.roles.includes(selectedOrganization?.role);
        })
        .map((item, index) => <NavigatorItem item={item} key={index} />),
    [config, selectedOrganization]
  );

  const warningInfo = useMemo(() => {
    const today = moment();
    const startOfMonth = moment().startOf('month');
    const endOfFirstWeek = moment(lockTime);
    const isInFirstSevenDays = today.isSameOrAfter(startOfMonth) && today.isBefore(endOfFirstWeek);

    return isLocked
      ? {
          color: red[900],
          buttonColor: 'error' as 'error',
          backgroundColor: red[50],
          title: t('settings.billing.payment_overdue'),
          description: t('settings.billing.payment_overdue_desc'),
        }
      : {
          color: orange[900],
          buttonColor: 'warning' as 'warning',
          backgroundColor: orange[50],
          title: t('settings.billing.have_unpaid_invoices'),
          description: isInFirstSevenDays
            ? t('settings.billing.have_unpaid_invoices_desc', {
                startDate: endOfFirstWeek.format(t('date_time_format')),
              })
            : t('settings.billing.have_unpaid_invoices_desc_2'),
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, lockTime, i18n.language]);

  const warningUnpaidInvoices = (
    <Box sx={{ marginTop: '20px', padding: '16px', borderRadius: '8px', backgroundColor: warningInfo.backgroundColor }}>
      <Box gap="8px" display="flex">
        <ReportOutlinedIcon sx={{ color: warningInfo.color }} />
        <Stack gap={0.5}>
          <Typography variant="body1" fontWeight={500} color={warningInfo.color} whiteSpace="pre-wrap">
            {warningInfo.title}
          </Typography>
          <Typography
            variant="caption"
            lineHeight="20px"
            whiteSpace="pre-wrap"
            display="inline-block"
            color={warningInfo.color}
          >
            {warningInfo.description}
          </Typography>
        </Stack>
      </Box>
      <PermissionsView roles={VIEW}>
        <LinkButton
          fullWidth
          variant="contained"
          sx={{ marginTop: '16px' }}
          color={warningInfo.buttonColor}
          to={`${AppRouteEnum.Setting}?tab=1`}
        >
          {t('details')}
        </LinkButton>
      </PermissionsView>
    </Box>
  );

  return (
    <Stack minHeight="100%" padding={isTemporarySideBar ? '16px 12px' : 0}>
      <Toolbar />
      <CreateOrganizationDialog
        open={openCreateOrganizationDialog}
        onClose={onCloseCreateOrganizationDialog}
        onSelectedOrganization={setSelectedOrganization}
      />
      <WalletConnectorDialog open={openWalletConnectorDialog} onClose={onCloseWalletConnectorDialog} />
      {isTemporarySideBar && (
        <>
          <MenuItem>
            <ListItemAvatar>
              {account.photoURL ? (
                <Avatar variant="rounded" src={account.photoURL} />
              ) : (
                <Box display="flex" alignItems="center">
                  <DefaultAvatar width={40} height={40} />
                </Box>
              )}
            </ListItemAvatar>
            <ListItemText primary={account?.displayName} secondary={account?.email} />
          </MenuItem>
          <Button
            style={{ marginTop: 4, marginBottom: 8 }}
            fullWidth
            color="gray"
            variant="outlined"
            onClick={accountAddress ? disconnect : onOpenWalletConnectorDialog}
          >
            {accountAddress ? t('disconnect_wallet') : t('connect_wallet')}
          </Button>
          {!!accountAddress && (
            <CopyToClipboard text={accountAddress} onCopy={onCopyAddress}>
              <Typography sx={{ cursor: 'pointer' }}>{truncateEthAddress(accountAddress)}</Typography>
            </CopyToClipboard>
          )}
          <ListItemButton onClick={onOpenOrgMenu} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <ApartmentIcon fontSize="small" style={{ fill: colors.black }} />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary={selectedOrganization?.name} />
            <ExpandMoreIcon />
          </ListItemButton>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(orgMenuAnchorEl)}
            onClose={onCloseOrgMenu}
            anchorEl={orgMenuAnchorEl}
            classes={{ paper: classes.paper }}
          >
            {(account?.organizations || []).map((organization) => (
              <MenuItem
                key={organization.uuid}
                selected={organization.uuid === selectedOrganization.uuid}
                onClick={() => {
                  setSelectedOrganization(organization.uuid);
                  onCloseOrgMenu();
                  navigate(AppRouteEnum.Home);
                  client.refetchQueries({ include: 'active' });
                }}
              >
                <ListItemText primary={organization.name} sx={{ whiteSpace: 'pre-wrap' }} />
              </MenuItem>
            ))}
            <MenuItem
              onClick={() => {
                onOpenCreateOrganizationDialog();
                onCloseOrgMenu();
              }}
            >
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              {t('create')}
            </MenuItem>
          </Menu>
        </>
      )}
      <Box className={classes.sideBar} sx={{ padding: isTemporarySideBar ? 0 : '16px 12px' }}>
        <Box sx={{ flex: 1, height: '100%' }} onClick={closeDrawer}>
          {renderNavigatorItems}
        </Box>
        {hasUnpaidInvoices && warningUnpaidInvoices}
        <CurrentPlanBox />
        {!isTemporarySideBar && (
          <>
            <Divider sx={{ margin: '0 0 20px' }} />
            <Button
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
              onClick={onOpenLanguageMenu}
              color="inherit"
              className={classes.languageMenu}
            >
              <Box sx={{ display: 'flex', paddingLeft: '8px', textTransform: 'capitalize' }}>
                <PublicIcon />
                <Typography>{LANGS[i18n.language as 'en' | 'ja']}</Typography>
              </Box>
              <ExpandMoreIcon />
            </Button>
            <LanguageMenu
              open={Boolean(languageMenuAnchorEl)}
              onClose={onCloseLanguageMenu}
              anchorEl={languageMenuAnchorEl}
            />
          </>
        )}
      </Box>
      {isTemporarySideBar && (
        <>
          <ListItemButton onClick={onLogout} className={classes.listItem} disabled={loading}>
            <ListItemIcon className={classes.listItemIcon}>
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <LogoutIcon style={{ fill: colors.black }} fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={onOpenLanguageMenu} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <PublicIcon style={{ fill: colors.black }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={LANGS[i18n.language as 'en' | 'ja']} />
            <ExpandMoreIcon />
          </ListItemButton>
          <LanguageMenu
            open={Boolean(languageMenuAnchorEl)}
            onClose={onCloseLanguageMenu}
            anchorEl={languageMenuAnchorEl}
          />
        </>
      )}
    </Stack>
  );
};

export default NavigatorMenu;
