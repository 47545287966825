import { isAddress } from 'ethers';
import * as yup from 'yup';

import { Currency, PaymentGateway } from '~/graphql/member/types';
import { FONT, TEMPLATE_OPTIONS } from '~/types/my-shop';

export const formEditCollectionFromShopSchema = yup.object({
  desc: yup.string(),
  descJa: yup.string(),
  name: yup.string().max(100).required(),
  nameJa: yup.string().max(100).required(),
});

export const formEditNFTCaptionsSchema = yup.object({
  name: yup.string().max(100).required(),
  nameJa: yup.string().max(100).required(),
});

export const formEditPaymentConfigSchema = yup.object({
  name: yup.string().max(255).required(),
  secretKey: yup.string().max(255).required(),
  publicKey: yup.string().max(255).required(),
  paymentGateway: yup.mixed<PaymentGateway>().oneOf(Object.values(PaymentGateway)).required(),
});

export const formTransferOwnershipSchema = yup.object({
  ownerAddress: yup
    .string()
    .test('isAddress', 'form_validation.isEthAddress', (value) => !value || isAddress(value))
    .required(),
});

export const formEditNFTCaptionSchema = yup.object({
  caption: yup.string().max(100).required(),
});

export const formEditCollectionCaptionSchema = yup.object({
  caption: yup.string().max(100).required(),
});

export const formEditShopSchema = yup.object({
  thema: yup
    .object({
      template: yup.mixed<TEMPLATE_OPTIONS>().nullable().oneOf(Object.values(TEMPLATE_OPTIONS)).required(),
      bannerImage: yup.string().nullable(),
      bannerImageFile: yup
        .mixed<File[]>()
        .nullable()
        .transform((value: File[]) => {
          if (value && value.length === 0) {
            return undefined;
          }
          return value;
        })
        .when('template', {
          is: (value: string) => value === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_1,
          then: (schema) =>
            schema.when('bannerImage', {
              is: (value: string) => !value,
              then: (schema) =>
                schema.test('required', 'form_validation.isNotEmpty', (value) => (value ? value.length !== 0 : false)),
            }),
        })
        .test({
          name: 'fileSize',
          message: 'form_validation.max_file_size',
          test: (value) => {
            if (!value) {
              return true;
            }
            return value[0].size < 10000000;
          },
        }),
      ratioBannerImage: yup.string().nullable(),
      ratioBannerImageFile: yup
        .mixed<File[]>()
        .nullable()
        .transform((value: File[]) => {
          if (value && value.length === 0) {
            return undefined;
          }
          return value;
        })
        .when('template', {
          is: (value: string) => value === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2,
          then: (schema) =>
            schema.when('ratioBannerImage', {
              is: (value: string) => !value,
              then: (schema) =>
                schema.test('required', 'form_validation.isNotEmpty', (value) => (value ? value.length !== 0 : false)),
            }),
        })
        .test({
          name: 'fileSize',
          message: 'form_validation.max_file_size',
          test: (value) => {
            if (!value) {
              return true;
            }
            return value[0].size < 10000000;
          },
        }),
    })
    .nullable(),
  style: yup
    .object({
      spacing: yup.number().nullable().required(),
      spacingSm: yup.number().nullable().required(),
      textColor: yup.string().nullable().required(),
      titleColor: yup.string().nullable().required(),
      buttonColor: yup.string().nullable().required(),
      headerColor: yup.string().nullable().required(),
      headerTextColor: yup.string().nullable().required(),
      backgroundColor: yup.string().nullable().required(),
      collectionNftCardImageMode: yup.string().nullable(),
      descriptionColor: yup.string().nullable().required(),
      collectionTitleColor: yup.string().nullable().required(),
      nftCardTextColor: yup.string().nullable().required(),
      nftCardBackgroundColor: yup.string().nullable().required(),
      collectionBorderColor: yup.string().nullable().required(),
      font: yup.mixed<FONT>().nullable().oneOf(Object.values(FONT)).required(),
    })
    .nullable(),
  navi: yup
    .object({
      pageTitle: yup.string().nullable().required(),
      pageTitleJa: yup.string().nullable().required(),
      description: yup.string().nullable(),
      descriptionJa: yup.string().nullable(),
    })
    .nullable(),
  showLabelSample: yup.boolean().nullable(),
  positionLabelSample: yup.array().of(yup.number()).nullable(),
});

export const formBasicSettingShop = yup.object({
  siteName: yup.string().nullable().max(100).required(),
  metaDescription: yup.string().nullable().max(1000),
  ogpFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  logoFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  faviconFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  ogp: yup.string().nullable(),
  logo: yup.string().nullable(),
  favicon: yup.string().nullable(),
  domainName: yup
    .string()
    .nullable()
    .max(100)
    .matches(/^[a-zA-Z0-9_-]*$/, { message: 'form_validation.shop_url_validation', excludeEmptyString: true })
    .required(),
  showPriceUsd: yup.boolean().required(),
  paymentConfig: yup.string().required(),
  currency: yup.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  category: yup.string().nullable().required(),
});
