import { ApolloError } from '@apollo/client';

export const delay = (timeout: number) => new Promise<void>((done) => setTimeout(done, timeout));

export const shouldShowSnackbar = (error: any) => {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors.some((error) => (error.extensions.originalError as any).error === 'Unauthenticated')) {
      return false;
    }
  }
  return true;
};
