import { FC } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import CustomDialog from '../custom-dialog';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { redirectToNewTab } from '~/utils/common';

export interface IDependentShop {
  uuid: string;
  name: string;
  domain: string;
}

interface ListDependentShopsDialogProps {
  open: boolean;
  dependentShops: IDependentShop[];
  onClose: () => void;
}

const ListDependentShopsDialog: FC<ListDependentShopsDialogProps> = ({ open, dependentShops, onClose }) => {
  const { t } = useTranslation();

  const handleClick = (shopUuid: string) => () => {
    redirectToNewTab(window.location.origin + AppRouteEnum.ShopDetail.replace(/:id/g, shopUuid));
  };

  return (
    <CustomDialog
      open={open}
      dialogTitle={t('settings.shop_payment.dependent_shops_list')}
      dialogContent={
        <>
          <Typography variant="body1">{t('settings.shop_payment.list_dependent_shops_desc')}</Typography>
          <List>
            {dependentShops.map((shop, idx) => (
              <ListItem
                key={shop.uuid}
                secondaryAction={
                  <IconButton edge="end" aria-label="go-detail" onClick={handleClick(shop.uuid)}>
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={`${idx + 1}. ${shop.name}`} />
              </ListItem>
            ))}
          </List>
        </>
      }
      onClose={onClose}
    />
  );
};

export default ListDependentShopsDialog;
