import { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { GetMeDocument, useUpdateOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';

const urlRegex = /https:\/\/(www.)?[A-Za-z0-9]+(\.[A-Za-z]{2,}){1,3}\/?[^\s]*$/;

const schema = yup.object({
  requiredAcceptTerms: yup.boolean(),
  termsUrl: yup.string().when('requiredAcceptTerms', {
    is: true,
    then: (schema) => schema.matches(urlRegex, { message: 'form_validation.invalid_value_entered' }),
  }),
  policyUrl: yup.string().when('requiredAcceptTerms', {
    is: true,
    then: (schema) => schema.matches(urlRegex, { message: 'form_validation.invalid_value_entered' }),
  }),
});

export interface FormPaymentMethodsValues extends yup.InferType<typeof schema> {}

const PaymentForm = () => {
  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();
  const { showError, showSuccess } = useNotify();
  const [editable] = useCheckPermissions([SCREEN_PERMISSION.SETTING.SHOP_PAYMENT.EDIT]);

  const defaultValues = useMemo(
    () => ({
      termsUrl: selectedOrganization?.paymentSetting?.termsUrl || '',
      policyUrl: selectedOrganization?.paymentSetting?.policyUrl || '',
      requiredAcceptTerms: !!selectedOrganization?.paymentSetting?.requiredAcceptTerms,
    }),
    [selectedOrganization]
  );

  const [updateOrganizationMutation] = useUpdateOrganizationMutation({ refetchQueries: [GetMeDocument] });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<FormPaymentMethodsValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });

  const requiredAcceptTerms = watch('requiredAcceptTerms');
  const isDirtyPayment = !!Object.keys(dirtyFields).length;

  const onSubmit = async (payload: FormPaymentMethodsValues) => {
    try {
      await updateOrganizationMutation({
        variables: {
          input: { paymentSetting: payload },
        },
      });
      reset(payload);
      showSuccess('my_shop.message.update_successful');
    } catch (err: any) {
      showError(err);
    }
  };

  useEffect(() => {
    const value = getValues();
    if (JSON.stringify(value) !== JSON.stringify(defaultValues)) reset(defaultValues);
  }, [defaultValues, getValues, reset]);

  return (
    <CustomCardTable
      cardTitle={t('settings.shop_payment.payment_form')}
      cardContent={
        <Box paddingBottom="16px">
          <Grid container spacing="16px" marginBottom="24px">
            <Grid item xs={12}>
              <Controller
                name="requiredAcceptTerms"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    disabled={!editable || isSubmitting}
                    control={<Switch checked={field.value} {...field} />}
                    label={t('settings.shop_payment.confirm_terms_and_privacy')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="termsUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="https://domain.com"
                    error={!!errors.termsUrl?.message}
                    label={t('settings.shop_payment.terms_url')}
                    helperText={t(errors.termsUrl?.message as any)}
                    disabled={!editable || isSubmitting || !requiredAcceptTerms}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="policyUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="https://domain.com"
                    error={!!errors.policyUrl?.message}
                    label={t('settings.shop_payment.policy_url')}
                    helperText={t(errors.policyUrl?.message as any)}
                    disabled={!editable || isSubmitting || !requiredAcceptTerms}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
            <Button
              variant="contained"
              disabled={!editable || isSubmitting || !isDirtyPayment}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('update')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default PaymentForm;
