import { FC, PropsWithChildren, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '../custom-dialog';

import LoaderCenter from '~/components/loader-center';
import { colors } from '~/constants/colors';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import {
  GetCurrentPlanDocument,
  GetMeDocument,
  GetNextPlanDocument,
  GetPlansDocument,
  GetPlansQuery,
  RecurrenceType,
  useEstimateFeePlanQuery,
  useSubscribePlanMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { formatLocaleString, roundUpDecimals } from '~/utils/number.util';

interface IPlanInvoiceDialog extends PropsWithChildren {
  open: boolean;
  plan: GetPlansQuery['getPlans'][number];
  onNext?: () => void;
  onClose: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapperTable: {
    '.MuiTableCell-root': {
      padding: '6px 16px',
    },
  },
}));

const PlanInvoiceDialog: FC<IPlanInvoiceDialog> = ({ open, plan, onNext, onClose }) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { show } = usePaymentMethodRequired();
  const { selectedOrganization } = useAccount();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [subscribePlan] = useSubscribePlanMutation({
    refetchQueries: [GetCurrentPlanDocument, GetMeDocument, GetPlansDocument, GetNextPlanDocument],
    variables: {
      input: {
        uuid: plan.uuid,
        activeNow: true,
        recurrence: RecurrenceType.Monthly,
      },
    },
  });
  const { data: planFeeRes, loading: loadingPlanFee } = useEstimateFeePlanQuery({
    skip: !open,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        uuid: plan.uuid,
        activeNow: true,
        recurrence: RecurrenceType.Monthly,
      },
    },
  });
  const planFee = planFeeRes?.estimateFeePlan;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const openRegisterPaymentMethod = await show({
        description: '',
      });
      if (openRegisterPaymentMethod) {
        setIsSubmitting(false);
        showErrorByKey('toast_message.payment_failed');
        return;
      }
      await subscribePlan();
      onClose();
      if (!!onNext) {
        onNext();
      }
      showSuccess('toast_message.payment_successful');
    } catch (error) {
      showError(error, 'toast_message.payment_failed');
    }
    setIsSubmitting(false);
  };

  const remainingDay =
    planFee?.numberDay + (i18n.language === 'ja' ? '日' : ` ${(planFee?.numberDay || 0) === 1 ? 'day' : 'days'}`);

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={onClose}
      dialogTitle={t('invoice')}
      dialogContent={
        loadingPlanFee ? (
          <Box height="100px">
            <LoaderCenter />
          </Box>
        ) : (
          <>
            <Stack gap={1} flexDirection="row">
              <Typography flex={1} variant="subtitle1">
                <strong>{t('organization')}:</strong> {selectedOrganization.name}
              </Typography>
              <Typography flex={1} variant="subtitle1">
                <strong>{t('date')}:</strong> {moment().format('YYYY-MM-DD')}
              </Typography>
            </Stack>
            <Divider sx={{ margin: '16px 0' }} />
            <Typography variant="h6">{t('contract_detail')}</Typography>
            <TableContainer className={classes.wrapperTable}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>{t('item_name')}</TableCell>
                    <TableCell align="right">{t('subtotal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3}>{plan.planName}</TableCell>
                    <TableCell align="right">${formatLocaleString(planFee?.fee, 2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {t('tax')}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      $0
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      {t('settings.per_month')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      ${formatLocaleString(planFee?.fee, 2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body2" textAlign="center" marginTop={2}>
              {t('settings.plan_fee_desc')}
            </Typography>
            <Divider sx={{ margin: '16px 0' }} />
            <Typography variant="h6">{t('settings.proration_amount')}</Typography>
            <TableContainer className={classes.wrapperTable}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>{t('item_name')}</TableCell>
                    <TableCell align="right">{t('subtotal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3}>{plan.planName}</TableCell>
                    <TableCell align="right">
                      ${roundUpDecimals(planFee?.estimateFee || 0, 2).toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {t('tax')}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      $0
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      {t('total')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      ${roundUpDecimals(planFee?.estimateFee || 0, 2).toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack alignItems="center" marginTop={2}>
              <Typography variant="body1">{t('settings.proration_desc')}</Typography>
              <Box sx={{ gap: '8px', display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight={500} color={colors.primary}>
                  {t('settings.for_number_of_days', {
                    day: remainingDay,
                  })}
                </Typography>
                =
                <Typography variant="h5" fontWeight={700}>
                  ${roundUpDecimals(planFee?.estimateFee || 0, 2).toLocaleString()}
                </Typography>
              </Box>
            </Stack>
          </>
        )
      }
      actions={[
        <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit}
        >
          {t('my_shop.purchase')}
        </Button>,
      ]}
    />
  );
};

export default PlanInvoiceDialog;
