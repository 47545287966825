import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddCollectionDialog from './AddCollectionDialog';
import SalesCollectionTab from './SalesCollectionTab';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import { ListTablePagination } from '~/components/list-table';
import WrapperWithFab from '~/components/WrapperWithFab';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { env } from '~/env';
import { MyShopCollectionQueryKey, QueryOperator, useListMyShopCollectionsQuery } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { COLLECTION_TYPE, SalesMethodEnum } from '~/types/my-shop';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export enum TABS {
  LAZY_MINT = 0,
  EXISTING = 1,
  MEMBER_SITE = 2,
}

export const TYPES = {
  [TABS.LAZY_MINT]: [COLLECTION_TYPE.MEMBERSHIP, COLLECTION_TYPE.PROMOTION],
  [TABS.EXISTING]: [COLLECTION_TYPE.DIGITAL_COLLECTIVE],
  [TABS.MEMBER_SITE]: [COLLECTION_TYPE.MEMBERSHIP, COLLECTION_TYPE.PROMOTION, COLLECTION_TYPE.DIGITAL_COLLECTIVE],
};

export interface DisplayedCollectionsList {
  id: string;
  url?: string;
  salesMethod?: SalesMethodEnum;
  [MyShopCollectionQueryKey.CollectionUuid]?: string;
  [MyShopCollectionQueryKey.CollectionSymbol]?: string;
  [MyShopCollectionQueryKey.CollectionNetwork]: string;
  [MyShopCollectionQueryKey.CollectionCreatedAt]?: string;
  [MyShopCollectionQueryKey.CollectionName]?: string;
  [MyShopCollectionQueryKey.CollectionOwnerAddress]: string;
  [MyShopCollectionQueryKey.CollectionContractAddress]?: string;
}

const getInitQuery = (shopId: string) => {
  return {
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    orderBy: verifyOrderKey(getLocalStorage('sales_collection_member_site_list_order')),
    sortBy: verifySortKey(MyShopCollectionQueryKey, getLocalStorage('sales_collection_member_site_list_sort')),
    where: {
      fields: [
        {
          value: [shopId ?? ''],
          operator: QueryOperator.Contains,
          key: MyShopCollectionQueryKey.ShopUuid,
        },
      ],
    },
  };
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    height: '100%',
  },
}));

const SalesCollection: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isLocked } = useAccount();
  const { showError, showErrorByKey } = useNotify();

  const [isOpenAddCollection, setIsOpenAddCollection] = useState<boolean>(false);

  const [myShopCollectionsQuery, setMyShopCollectionsQuery] = useState(getInitQuery(id || ''));

  const updateMyShopCollectionsQuery = (newValue: any) => {
    setMyShopCollectionsQuery((value: any) => {
      return { ...value, ...newValue };
    });
  };

  const { data: dataShopCollections, loading: loadingListMyShopCollections } = useListMyShopCollectionsQuery({
    variables: myShopCollectionsQuery,
    fetchPolicy: 'cache-and-network',
    onError(error) {
      showError(error.message);
    },
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShopCollections?.listMyShopCollections?.items || [];
    const pagination: ListTablePagination = dataShopCollections?.listMyShopCollections?.pagination || {};

    const _items: DisplayedCollectionsList[] = items.map((item) => {
      const { collection } = item;
      return {
        id: item.uuid,
        salesMethod: item.type || '-',
        [MyShopCollectionQueryKey.CollectionUuid]: collection?.uuid,
        [MyShopCollectionQueryKey.CollectionSymbol]: collection?.symbol,
        [MyShopCollectionQueryKey.CollectionNetwork]: collection?.network,
        [MyShopCollectionQueryKey.CollectionCreatedAt]: collection?.createdAt,
        [MyShopCollectionQueryKey.CollectionOwnerAddress]: collection?.ownerAddress,
        [MyShopCollectionQueryKey.CollectionContractAddress]: collection?.contractAddress,
        [MyShopCollectionQueryKey.CollectionName]: item.name || collection?.name,
        url: env.REACT_APP_API_MEDIA + '/' + collection?.images?.[0],
      } as DisplayedCollectionsList;
    });
    return { items: _items, pagination };
  }, [dataShopCollections]);

  const openAttachCollectionDialog = useCallback(() => {
    if (isLocked) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }
    setIsOpenAddCollection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeAttachCollectionDialog = useCallback(() => {
    setIsOpenAddCollection(false);
  }, []);

  useEffect(() => {
    setLocalStorageItems({
      sales_collection_member_site_list_order: myShopCollectionsQuery?.orderBy,
      sales_collection_member_site_list_sort: myShopCollectionsQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShopCollectionsQuery?.orderBy, myShopCollectionsQuery?.sortBy]);

  return (
    <Box className={classes.wrapper}>
      <WrapperWithFab onClick={openAttachCollectionDialog}>
        <CustomCardTable
          cardTitle={t('collection')}
          headerAction={
            <HeaderAction
              menus={[
                {
                  title: t('my_shop.attach_new_collection'),
                  onClick: openAttachCollectionDialog,
                },
              ]}
            />
          }
          cardContent={
            <SalesCollectionTab
              rows={items}
              pagination={pagination}
              loadingData={loadingListMyShopCollections}
              queryData={myShopCollectionsQuery}
              updateQuery={updateMyShopCollectionsQuery}
              openAttachCollectionDialog={openAttachCollectionDialog}
            />
          }
        />
      </WrapperWithFab>
      <AddCollectionDialog
        open={isOpenAddCollection}
        onClose={closeAttachCollectionDialog}
        refetchMyShopCollections={() => updateMyShopCollectionsQuery({ page: 1, searchText: '' })}
      />
    </Box>
  );
};

export default memo(SalesCollection);
