import React from 'react';

import Button, { ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomDialog, { ICustomDialog } from '../custom-dialog';

export type ICloseDialog = (params?: { isSubmitted?: boolean }) => Promise<void> | void;

export interface ConfirmationDialogProps extends Omit<ICustomDialog, 'dialogTitle' | 'dialogContent'> {
  title: string;
  content?: string;
  cancelTitle?: string;
  onlyConfirm?: boolean;
  confirmTitle?: string;
  classes?: DialogProps['classes'];
  colorSubmit?: ButtonTypeMap['props']['color'];
  onClose: ICloseDialog;
  onCancel?: () => void;
  onConfirm: () => Promise<void>;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  content,
  colorSubmit,
  onlyConfirm,
  cancelTitle,
  confirmTitle,
  onClose,
  onCancel,
  onConfirm,
  ...others
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = async () => {
    try {
      await onConfirm();
      onClose({ isSubmitted: true });
    } catch (err) {}
  };

  const handleClose = () => {
    onClose();
  };

  const handleCancel = async () => {
    try {
      if (!!onCancel) {
        await onCancel();
      }
      onClose();
    } catch (err) {}
  };

  return (
    <CustomDialog
      {...others}
      width="sm"
      dialogTitle={title}
      dialogContent={
        <Typography color="inherit" component="p">
          {content ?? t('my_shop.message.confirm_delete')}
        </Typography>
      }
      actions={[
        <>
          {!onlyConfirm && (
            <Button key={0} color="inherit" variant="outlined" disabled={isSubmitting} onClick={handleCancel}>
              {cancelTitle || t('cancel')}
            </Button>
          )}
          <Button
            key={1}
            variant="contained"
            disabled={isSubmitting}
            color={colorSubmit || 'error'}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(onSubmit)}
          >
            {confirmTitle || t('delete')}
          </Button>
        </>,
      ]}
      onClose={handleClose}
    />
  );
};

export default ConfirmationDialog;
