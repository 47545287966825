import { FC, PropsWithChildren, useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import HeaderBar from './header-bar';
import SideBar from './side-bar';

import { DRAWER_WIDTH, DRAWER_WIDTH_SM, HEADER_HEIGHT } from '~/constants/layout';

const useStyles = makeStyles<{ isShopDetail: boolean }>()((theme, { isShopDetail }) => ({
  root: {
    backgroundColor: theme.colors.white,
  },
  rightSide: {
    position: 'relative',
    marginLeft: DRAWER_WIDTH,
    backgroundColor: '#F8F8F8',
    transition: 'all 250ms ease 0s',
    [theme.breakpoints.down('md')]: {
      marginLeft: DRAWER_WIDTH_SM,
    },
  },
  rightSideTemporarySideBar: {
    marginLeft: 0,
  },
  contentWrapper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    ...(isShopDetail
      ? {}
      : {
          gap: theme.spacing(2),
          alignItems: 'flex-start',
          padding: '24px 16px',
          [theme.breakpoints.up('md')]: {
            padding: '24px',
          },
        }),
  },
}));

const UserLayout: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { id: shopId } = useParams();

  const isShop = /^\/(my-shop|member-site)/.test(location.pathname);
  const isEditor = /edit-(shop|member-site)$/.test(location.pathname);

  const { classes, cx, theme } = useStyles({ isShopDetail: !!isShop && !!shopId });
  const isTemporarySideBar = useMediaQuery(theme.breakpoints.down('md'));

  const [openDrawer, setOpenDrawer] = useState(false);

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((open) => !open);
  }, []);
  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  if (isEditor && !!children) {
    return (
      <Box className={classes.root} data-testid="layout" sx={{ height: '100vh' }}>
        {children}
      </Box>
    );
  }

  return (
    <div className={classes.root} data-testid="layout">
      <HeaderBar isTemporarySideBar={isTemporarySideBar} onToggleDrawer={onToggleDrawer} />
      <SideBar isTemporarySideBar={isTemporarySideBar} openDrawer={openDrawer} onCloseDrawer={onCloseDrawer} />
      <div className={cx(classes.rightSide, isTemporarySideBar && classes.rightSideTemporarySideBar)}>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
