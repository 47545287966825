import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, FormControlLabel, ListItemIcon, ListItemText, Stack, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import HeaderAction from './HeaderAction';
import { useBasicSiteSettingStyles } from './styles';

import { PAYMENT_GATEWAY_INFO } from '~/components/dialog/add-payment-config-dialog';
import CurrencyChangeWarningDialog, {
  FormCurrencyChangeWarningValues,
} from '~/components/dialog/currency-change-warning-dialog';
import ImageUploadInput from '~/components/image-upload-input';
import WrapperTextField from '~/components/WrapperTextField';
import { formBasicSettingShop } from '~/config/yup';
import { SUPPORTED_CURRENCIES } from '~/constants/common';
import { IMyShopFormWrapper, useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { ShopFormEnum } from '~/enum/pages/my-shop';
import { env } from '~/env';
import {
  Currency,
  GetMyShopDocument,
  ListPaymentConfigsQuery,
  useCheckExistDomainLazyQuery,
  useListPaymentConfigsQuery,
  useUpdateBaseCurrencyMutation,
  useUpdateMyShopMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { steps } from '~/pages/my-shop/create-shop';
import { NFT_SHOP_TYPE, SHOP_TYPE } from '~/types/my-shop';
import { generateQueryString } from '~/utils/common';
import { getErrorText } from '~/utils/yup.util';

export interface FormBasicSiteSettingValues extends yup.InferType<typeof formBasicSettingShop> {}

interface BasicSiteSettingProps {
  isCreate?: boolean;
}

const getShopType = (t: TFunction) => ({
  [NFT_SHOP_TYPE.MINTED]: {
    value: NFT_SHOP_TYPE.MINTED,
    label: t('my_shop.minted_type'),
  },
  [NFT_SHOP_TYPE.PRE_MINT]: {
    value: NFT_SHOP_TYPE.PRE_MINT,
    label: t('my_shop.pre_mint_type'),
  },
});

const BasicSiteSetting: FC<BasicSiteSettingProps> = ({ isCreate }) => {
  const { data: shopData, editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handleNextStep } = useMyShopFormWrapper();

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotify();
  const { classes } = useBasicSiteSettingStyles({ isCreate });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCurrencyChangeWarning, setShowCurrencyChangeWarning] = useState(false);

  const isEditing = editingAt === ShopFormEnum.BASIC;

  const { data: listPaymentConfigsRes } = useListPaymentConfigsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateBaseCurrency] = useUpdateBaseCurrencyMutation();
  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [checkExistDomain] = useCheckExistDomainLazyQuery();

  const listPaymentConfigObj = useMemo(
    () =>
      listPaymentConfigsRes?.listPaymentConfigs.reduce((result, config) => {
        result[config.uuid] = config;
        return result;
      }, {} as Record<string, ListPaymentConfigsQuery['listPaymentConfigs'][number]>) || {},
    [listPaymentConfigsRes?.listPaymentConfigs]
  );

  const defaultValues: FormBasicSiteSettingValues = useMemo(
    () => ({
      ogp: formData?.siteSetting?.ogp!,
      logo: formData?.siteSetting?.logo!,
      favicon: formData?.siteSetting?.favicon!,
      ogpFile: formData?.siteSetting?.ogpFile!,
      category: formData?.siteSetting?.category!,
      logoFile: formData?.siteSetting?.logoFile!,
      siteName: formData?.siteSetting?.siteName!,
      domainName: formData?.siteSetting?.domainName!,
      currency: formData.paymentMethod?.baseCurrency!,
      paymentConfig: formData?.paymentConfigUuid || '',
      faviconFile: formData?.siteSetting?.faviconFile!,
      showPriceUsd: !!formData?.siteSetting?.showPriceUsd,
      metaDescription: formData?.siteSetting?.metaDescription!,
    }),
    [formData]
  );

  const {
    control,
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormBasicSiteSettingValues>({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'firstError',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(formBasicSettingShop),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const [currency, category, paymentConfig] = useWatch({
    control,
    name: ['currency', 'category', 'paymentConfig'],
  });

  const currentPaymentConfig = listPaymentConfigObj[paymentConfig] || {};

  const shopTypeOptions = useMemo(() => {
    const shopTypeWithLang = getShopType(t);
    return Object.keys(shopTypeWithLang).map((option: string) => (
      <MenuItem key={option} value={option} disabled={category === option}>
        {t(shopTypeWithLang[option as NFT_SHOP_TYPE].label)}
      </MenuItem>
    ));
  }, [category, t]);

  const redirectToPaymentConfig = () => {
    navigate(AppRouteEnum.Setting + generateQueryString({ tab: 4 }));
  };

  const listPaymentConfigOptions = useMemo(
    () =>
      Object.values(listPaymentConfigObj).length > 0
        ? Object.values(listPaymentConfigObj).map((config) => (
            <MenuItem key={config.uuid} value={config.uuid} disabled={paymentConfig === config.uuid}>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                {PAYMENT_GATEWAY_INFO[config.paymentGateway].icons}
                <Typography lineHeight="23px">{config.name}</Typography>
              </Stack>
            </MenuItem>
          ))
        : [
            <MenuItem key={0} value={0} onClick={redirectToPaymentConfig}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>{t('settings.shop_payment.add_payment_config')}</ListItemText>
            </MenuItem>,
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentConfig, listPaymentConfigObj, t]
  );

  const currencyOptions = useMemo(
    () =>
      (SUPPORTED_CURRENCIES[currentPaymentConfig?.paymentGateway] || [Currency.Jpy])?.map((item) => {
        return (
          <MenuItem key={item} value={item} disabled={currency === item}>
            {item}
          </MenuItem>
        );
      }),
    [currency, currentPaymentConfig?.paymentGateway]
  );

  const handleChangeToEdit = () => {
    handleEditingAt(ShopFormEnum.BASIC);
  };

  const handleCancel = () => {
    if (isCreate) {
      navigate(AppRouteEnum.MyShop);
    } else {
      reset(defaultValues);
      handleEditingAt(undefined);
    }
  };

  const openCurrencyChangeWarning = () => {
    setShowCurrencyChangeWarning(true);
  };
  const closeCurrencyChangeWarning = () => {
    setShowCurrencyChangeWarning(false);
  };

  const handleUpdateMyShop = async (
    data: FormBasicSiteSettingValues,
    confirmData?: FormCurrencyChangeWarningValues
  ) => {
    try {
      if (!isCreate) {
        if (defaultValues.currency !== data.currency && confirmData) {
          await updateBaseCurrency({
            variables: {
              input: {
                uuid: id!,
                baseCurrency: data.currency,
                rate: Number(confirmData.exchangeRate),
              },
            },
          });
        }

        await updateMyShop({
          variables: {
            input: {
              uuid: id!,
              siteSetting: {
                title: data.siteName,
                showPriceUsd: data.showPriceUsd,
                description: data.metaDescription,
              },
              paymentConfigUuid: data.paymentConfig,
              domain: {
                name: data.domainName.trim(),
              },
              ogpFile: data.ogpFile?.[0],
              logoFile: data.logoFile?.[0],
              faviconFile: data.faviconFile?.[0],
            },
          },
        });
        handleEditingAt(undefined);
        showSuccess('my_shop.message.update_successful');
      }

      setFormData(
        (prevState) =>
          ({
            ...prevState,
            siteSetting: {
              ...prevState.siteSetting,
              ...data,
            },
            paymentMethod: {
              baseCurrency: data.currency,
            },
            paymentConfigUuid: data.paymentConfig,
          } as IMyShopFormWrapper)
      );

      if (isCreate) {
        handleNextStep(steps(t).length);
      }
      setIsSubmitting(false);
    } catch (err) {
      showError(err);
    }
  };

  const onSubmit = async (data: FormBasicSiteSettingValues) => {
    setIsSubmitting(true);
    if (formData?.siteSetting?.domainName?.trim() !== data.domainName.trim()) {
      const isExistDomain = await checkExistDomain({
        fetchPolicy: 'cache-and-network',
        variables: {
          shopType: SHOP_TYPE.SHOP,
          domain: data.domainName.trim(),
        },
      });
      if (isExistDomain?.data?.checkExistDomain) {
        setError('domainName', { message: t('my_shop.message.errors.domain_is_existing') });
        setIsSubmitting(false);
        return;
      }
    }
    if (!isCreate && defaultValues.currency !== data.currency) {
      openCurrencyChangeWarning();
      setIsSubmitting(false);
      return;
    }

    try {
      await handleUpdateMyShop(data);
    } catch (err) {
      showError(err);
    }
  };

  const confirmCurrencyChangeWarning = async (confirmData: FormCurrencyChangeWarningValues) => {
    try {
      await handleSubmit((submitData) => handleUpdateMyShop(submitData, confirmData))();
    } catch (err) {
      showError(err);
    }
  };

  const handleChangePaymentConfig: (...event: any[]) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    (onChange) => (event) => {
      setValue('currency', Currency.Jpy);
      onChange(event);
    };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currency !== Currency.Jpy) {
      setValue('showPriceUsd', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={t('my_shop.basic_site_setting')}
          action={!isCreate && <HeaderAction />}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent__left">
            <Box className="cardContent__left-wrapperSiteImage">
              <Typography variant="h5">{t('my_shop.site_image')}</Typography>
              <Box className="cardContent__left-wrapperSiteImage--siteImage">
                <ImageUploadInput<FormBasicSiteSettingValues>
                  required
                  type="image"
                  height="100%"
                  name="ogpFile"
                  control={control}
                  label={t('image')}
                  defaultImageUrlName="ogp"
                  error={!!errors.ogpFile?.message}
                  defaultImagePath={'/default/ogp-shop.png'}
                  disabled={!(isCreate || isEditing) || isSubmitting}
                  helperText={t(errors.ogpFile?.message as any, {
                    size: 10,
                  })}
                />
              </Box>
            </Box>
            <Box className="cardContent__left-wrapperLogo">
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('logo')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="logoFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="logo"
                    error={!!errors.logoFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.logoFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('favicon')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="faviconFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="favicon"
                    error={!!errors.faviconFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.faviconFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="cardContent__right">
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="siteName"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('my_shop.shop_name')}
                render={(field) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('my_shop.shop_name')}
                    error={!!errors.siteName?.message}
                    helperText={getErrorText(errors.siteName?.message, t)}
                    required
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="domainName"
                control={control}
                label={t('my_shop.site_setting.shop_url')}
                readOnlyBodyStyle={{ color: '#1976d2 !important' }}
                isEditing={isCreate || isEditing}
                formatValue={(field) =>
                  env.REACT_APP_CLIENT_URL + AppRouteEnum.Shop.replace(/:shopName/g, field.value as string)
                }
                render={(field) => (
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    error={!!errors.domainName?.message}
                    helperText={getErrorText(errors.domainName?.message, t)}
                    label={t('my_shop.site_setting.shop_url') + ' (tokenstudio.gu.net/shop/)'}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="metaDescription"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('my_shop.site_setting.meta_description')}
                readOnlyBodyStyle={{
                  height: '120px',
                }}
                render={(field) => (
                  <TextField
                    rows={4}
                    fullWidth
                    multiline
                    margin="normal"
                    disabled={isSubmitting}
                    placeholder={t('my_shop.shop_description')}
                    label={t('my_shop.site_setting.shop_description')}
                    error={!!errors.metaDescription?.message}
                    helperText={getErrorText(errors.metaDescription?.message, t)}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                control={control}
                name="paymentConfig"
                isEditing={isCreate || isEditing}
                formatValue={() => currentPaymentConfig.name}
                label={t('settings.shop_payment.payment_config')}
                render={({ onChange, ...field }) => (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    error={!!errors.paymentConfig?.message}
                    label={t('settings.shop_payment.payment_config')}
                    disabled={isSubmitting || !!shopData?.publish}
                    helperText={
                      t(errors.paymentConfig?.message as any) ||
                      (!!shopData?.publish ? t('my_shop.site_setting.payment_config_desc') : undefined)
                    }
                    onChange={handleChangePaymentConfig(onChange)}
                    {...field}
                  >
                    {listPaymentConfigOptions}
                  </TextField>
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="currency"
                control={control}
                label={t('settings.currency')}
                isEditing={isCreate || isEditing}
                render={(field) => (
                  <>
                    <TextField
                      select
                      fullWidth
                      margin="normal"
                      label={t('settings.currency')}
                      error={!!errors.currency?.message}
                      disabled={isSubmitting || !!shopData?.publish}
                      helperText={
                        t(errors.currency?.message as any) ||
                        (!!shopData?.publish ? t('my_shop.site_setting.currency_desc') : undefined)
                      }
                      {...field}
                    >
                      {currencyOptions}
                    </TextField>
                  </>
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="category"
                control={control}
                isEditing={!!isCreate}
                label={t('my_shop.shop_type')}
                formatValue={(field) => getShopType(t)[field.value as NFT_SHOP_TYPE]?.label}
                render={(field) => (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('my_shop.shop_type')}
                    error={!!errors.category?.message}
                    helperText={t(errors.category?.message as any)}
                    {...field}
                  >
                    {shopTypeOptions}
                  </TextField>
                )}
              />
            </Box>
            {currency === Currency.Jpy && (
              <Box className="cardContent__right-wrapperInput">
                <WrapperTextField
                  showBorderBottom
                  control={control}
                  name="showPriceUsd"
                  isEditing={isCreate || isEditing}
                  label={t('my_shop.site_setting.show_est_usd_price')}
                  formatValue={(field) => t(field.value ? 'show' : 'hide')}
                  render={(field) => (
                    <FormControlLabel
                      label={t(field.value ? 'show' : 'hide')}
                      control={<Switch checked={!!field.value} onChange={field.onChange} />}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && !isDirty) || isSubmitting}
                endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {t(isCreate ? 'next' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
      <CurrencyChangeWarningDialog
        newCurrency={currency}
        open={showCurrencyChangeWarning}
        oldCurrency={defaultValues.currency}
        onClose={closeCurrencyChangeWarning}
        onConfirm={confirmCurrencyChangeWarning}
      />
    </Box>
  );
};

export default BasicSiteSetting;
