import { OrganizationUserRole } from '~/graphql/member/types';

const HIGH_ROLES = [OrganizationUserRole.Owner, OrganizationUserRole.Admin];
const WITHOUT_VIEWER_ROLE = HIGH_ROLES.concat([OrganizationUserRole.Member]);
const ALL_ROLES = WITHOUT_VIEWER_ROLE.concat([OrganizationUserRole.Viewer]);

export const SCREEN_PERMISSION = {
  HOME: {
    VIEW: ALL_ROLES,
  },
  COLLECTIONS: {
    VIEW: ALL_ROLES,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
    BASIC: {
      VIEW: ALL_ROLES,
    },
    MINTED_NFT: {
      VIEW: ALL_ROLES,
      ADD: WITHOUT_VIEWER_ROLE,
      EDIT: WITHOUT_VIEWER_ROLE,
      DELETE: WITHOUT_VIEWER_ROLE,
    },
    MINT_ON_PURCHASE: {
      VIEW: ALL_ROLES,
      ADD: WITHOUT_VIEWER_ROLE,
      EDIT: WITHOUT_VIEWER_ROLE,
      DELETE: WITHOUT_VIEWER_ROLE,
    },
  },
  SHOPS: {
    VIEW: WITHOUT_VIEWER_ROLE,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
  },
  MEMBER_SITES: {
    VIEW: WITHOUT_VIEWER_ROLE,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
  },
  SETTING: {
    VIEW: WITHOUT_VIEWER_ROLE,
    GENERAL: {
      VIEW: WITHOUT_VIEWER_ROLE,
      EDIT: [OrganizationUserRole.Owner],
    },
    BILLING: {
      VIEW: WITHOUT_VIEWER_ROLE,
      PAY_NOW: HIGH_ROLES,
    },
    POINTS: {
      VIEW: WITHOUT_VIEWER_ROLE,
      BUY_POINTS: HIGH_ROLES,
      REFUND: [OrganizationUserRole.Owner],
    },
    SHOP_PAYMENT: {
      VIEW: WITHOUT_VIEWER_ROLE,
      ADD: HIGH_ROLES,
      EDIT: HIGH_ROLES,
      DELETE: HIGH_ROLES,
    },
    PAYMENT_METHODS: {
      ADD: HIGH_ROLES,
      VIEW: HIGH_ROLES,
      EDIT: HIGH_ROLES,
      DELETE: HIGH_ROLES,
    },
    MEMBER: {
      VIEW: WITHOUT_VIEWER_ROLE,
      ADD: HIGH_ROLES,
      EDIT: HIGH_ROLES,
      DELETE: HIGH_ROLES,
    },
    PLAN_MANAGEMENT: {
      VIEW: HIGH_ROLES,
      ADD: [OrganizationUserRole.Owner],
      EDIT: [OrganizationUserRole.Owner],
      DELETE: [OrganizationUserRole.Owner],
    },
  },
  PLANS: {
    VIEW: WITHOUT_VIEWER_ROLE,
    SUBSCRIBE: OrganizationUserRole.Owner,
  },
  USER_MANUAL: {
    VIEW: ALL_ROLES,
  },
};

export const MEMBER_ACTIONS = {
  ADD: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
  EDIT: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Owner,
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
  DELETE: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
};
