import { AcquisitionInformation as AI, InputMaybe } from '~/graphql/member/types';
import { TokenQuery } from '~/graphql/subgraph/types';
import { NFTToken } from '~/utils/getNFTMetadata';

export type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];

export type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;

export interface AcquisitionInformation extends AI {
  type: InputMaybe<AcquisitionInformationType> | undefined;
}

export type ArrayElement<T> = T extends (infer U)[] ? U : never;

export interface INftInfo extends NFTToken {
  price?: number;
  type?: NFT_TYPE;
  nameJa?: string;
  order?: number | null | undefined;
}

export interface CardData {
  id: string;
  url?: string;
  name?: string;
  price?: number;
  nameJa?: string;
  remain?: number;
  status?: string;
  tokenId?: string;
  maxMint?: number;
  currentMint?: number;
  description?: string;
  ownerAddress?: string;
  originalName?: string;
  networkSymbol?: string;
  collectionUuid?: string;
  collectionImageUuid?: string;
  myShopCollectionUuid?: string;
  order?: number | undefined | null;
  additionalInfos?: TokenQuery['token'];
}

export interface MetadataJson {
  name: string;
  image: string;
  description: string;
  animation_url: string;
}

export enum ShopDetailTab {
  BASIC = 'BASIC',
  LEGAL = 'LEGAL',
  ADVANCED = 'ADVANCED',
  COLLECTION = 'COLLECTION',
}

export enum MemberSiteDetailTab {
  BASIC = 'BASIC',
  TERMS = 'TERMS',
  ADVANCED = 'ADVANCED',
  COLLECTION = 'COLLECTION',
  REGISTRATION = 'REGISTRATION',
  MEMBER_MANAGEMENT = 'MEMBER_MANAGEMENT',
}

export enum NFT_SHOP_TYPE {
  MINTED = 'MINTED',
  PRE_MINT = 'PRE_MINT',
}

export enum CollectionTabEnum {
  ISSUE = 'ISSUE',
  LAZY_MINT = 'LAZY_MINT',
}

export enum CollectionInfos {
  BASIC = 'BASIC',
  MINT_ON_PURCHASE = 'MINT_ON_PURCHASE',
  MINTED_NFT = 'MINTED_NFT',
}

export enum GRANT_PERMISSION {
  GRANTED = 'GRANTED',
  UNAVAILABLE = 'UNAVAILABLE',
  NOT_GRANTED = 'NOT_GRANTED',
  NOT_PERMISSION = 'NOT_PERMISSION',
}

export enum SalesMethodEnum {
  NFT_LIST_ONLY = 'NFT_LIST_ONLY',
  ADVANCED_ONLY = 'ADVANCED_ONLY',
  NFT_LIST_AND_ADVANCED = 'NFT_LIST_AND_ADVANCED',
}

export enum TYPE_IMAGE {
  URL = 'URL',
  FILE = 'FILE',
}

export enum NFT_TYPE {
  EXISTING = 'EXISTING',
  LAZY_MINT = 'LAZY_MINT',
}

export enum DisplayFormat {
  COLLECTIONS = 'COLLECTIONS',
  COLLECTION_GROUPS = 'COLLECTION_GROUPS',
}

export enum AcquisitionInformationType {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  ADDRESS = 'ADDRESS',
}

export enum ExpireDateMethod {
  SPECIFY_DATE = 'SPECIFY_DATE',
  SPECIFY_HOW_LONG = 'SPECIFY_HOW_LONG',
  NOT_EXPIRE = 'NOT_EXPIRE',
}

export enum Unit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum SHOP_TYPE {
  SHOP = 'shop',
  MEMBER = 'member',
}

export enum COLLECTION_TYPE {
  MEMBERSHIP = 'MEMBERSHIP',
  PROMOTION = 'PROMOTION',
  DIGITAL_COLLECTIVE = 'DIGITAL_COLLECTIVE',
}

export enum STATUS {
  NOW_ON_SALE = 'NOW_ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENSION = 'SUSPENSION',
  NOT_SELECTED = 'NOT_SELECTED',
}

export enum NFT_STATUS {
  ON_SALE = 'ON_SALE',
  UNSOLD = 'UNSOLD',
}

export enum MEMBER_SITE_NFT_STATUS {
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  NOT_CONFIRM = 'NOT_CONFIRM',
  UNRESTRICTED = 'UNRESTRICTED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export enum DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum INDICATION {
  NFT = 'NFT',
  COLLECTION = 'COLLECTION',
}

export enum SEX {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum TEMPLATE_OPTIONS {
  SIMPLE_SITE = 'SIMPLE_SITE',
  HEADER_IMAGE_STYLE_1 = 'HEADER_IMAGE_STYLE_1',
  HEADER_IMAGE_STYLE_2 = 'HEADER_IMAGE_STYLE_2',
}

export enum FONT {
  ROBOTO = 'Roboto',
  ARIAL = 'Arial',
  HELVETICA = 'Helvetica',
}

export enum OBJECT_FIT {
  COVER = 'cover',
  CONTAIN = 'contain',
  FILL = 'fill',
}

export interface AddressType {
  area: string;
  city: string;
  street: string;
  cityEn: string;
  areaEn: string;
  address: string;
  streetEn: string;
  addressEn: string;
  prefecture: string;
  prefectureEn: string;
}

export interface ConfigurationData {
  thema: {
    template: TEMPLATE_OPTIONS;
    bannerImage?: string;
  };
  style: {
    font: FONT;
    textColor: string;
    backgroundColor: string;
    buttonColor: string;
  };
  navi: {
    pageTitle: string;
    description: string;
  };
}

export enum PageCategoryType {
  Corporate = 'Corporate',
  Individual = 'Individual',
}
