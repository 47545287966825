import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Checkbox,
  IconButton,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { GridColDef, GridRowParams, jaJP } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormRegistrationConditionValues } from '../RegistrationCondition';

import AcquisitionToolbar from './AcquisitionToolbar';

import CustomDataGrid from '~/components/CustomDataGrid';
import { AcquisitionInformationInput } from '~/graphql/member/types';
import { AcquisitionInformationType } from '~/types/my-shop';

export const initInfoType = (t: TFunction) => ({
  [AcquisitionInformationType.NAME]: {
    value: AcquisitionInformationType.NAME,
    label: 'Name',
    type: t('name.person'),
  },
  [AcquisitionInformationType.EMAIL]: {
    value: AcquisitionInformationType.EMAIL,
    label: 'E-mail address',
    type: t('email_address'),
  },
  [AcquisitionInformationType.ADDRESS]: {
    value: AcquisitionInformationType.ADDRESS,
    label: 'Address',
    type: t('address'),
  },
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '4px !important',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const Empty = () => {
  return <></>;
};

interface IAcquisitionInformation {
  errors: any;
  disabled: boolean;
  control: Control<FormRegistrationConditionValues, any>;
}

const AcquisitionInformation: FC<IAcquisitionInformation> = ({ errors, disabled, control }) => {
  const { t, i18n } = useTranslation();

  const acquisitionInformation = useWatch({
    control,
    name: 'acquisitionInformation',
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: 'acquisitionInformation',
  });

  const typeOptions = useCallback(
    (currentRow?: AcquisitionInformationInput) => {
      if (!currentRow) {
        return [];
      }

      const currentInfoTypes = acquisitionInformation?.reduce((result, info) => {
        if (currentRow.id !== info?.id) {
          result.push(info?.type!);
        }
        return result;
      }, [] as string[]);

      const newInfoTypes = Object.values(initInfoType(t)).reduce((result, info) => {
        if (!currentInfoTypes?.includes(info.value)) {
          result.push({ value: info.value, label: info.type });
        }
        return result;
      }, [] as Array<{ value: string; label: string }>);

      return newInfoTypes;
    },
    [t, acquisitionInformation]
  );

  const handleChangeTextField = useCallback(
    (
        row: AcquisitionInformationInput & { key?: string },
        field: 'type' | 'displayName'
      ): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
      (event) => {
        const rowIdx = fields.findIndex((item) => row.id === item.id);
        const value = event.target.value;
        const { key: _, id, type, required, displayName } = row;

        update(rowIdx, {
          id,
          type,
          required,
          displayName,
          [field]: value,
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  const handleChangeCheckbox = useCallback(
    (row: AcquisitionInformationInput & { key?: string }) =>
      (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const rowIdx = fields.findIndex((item) => row.id === item.id);
        const { key: __, id, type, displayName } = row;

        update(rowIdx, {
          id,
          type,
          displayName,
          required: checked,
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  const handleRemove = useCallback(
    (rowId: string) => {
      const rowIdx = fields.findIndex((row) => row.id === rowId);
      remove(rowIdx);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  const columns: GridColDef<AcquisitionInformationInput>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 80,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: (params: GridRowParams<AcquisitionInformationInput>) => [
          <IconButton onClick={() => handleRemove(params.row.id || '')} disabled={disabled}>
            {<DeleteOutlineOutlinedIcon />}
          </IconButton>,
        ],
      },
      {
        sortable: false,
        field: 'displayName',
        headerName: t('display_name'),
        width: JSON.parse(columnsSize).displayName || 150,
        renderCell: ({ row, api }) => {
          const idx = api.getRowIndexRelativeToVisibleRows(row.id || '');
          return (
            <StyledTooltip
              title={t('form_validation.this_field_cannot_be_empty')}
              open={!!errors.acquisitionInformation?.[idx]?.displayName}
            >
              <TextField
                variant="outlined"
                disabled={disabled}
                value={row.displayName}
                onChange={handleChangeTextField(row, 'displayName')}
              />
            </StyledTooltip>
          );
        },
      },
      {
        field: 'type',
        sortable: false,
        type: 'singleSelect',
        headerName: t('type'),
        width: JSON.parse(columnsSize).type || 150,
        renderCell: ({ row }) => (
          <TextField
            select
            value={row.type}
            fullWidth
            variant="outlined"
            disabled={disabled}
            onChange={handleChangeTextField(row, 'type')}
          >
            {typeOptions(row).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ),
      },
      {
        type: 'boolean',
        sortable: false,
        field: 'required',
        headerName: t('required_field'),
        width: JSON.parse(columnsSize).required || 150,
        renderCell: ({ row }) => (
          <Checkbox checked={!!row.required} onChange={handleChangeCheckbox(row)} disabled={disabled} />
        ),
      },
    ];
  }, [
    t,
    disabled,
    errors.acquisitionInformation,
    typeOptions,
    handleRemove,
    handleChangeCheckbox,
    handleChangeTextField,
  ]);

  return (
    <CustomDataGrid
      autoHeight
      rows={fields}
      rowHeight={59}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      slots={{
        toolbar: !disabled ? AcquisitionToolbar : undefined,
        pagination: Empty,
      }}
      slotProps={{
        toolbar: { control, append },
      }}
      localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
    />
  );
};

export default AcquisitionInformation;
