import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIpfsGateways } from '@gu-corp/react-ipfs-media';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getAddress } from 'ethers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { nftStatus } from '../CollectionDetail/MemberSiteCollectionDetail';

import CopyableAddress from '~/components/CopyableAddress';
import CustomCardTable from '~/components/custom-card-table';
import HomeBtn from '~/components/home-btn';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import LinkButton from '~/components/link-button';
import ListTable, { IHandleListTable } from '~/components/list-table';
import LoaderCenter from '~/components/loader-center';
import SquareImage from '~/components/SquareImage';
import { CHIP_COLOR, ITEMS_PER_PAGE } from '~/constants/common';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { ContextAPIEnum } from '~/enum/common';
import { env } from '~/env';
import {
  Collection,
  ListMemberTokensQueryQuery,
  MyShopCollectionQueryKey,
  QueryOperator,
  useGetMemberQuery,
  useListMemberTokensQueryLazyQuery,
  useListMyShopCollectionsQuery,
} from '~/graphql/member/types';
import {
  _SubgraphErrorPolicy_,
  OrderDirection,
  Token_OrderBy,
  TokensQuery,
  useTokensLazyQuery,
  useTokensQuery,
} from '~/graphql/subgraph/types';
import { MEMBER_SITE_NFT_STATUS, MemberSiteDetailTab, SHOP_TYPE } from '~/types/my-shop';
import {
  convertOrderToAPI,
  convertOrderToSubgraph,
  generateQueryString,
  getLocalStorage,
  setLocalStorageItems,
  verifySortKey,
  verifySubgraphOrderKey,
} from '~/utils/common';
import { getExpireDate } from '~/utils/getExpireDate';
import { getNFTMetadata, NFTToken } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

interface ICollectionInfo {
  [key: string]: Collection;
}

const initParams: IHandleListTable = {
  page: 1,
  searchText: '',
  limit: ITEMS_PER_PAGE.LIST,
  sortBy: verifySortKey(Token_OrderBy, getLocalStorage('member_detail_sort'), Token_OrderBy.MintTime),
  orderBy: verifySubgraphOrderKey(getLocalStorage('member_detail_order')),
};

export interface MemberNFT {
  id: string;
  url: string;
  name: string;
  status: string;
  collectionName: string;
  activatedAt: Date | string;
  [Token_OrderBy.TokenId]: string;
  [Token_OrderBy.ContractId]: string;
  expireDate: string | number | null;
  [Token_OrderBy.MintTime]: Date | string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiTypography-subtitle1': {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '26px',
      color: '#333333',
      marginBottom: '16px',
    },
  },
  wrapperContent: {
    '.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const MemberDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { ipfsGateways } = useIpfsGateways();
  const { data: memberSite } = useShopDetail();
  const { id: memberSiteId, memberId } = useParams();
  const { supportedNetworks } = useSupportedNetworks();

  const latestNetwork = Object.values(supportedNetworks || {})?.[0];

  const [rowCount, setRowCount] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [nftsQuery, setNFTsQuery] = useState(initParams);
  const [tokenList, setTokenList] = useState<MemberNFT[]>([]);

  useEffect(() => {
    setLocalStorageItems({
      member_detail_sort: nftsQuery?.sortBy,
      member_detail_order: nftsQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftsQuery?.orderBy, nftsQuery?.sortBy]);

  const { data: memberRes, loading } = useGetMemberQuery({
    fetchPolicy: 'cache-and-network',
    skip: !memberId,
    variables: {
      uuid: memberId ?? '',
      shopUuid: memberSiteId ?? '',
    },
    onError: () => navigate(generatePath(AppRouteEnum.MemberSiteDetail, { id: memberSiteId || '' })),
  });
  const member = memberRes?.getMember;

  const { data: listMyShopCollectionsRes } = useListMyShopCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !member,
    variables: {
      where: {
        shopType: SHOP_TYPE.MEMBER,
        fields: [
          {
            value: [memberSiteId || ''],
            operator: QueryOperator.Equals,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });
  const collectionInfo = useMemo(
    () =>
      listMyShopCollectionsRes?.listMyShopCollections.items?.reduce((result, myShopCollection) => {
        const collection = myShopCollection.collection;
        const network = collection?.network;
        const contractAddress = collection?.contractAddress?.toLowerCase();
        if (!!contractAddress && network === latestNetwork.chainId) {
          result[contractAddress] = collection!;
        }
        return result;
      }, {} as ICollectionInfo),
    [latestNetwork, listMyShopCollectionsRes?.listMyShopCollections.items]
  );

  const updateNFTsQuery = ({ orderBy, ...newValue }: IHandleListTable) => {
    const orderDirection = !!orderBy ? { orderBy: convertOrderToSubgraph(orderBy) } : {};
    return setNFTsQuery((value) => ({
      ...value,
      ...newValue,
      ...orderDirection,
    }));
  };

  const [getTokens] = useTokensLazyQuery();
  const [listMemberTokens] = useListMemberTokensQueryLazyQuery();

  const subgraphVariables = useMemo(() => {
    const isTokenID = RegExp('^\\d+$').test(nftsQuery.searchText || '');
    const _skip =
      Number(nftsQuery.page) && Number(nftsQuery.limit)
        ? ((nftsQuery.page || 1) - 1) * (nftsQuery.limit || 1)
        : undefined;

    return {
      skip: _skip,
      first: nftsQuery.limit,
      subgraphError: _SubgraphErrorPolicy_.Deny,
      orderBy: nftsQuery.sortBy as Token_OrderBy,
      orderDirection: nftsQuery.orderBy as OrderDirection,
      where: {
        owner: member?.wallet?.toLowerCase() || '',
        tokenID: isTokenID ? nftsQuery.searchText : undefined,
        contract_in:
          !!nftsQuery.searchText && !isTokenID
            ? [nftsQuery.searchText.toLowerCase()]
            : Object.keys(collectionInfo || {}),
      },
    };
  }, [member, nftsQuery, collectionInfo]);

  const processNFTStatus = useCallback(
    (
      newExpireDate: number | string | null,
      memberToken: ListMemberTokensQueryQuery['listMemberTokensQuery'][number]
    ) => {
      const currentDate = moment();

      const newStatus = !member
        ? MEMBER_SITE_NFT_STATUS.NOT_REGISTERED
        : !!member.email && !member.verifyEmail
        ? MEMBER_SITE_NFT_STATUS.NOT_CONFIRM
        : !memberToken
        ? MEMBER_SITE_NFT_STATUS.UNRESTRICTED
        : currentDate.isSameOrAfter(newExpireDate)
        ? MEMBER_SITE_NFT_STATUS.EXPIRED
        : memberToken?.status || MEMBER_SITE_NFT_STATUS.VALID;

      return newStatus;
    },
    [member]
  );

  const getMemberTokensObj = useCallback(async (data: TokensQuery) => {
    const memberTokensQuery = data.tokens.map((token) => ({
      tokenId: token.tokenID,
      wallet: getAddress(token.owner.id),
      contractAddress: getAddress(token.contract.id),
    }));

    const { data: memberTokensRes } = await listMemberTokens({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          filterItems: memberTokensQuery,
        },
      },
    });

    const _memberTokensObj =
      memberTokensRes?.listMemberTokensQuery.reduce((result, memberToken) => {
        const lowercaseContractAddress = memberToken.contractAddress?.toLowerCase() || '';
        result[`${lowercaseContractAddress}_${memberToken.tokenId}`] = memberToken;
        return result;
      }, {} as Record<string, ListMemberTokensQueryQuery['listMemberTokensQuery'][number]>) || {};

    return _memberTokensObj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processNFTsData = useCallback(
    async (data: TokensQuery) => {
      setProcessing(true);
      const nftActivationSettings = memberSite?.nftActivationSettings;
      if (!nftActivationSettings) {
        setProcessing(false);
        return;
      }
      const { acquisitionInformation: _, ...expireObj } = nftActivationSettings;

      const memberTokensObj = await getMemberTokensObj(data);

      const result = await Promise.all(
        data.tokens.map(async (token) => {
          const collection = collectionInfo?.[token.contract.id];
          let metadata: NFTToken | undefined;
          if (collection) {
            metadata = await getNFTMetadata(collection.uuid, token, ipfsGateways);
          }

          const metadataContent = metadata?.metadataContent;
          const mintTime = moment.unix(Number(token.mintTime));
          const newExpireDate = getExpireDate(expireObj, mintTime.toString());
          const memberToken = memberTokensObj[`${token.contract.id}_${token.tokenID}`];
          const imageURL = metadataContent?.image.includes('ipfs')
            ? env.REACT_APP_IPFS_GATEWAY_URL + encodeURI(metadataContent?.image.replace('ipfs://', ''))
            : metadataContent?.image || '';

          const status = processNFTStatus(newExpireDate, memberToken);
          const activatedAt = moment(memberToken?.createdAt).format(t('date_time_format'));
          const expireDate = !newExpireDate
            ? ''
            : typeof newExpireDate === 'number'
            ? 'Infinity'
            : moment(newExpireDate).format(t('date_time_format'));

          return {
            status,
            expireDate,
            activatedAt,
            id: token.id,
            url: imageURL,
            collectionName: collection?.name || '',
            [Token_OrderBy.TokenId]: token.tokenID,
            name: metadata?.metadataContent.name || '',
            [Token_OrderBy.ContractId]: token.contract.id,
            [Token_OrderBy.MintTime]: mintTime.format(t('date_time_format')),
          };
        })
      );
      setTokenList(result);

      const _skip =
        Number(nftsQuery.page) && Number(nftsQuery.limit) ? (nftsQuery.page || 1) * (nftsQuery.limit || 1) : undefined;
      await getTokens({
        fetchPolicy: 'cache-and-network',
        context: {
          blockchain: ContextAPIEnum.Subgraph,
          subgraphUrl: latestNetwork.subgraphUrl,
        },
        variables: {
          ...subgraphVariables,
          skip: _skip,
        },
        onCompleted: (nextData) => {
          const currentPage = nftsQuery.page || 1;
          const hasNextPage = !!nextData?.tokens?.length;
          const totalPreviousPages = ITEMS_PER_PAGE.LIST * (currentPage - 1);
          const currentPageLength = data?.tokens.length || 0;
          const estimateNextPage = hasNextPage ? ITEMS_PER_PAGE.LIST : 0;

          const _rowCount = totalPreviousPages + currentPageLength + estimateNextPage;
          setRowCount(_rowCount);
        },
      });
      setProcessing(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      nftsQuery,
      memberSite,
      ipfsGateways,
      collectionInfo,
      subgraphVariables,
      latestNetwork.subgraphUrl,
      processNFTStatus,
      getMemberTokensObj,
    ]
  );

  const { loading: loadingTokenList } = useTokensQuery({
    fetchPolicy: 'cache-and-network',
    context: {
      blockchain: ContextAPIEnum.Subgraph,
      subgraphUrl: latestNetwork.subgraphUrl,
    },
    skip: !collectionInfo || !member,
    variables: subgraphVariables,
    onCompleted: processNFTsData,
  });

  const columns: GridColDef<MemberNFT>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value }) => {
          return (
            <Box width="64px">
              <SquareImage isNFT src={value} />
            </Box>
          );
        },
      },
      {
        field: 'name',
        sortable: false,
        headerName: t('my_shop.nft_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        headerName: t('token_id'),
        field: Token_OrderBy.TokenId,
        width: JSON.parse(columnsSize).name || 100,
      },
      {
        field: 'status',
        sortable: false,
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 150,
        renderCell: ({ row }) => {
          const status = (row.status as MEMBER_SITE_NFT_STATUS) || MEMBER_SITE_NFT_STATUS.VALID;
          return <Chip label={`${nftStatus(t)[status]?.title || ''}`} color={CHIP_COLOR[status]} />;
        },
      },
      {
        sortable: false,
        field: 'expireDate',
        getApplyQuickFilterFn: undefined,
        headerName: t('member_site.effective_date'),
        width: JSON.parse(columnsSize).createdAt || 150,
      },
      {
        ellipsis: true,
        sortable: false,
        field: Token_OrderBy.ContractId,
        getApplyQuickFilterFn: undefined,
        headerName: t('contract_address'),
        width: JSON.parse(columnsSize).contractAddress || 150,
        renderCell: ({ formattedValue }) => (
          <CopyableAddress title={formattedValue} href={`${latestNetwork.blockExplorer}/address/${formattedValue}`} />
        ),
      },
      {
        ellipsis: true,
        sortable: false,
        field: 'collectionName',
        getApplyQuickFilterFn: undefined,
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).collectionName || 150,
      },
      {
        sortable: false,
        field: 'activatedAt',
        getApplyQuickFilterFn: undefined,
        headerName: t('member_site.activated_at'),
        width: JSON.parse(columnsSize).activatedAt || 150,
      },
      {
        headerName: t('created_at'),
        field: Token_OrderBy.MintTime,
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 150,
      },
    ];
  }, [t, latestNetwork.blockExplorer]);

  if (loading) {
    return <LoaderCenter />;
  }

  if (!member) {
    return <Navigate to={generatePath(AppRouteEnum.MemberSiteDetail, { id: memberSiteId || '' })} />;
  }

  const pagination = { currentPage: nftsQuery.page, itemsPerPage: ITEMS_PER_PAGE.LIST };

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.MemberSite}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('member_site.member_site')}
        </Link>
        <Link
          component={RouterLink}
          to={generatePath(AppRouteEnum.MemberSiteDetail, { id: memberSiteId || '' })}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          style={{ wordBreak: 'break-all' }}
        >
          {memberSite?.siteSetting?.title || '-'}
        </Link>
        <Typography
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.secondary"
        >
          {member?.wallet ? truncateEthAddress(member.wallet) : '-'}
        </Typography>
      </Breadcrumbs>
      <LinkButton
        startIcon={<ArrowBackIcon />}
        to={`${generatePath(AppRouteEnum.MemberSiteDetail, { id: memberSiteId || '' })}${generateQueryString({
          tab: MemberSiteDetailTab.MEMBER_MANAGEMENT,
        })}`}
      >
        {t('back')}
      </LinkButton>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={
            <Box gap="8px" display="flex" alignItems="center">
              {truncateEthAddress(member.wallet || '-')}
              <IconBtnCopy text={member.wallet || '-'} />
            </Box>
          }
          cardContent={
            <Box className={classes.wrapperContent}>
              <ListTable
                noBorder
                rowHeight={89}
                rows={tokenList}
                columns={columns}
                rowCount={rowCount}
                tableName="memberDetail"
                search={nftsQuery.searchText}
                pageSizeOptions={[ITEMS_PER_PAGE.LIST]}
                isLoading={loadingTokenList || processing}
                searchLabel={`${t('token_id')}, ${t('contract_address')}`}
                sort={{
                  sortBy: nftsQuery.sortBy,
                  orderBy: convertOrderToAPI(nftsQuery.orderBy),
                }}
                paginationData={pagination}
                onSort={updateNFTsQuery}
                onPagination={updateNFTsQuery}
                onSearch={(v) => updateNFTsQuery({ page: 1, searchText: v || '' })}
              />
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default MemberDetail;
