import { FC } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
  },
  setupHeader: {
    display: 'flex',
    margin: '16px 0',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'unset',
    },
    alignItems: 'start',
    justifyContent: 'space-between',
    '.MuiTypography-h2': {
      margin: 0,
      fontWeight: 700,
      color: '#111',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  step: {
    fontSize: 16,
    fontWeight: 700,
    minWidth: '63px',
    marginRight: '8px',
  },
  content: {
    display: 'flex',
    '&:not(:last-of-type)': {
      marginBottom: '16px',
    },
    h4: {
      margin: '0',
      color: '#111',
      fontSize: '16px',
    },
  },
  instruction: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#363636',
  },
}));

const FincodeSetupGuide: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const instruction = [
    {
      step: t('settings.shop_payment.step1.fincode.label'),
      label: t('settings.shop_payment.step1.fincode.method'),
      content: t('settings.shop_payment.step1.fincode.detail'),
      link: 'https://dashboard.fincode.jp/',
    },
    {
      step: t('settings.shop_payment.step2.fincode.label'),
      label: t('settings.shop_payment.step2.fincode.method'),
      content: t('settings.shop_payment.step2.fincode.detail'),
      link: '',
    },
    {
      step: t('settings.shop_payment.step3.fincode.label'),
      label: t('settings.shop_payment.step3.fincode.method'),
      content: t('settings.shop_payment.step3.fincode.detail'),
      link: '',
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.setupHeader}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h2">{t('settings.shop_payment.fincode_setup')}</Typography>
        </Box>
      </Box>
      {instruction.map((item) => (
        <Box className={classes.content} key={item.label}>
          <Typography color="primary" className={classes.step}>
            {item.step}
          </Typography>
          <Box>
            <h4>{item.label}</h4>
            <Typography variant="caption" className={classes.instruction}>
              <Trans
                i18nKey={item.content}
                components={{
                  lnk: <Link sx={{ color: '#1098FC' }} href="https://dashboard.fincode.jp/" target="_blank" />,
                }}
              />
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default FincodeSetupGuide;
