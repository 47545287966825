import { ChangeEvent, useCallback } from 'react';

import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
  },
  paper: {
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(4, 2),
    textAlign: 'center',
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
}));
interface Props extends StyledComponentProps<typeof useStyles> {
  headerTitle: string;
  hideLanguage?: boolean;
}

const AuthLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );

  return (
    <Container className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h5" color="white" sx={{ textAlign: 'center' }}>
              {props.headerTitle}
            </Typography>
          </div>
          <div className={classes.content}>
            <div>{props.children}</div>
          </div>
        </Paper>
        {!props.hideLanguage && (
          <div className={classes.language}>
            <TextField
              name="language"
              onChange={onLanguageChange}
              value={i18n.language}
              select
              color="primary"
              variant="outlined"
              fullWidth
              hiddenLabel
              size="small"
            >
              {languageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AuthLayout;

export const languageOptions = [
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'en',
    label: 'English',
  },
];
