export enum AppRouteEnum {
  Home = '/',
  Plans = '/plans',
  OidcCallback = '/oidc-callback',
  Collection = '/collection',
  CollectionDetail = '/collection/:collectionId',
  Shop = '/shop/:shopName',
  MyShop = '/my-shop',
  CreateShop = '/my-shop/create',
  EditShop = '/my-shop/:id/edit-shop',
  ShopDetail = '/my-shop/:id',
  ShopCollectionDetail = '/my-shop/:id/collection/:collectionId',
  MemberSite = '/member-site',
  BuyerMemberSite = '/member/:shopName',
  CreateMemberSite = '/member-site/create',
  MemberSiteMemberDetail = '/member-site/:id/member/:memberId',
  MemberSiteNFTs = '/member-site/:id/nfts',
  EditMemberSite = '/member-site/:id/edit-member-site',
  MemberSiteDetail = '/member-site/:id',
  MemberSiteCollectionDetail = '/member-site/:id/collection/:collectionId',
  SignIn = '/sign-in',
  Setting = '/setting',
  NotFound = '/404',
}
