import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import PlanCard from './PlanCard';

import CustomDialog from '~/components/dialog/custom-dialog';
import PlanComparisonDialog from '~/components/dialog/plan-comparison-dialog';
import { Currency, GetPlansQuery, useGetNextPlanQuery, useGetPlansQuery } from '~/graphql/member/types';

const Plans = () => {
  const { t } = useTranslation();

  const [openComparison, setOpenComparison] = useState(false);
  const [openDowngrading, setOpenDowngrading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<GetPlansQuery['getPlans'][number]>();

  const { data: plansRes } = useGetPlansQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: Currency.Usd,
    },
  });
  const { data: getNextPlan } = useGetNextPlanQuery({
    fetchPolicy: 'cache-and-network',
  });
  const nextPlan = getNextPlan?.getNextPlan;
  const plans = plansRes?.getPlans;

  const handleOpenComparison = () => {
    setOpenComparison(true);
  };
  const handleCloseComparison = () => {
    setOpenComparison(false);
  };

  const handleOpenDowngrading = () => {
    setOpenDowngrading(true);
  };
  const handleCloseDowngrading = () => {
    setOpenDowngrading(false);
  };

  const handleSelectPlan = (plan: GetPlansQuery['getPlans'][number]) => {
    if (nextPlan?.uuid === plan.uuid) {
      handleOpenDowngrading();
    } else {
      setSelectedPlan(plan);
      handleOpenComparison();
    }
  };

  useEffect(() => {
    document.title = t('plans.title');
  }, [t]);

  return (
    <>
      <Stack gap="40px" width="100%" alignItems="center">
        <Box>
          <Typography variant="h3" textAlign="center">
            {t('choose_the_right_plan_for_you')}
          </Typography>
          <Typography variant="body2" textAlign="center" marginTop="8px">
            {t('plans.only_owner_can_select')}
          </Typography>
        </Box>
        <Grid container spacing={3} justifyContent="center">
          {plans?.map((plan) => (
            <Grid item key={plan.uuid} xs={12} sm={6} lg={3}>
              <PlanCard plan={plan} onSelectPlan={handleSelectPlan} />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {!!selectedPlan && (
        <PlanComparisonDialog open={openComparison} selectedPlan={selectedPlan} onClose={handleCloseComparison} />
      )}
      <CustomDialog
        width="sm"
        open={openDowngrading}
        dialogTitle={t('settings.plan_management.downgrade_is_processing')}
        onClose={handleCloseDowngrading}
        dialogContent={
          <Typography>
            <Trans
              i18nKey="change_plan_desc"
              values={{
                planName: nextPlan?.planName || '-',
                startDate: moment(nextPlan?.startAt || '').format(t('date_time_format')),
              }}
              components={{
                b: <strong />,
              }}
            />
          </Typography>
        }
        actions={[
          <Button variant="contained" onClick={handleCloseDowngrading}>
            {t('confirm')}
          </Button>,
        ]}
      />
    </>
  );
};

export default Plans;
