import { FC, Fragment, PropsWithChildren, useMemo, useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '../custom-dialog';
import PlanInvoiceDialog from '../plan-invoice-dialog';

import { colors } from '~/constants/colors';
import { CURRENCY_ICONS } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import {
  Currency,
  GetNextPlanDocument,
  GetPlansQuery,
  RecurrenceType,
  useGetInfoUsageQuery,
  useSubscribePlanMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';

interface IPlanComparisonDialog extends PropsWithChildren {
  open: boolean;
  selectedPlan: GetPlansQuery['getPlans'][number];
  onClose: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapperTable: {
    '.MuiTable-root': {
      minWidth: 400,
    },
    '.MuiTableCell-root': {
      padding: '12px 0',
      '&.MuiTableCell-head': {
        verticalAlign: 'top',
      },
    },
  },
  alert: {
    marginBottom: 1,
    ul: {
      marginBlockEnd: 0,
      marginBlockStart: 0,
      paddingInlineStart: '20px',
    },
  },
}));

const licenseInfo = [
  {
    freeKey: 'numberOfAdminUsers',
    warningLabel: 'plans.user_licenses',
    nextMonthKey: 'userLicensesNextMonth',
    comparisonTitle: 'plans.number_of_users',
    comparisonItems: [
      { label: 'plans.number_of_free_users', value: 'numberOfAdminUsers' },
      { label: 'plans.additional_fee_per_user', value: 'additionalUserFee' },
    ],
  },
  {
    freeKey: 'numberOfShops',
    warningLabel: 'plans.shop_licenses',
    nextMonthKey: 'shopLicensesNextMonth',
    comparisonTitle: 'plans.number_of_shops',
    comparisonItems: [
      { label: 'plans.number_of_free_shops', value: 'numberOfShops' },
      { label: 'plans.additional_fee_per_shop', value: 'additionalShopFee' },
    ],
  },
  {
    freeKey: 'numberOfMemberSites',
    warningLabel: 'plans.member_site_licenses',
    nextMonthKey: 'memberSiteLicensesNextMonth',
    comparisonTitle: 'plans.number_of_member_sites',
    comparisonItems: [
      { label: 'plans.number_of_free_sites', value: 'numberOfMemberSites' },
      { label: 'plans.additional_fee_per_member_site', value: 'additionalSiteFee' },
    ],
  },
  {
    freeKey: 'numberOfMemberPerSite',
    nextMonthKey: 'memberPerSiteLicensesNextMonth',
    comparisonTitle: 'plans.number_of_members_per_site',
    warningLabel: 'plans.member_licenses_per_member_site',
    comparisonItems: [
      { label: 'plans.additional_fee_per_member_license', value: 'additionalMemberFee' },
      { label: 'plans.number_of_members_per_license', value: 'numberOfMembersPerLicense' },
      { label: 'plans.number_of_free_members_per_site', value: ['numberOfMemberPerSite', 'numberOfMembersPerLicense'] },
    ],
  },
];

const calculatePlanValue = (plan: GetPlansQuery['getPlans'][number] | undefined | null, value: string | string[]) => {
  if (typeof value === 'string') {
    return plan?.[value as 'numberOfAdminUsers'] || 0;
  } else {
    return value.reduce((result, item) => {
      return result * (plan?.[item as 'numberOfAdminUsers'] || 0);
    }, 1);
  }
};

const PlanComparisonDialog: FC<IPlanComparisonDialog> = ({ open, selectedPlan, onClose }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { plan: currentPlan } = useAccount();
  const { show } = usePaymentMethodRequired();
  const { showError, showErrorByKey } = useNotify();

  const [openInvoice, setOpenInvoice] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openScheduled, setOpenScheduled] = useState(false);

  const { data: infoUsageRes } = useGetInfoUsageQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
  });
  const [subscribePlan] = useSubscribePlanMutation({
    variables: {
      input: {
        activeNow: false,
        uuid: selectedPlan.uuid,
        recurrence: RecurrenceType.Monthly,
      },
    },
    refetchQueries: [GetNextPlanDocument],
  });

  const infoUsage = infoUsageRes?.getInfoUsage;
  const isDowngrade = (selectedPlan.monthlyFee || 0) < (currentPlan?.monthlyFee || 0);

  const diffLicenses = useMemo(
    () =>
      isDowngrade
        ? licenseInfo.reduce((result, info) => {
            const numberNextMonth = infoUsage?.[info.nextMonthKey as 'userLicensesNextMonth'] || 0;
            const numberFree = selectedPlan?.[info.freeKey as 'numberOfAdminUsers'] || 0;
            const diff = Math.max(numberNextMonth - numberFree, 0);
            if (!!diff) {
              result.push({
                value: diff,
                label: info.warningLabel,
              });
            }
            return result;
          }, [] as { value: number; label: string }[])
        : undefined,
    [infoUsage, isDowngrade, selectedPlan]
  );

  const contents = isDowngrade
    ? {
        title: 'plans.downgrading_plan_title',
        warning: 'plans.downgrading_plan_warning',
      }
    : {
        title: 'plans.upgrading_plan_title',
        warning: 'plans.upgrading_plan_warning',
      };

  const handleOpenScheduled = () => {
    setOpenScheduled(true);
  };
  const handleCloseScheduled = () => {
    onClose();
    setOpenScheduled(false);
  };

  const handleOpenInvoice = () => {
    setOpenInvoice(true);
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleConfirmChange = async () => {
    setIsSubmitting(true);
    try {
      if (isDowngrade) {
        const openRegisterPaymentMethod = await show({
          description: '',
        });
        if (openRegisterPaymentMethod) {
          setIsSubmitting(false);
          showErrorByKey('toast_message.payment_failed');
          return;
        }
        await subscribePlan();
        handleOpenScheduled();
      } else {
        handleOpenInvoice();
      }
    } catch (error) {
      showError(error);
    }
    setIsSubmitting(false);
  };

  const formatPrice = (price?: number | null) =>
    price
      ? t('plans.price_per_month', { price: `${CURRENCY_ICONS[Currency.Usd]}${price.toLocaleString()}` })
      : t('free');

  return (
    <>
      <CustomDialog
        width="lg"
        open={open}
        onClose={onClose}
        dialogTitle={t(contents.title)}
        dialogContent={
          <Box>
            <Alert severity="warning" className={classes.alert}>
              <AlertTitle>{t(contents.warning)}</AlertTitle>
              {diffLicenses && (
                <ul>
                  {diffLicenses.map((license, idx) => (
                    <li key={idx}>
                      <Typography>{`${t(license.label)}: ${license.value}`}</Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Alert>
            <TableContainer className={classes.wrapperTable}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }} />
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      <Stack>
                        <Typography variant="subtitle1" fontWeight={500}>
                          {currentPlan?.planName}
                        </Typography>
                        <Typography variant="h5">{formatPrice(currentPlan?.monthlyFee)}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                      <Stack>
                        <Typography variant="subtitle1" fontWeight={500}>
                          {selectedPlan?.planName}
                        </Typography>
                        <Typography variant="h5">{formatPrice(selectedPlan?.monthlyFee)}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenseInfo.map((info, idx) => (
                    <Fragment key={idx}>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle1" fontWeight={500}>
                            {t(info.comparisonTitle)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {info.comparisonItems.map((item, idx) => {
                        const current = calculatePlanValue(currentPlan, item.value);
                        const next = calculatePlanValue(selectedPlan, item.value);
                        const isSmaller = current > next;
                        const isEqual = current === next;
                        return (
                          <TableRow key={idx}>
                            <TableCell>{t(item.label)}</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 500 }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                {current.toLocaleString()}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={isEqual ? {} : { color: isSmaller ? colors.red : colors.primary }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: 500,
                                  color: isEqual ? 'inherit' : isSmaller ? colors.red : colors.primary,
                                }}
                              >
                                {next.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        }
        actions={[
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>,
          <Button
            variant="contained"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleConfirmChange}
          >
            {t('confirm')}
          </Button>,
        ]}
      />
      <CustomDialog
        width="sm"
        open={openScheduled}
        dialogTitle={t('plans.scheduled_successfully')}
        onClose={handleCloseScheduled}
        dialogContent={
          <Typography>
            <Trans
              i18nKey="plans.scheduled_successfully_desc"
              values={{
                planName: selectedPlan?.planName || '-',
                startDate: moment().add(1, 'month').startOf('month').format(t('date_time_format')),
              }}
              components={{
                b: <strong />,
              }}
            />
          </Typography>
        }
        actions={[
          <Button variant="contained" onClick={handleCloseScheduled}>
            {t('confirm')}
          </Button>,
        ]}
      />
      <PlanInvoiceDialog onNext={onClose} open={openInvoice} plan={selectedPlan} onClose={handleCloseInvoice} />
    </>
  );
};

export default PlanComparisonDialog;
