import { useCallback, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ScreenLoading from '~/components/screen-loading';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useNotify } from '~/hooks/useNotify';
import { oidcUserManager } from '~/oidc';
import { shouldShowSnackbar } from '~/utils/system.util';

const OidcCallbackPage = () => {
  const navigate = useNavigate();
  const hasCalled = useRef(false);
  const { showError } = useNotify();
  const { t } = useTranslation();

  const handleLoginCallback = useCallback(async () => {
    if (window.self === window.top) {
      try {
        const user = await oidcUserManager.signinCallback();
        navigate(AppRouteEnum.SignIn, { state: { user } });
      } catch (error: any) {
        if (shouldShowSnackbar(error)) {
          showError(error);
        }
        navigate(AppRouteEnum.SignIn);
      }
    } else {
      // The user is in an iframe
      await oidcUserManager.signinSilentCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (!hasCalled.current) {
      handleLoginCallback();
      hasCalled.current = true;
    }
  }, [handleLoginCallback]);

  return (
    <>
      <ScreenLoading />
    </>
  );
};

export default OidcCallbackPage;
