import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { UserLoadedCallback } from 'oidc-client-ts';
import { useTranslation } from 'react-i18next';

import { useLogout } from './use-logout';

import ScreenLoading from '~/components/screen-loading';
import { useGetMeQuery, useLoginMutation, UserOrs, useUpdateUserMutation } from '~/graphql/member/types';
import { oidcUserManager } from '~/oidc';

const SessionContext = createContext<{
  loadingAccount: boolean;
  account: UserOrs | undefined | null;
  errorAccount: ApolloError | undefined;
}>({} as any);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!Object.values(context).length) {
    throw new Error('useSession must be used within a WithSession');
  }
  return context;
};

export const WithSession: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { i18n } = useTranslation();

  const hasCalled = useRef(false);

  const logout = useLogout();
  const [updateUser] = useUpdateUserMutation({
    variables: {
      input: {
        language: i18n.language,
      },
    },
  });

  const [checkingSession, setCheckingSession] = useState(true);

  const [login] = useLoginMutation();
  const {
    data: getMeRes,
    error: errorAccount,
    loading: loadingAccount,
  } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!data.getMe) return;
      const userLanguage = data.getMe.language as 'ja' | 'en' | undefined;
      if (!userLanguage) {
        updateUser();
        return;
      }
      if (userLanguage !== i18n.language) {
        i18n.changeLanguage(userLanguage);
      }
    },
  });

  const account = getMeRes?.getMe;

  useEffect(() => {
    const userLoadedCallback: UserLoadedCallback = async (user) => {
      await login({ variables: { input: { accessToken: user.access_token } } });
    };
    oidcUserManager.events.addUserLoaded(userLoadedCallback);
    return () => {
      oidcUserManager.events.removeUserLoaded(userLoadedCallback);
    };
  }, [login]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const user = await oidcUserManager.getUser();
        if (!user || user.expired) {
          await oidcUserManager.signinSilent();
        }
      } catch (error) {
        await logout({ disableConfirmDialog: true, logoutOidc: false });
      } finally {
        setCheckingSession(false);
      }
    };
    if (!hasCalled.current) {
      checkSession();
      hasCalled.current = true;
    }
  }, [logout]);

  if (checkingSession) {
    return <ScreenLoading />;
  }

  return (
    <SessionContext.Provider value={{ account, loadingAccount, errorAccount }}>
      {props.children}
    </SessionContext.Provider>
  );
};
