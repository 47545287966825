import { ChangeEventHandler, FC, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

import CustomDialog, { ICustomDialog } from '../custom-dialog';

import { GetMeDocument, useGetInfoUsageQuery, useRemoveOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';

const DeleteOrganizationDialog: FC<ICustomDialog> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useNotify();
  const { selectedOrganization, setSelectedOrganization } = useAccount();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  const { data: getInfoUsage } = useGetInfoUsageQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [removeOrganization] = useRemoveOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  const isSame = organizationName === selectedOrganization.name;
  const shopQuantity = getInfoUsage?.getInfoUsage.numberShop || 0;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;

    setOrganizationName(value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await removeOrganization();
      setSelectedOrganization('');
      showSuccess('toast_message.deleted_successfully');
    } catch (err) {
      showError(err);
    }
    setIsSubmitting(false);
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      dialogTitle={t('settings.general.delete_organization')}
      actions={[
        <Button
          variant="contained"
          color="error"
          disabled={!isSame}
          startIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit}
        >
          {t('settings.general.delete_organization')}
        </Button>,
      ]}
      dialogContent={
        <>
          <Alert severity="error">{t('settings.general.important_alert')}</Alert>
          <Stack gap={2} margin="16px 0">
            <Typography variant="body1">
              <Trans
                i18nKey="settings.general.delete_org_desc_1"
                values={{
                  shopQuantity,
                }}
                components={{
                  b: <strong />,
                }}
              />
            </Typography>
            <Typography variant="body1">
              <Trans
                i18nKey="settings.general.delete_org_desc_2"
                components={{
                  b: <strong />,
                }}
              />
            </Typography>
            <Typography variant="body1">
              <Trans
                i18nKey="settings.general.delete_org_desc_3"
                components={{
                  b: <strong />,
                }}
              />
            </Typography>
          </Stack>
          <Divider />
          <Box marginTop={2}>
            <Typography variant="body1">
              <Trans
                i18nKey="settings.general.verify_org_name_label"
                values={{
                  organizationName: selectedOrganization.name,
                }}
                components={{
                  i: <em />,
                  b: <strong />,
                }}
              />
            </Typography>
            <TextField
              required
              fullWidth
              margin="dense"
              variant="outlined"
              disabled={isSubmitting}
              onChange={handleChange}
            />
          </Box>
        </>
      }
    />
  );
};

export default DeleteOrganizationDialog;
