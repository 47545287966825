import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { WalletRequired } from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatEther, JsonRpcProvider } from 'ethers';
import moment, { Moment } from 'moment';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import DepositDialog from '~/components/dialog/deposit-dialog';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { colors } from '~/constants/colors';
import { MAX_NUMBER } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { GetMeDocument, useAutoBuyPointMutation, useUpdateOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { getErrorText } from '~/utils/yup.util';

const { BUY_POINTS, REFUND } = SCREEN_PERMISSION.SETTING.POINTS;

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    minHeight: '150px',
    paddingBottom: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    '.wrapperContent': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      '.content': {
        gap: '32px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiTypography-body1': {
          fontSize: '16px',
          strong: {
            fontSize: '32px',
          },
        },
      },
    },
    '.actions': {
      gap: '16px',
      width: '100%',
      display: 'flex',
      marginTop: '16px',
      justifyContent: 'center',
    },
  },
}));

type MuiSwitchType = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;

export interface ISelectedNetwork {
  chainId: string;
  isWithdraw?: boolean;
}

const schema = yup.object({
  autoBuy: yup.boolean(),
  maxAmount: yup.string().when('autoBuy', {
    is: true,
    then: (schema) => schema.maxNumber(MAX_NUMBER).required(),
  }),
  threshold: yup.string().when('autoBuy', {
    is: true,
    then: (schema) => schema.maxNumber(MAX_NUMBER).required(),
  }),
});

export interface FormSetupAutoBuyValues extends yup.InferType<typeof schema> {}

const COUNTRIES_NOT_SUPPORT_REFUND = ['US', 'JP'];

const Balance: FC = () => {
  const { classes } = useStyles();
  const { showSuccess } = useNotify();
  const { t, i18n } = useTranslation();
  const { selectedOrganization } = useAccount();
  const { supportedNetworks } = useSupportedNetworks();

  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [updatedDate, setUpdatedDate] = useState<Moment | null>(null);
  const [openDepositWithdraw, setOpenDepositWithdraw] = useState(false);

  const latestNetwork = Object.values(supportedNetworks)?.[0];

  const [depositable, withdrawable] = useCheckPermissions([BUY_POINTS, REFUND]);

  const [autoBuyPoint] = useAutoBuyPointMutation();
  const [updateOrganization] = useUpdateOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  const showWithdraw = useMemo(() => {
    const country = selectedOrganization.region || 'JP';

    if (!selectedOrganization.region) return false;

    const outsideJapanAndUS = !COUNTRIES_NOT_SUPPORT_REFUND.includes(country);
    const isEnglish = i18n.language === 'en';

    return withdrawable && outsideJapanAndUS && isEnglish;
  }, [withdrawable, i18n.language, selectedOrganization.region]);

  const {
    control,
    reset,
    resetField,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<FormSetupAutoBuyValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      autoBuy: !!selectedOrganization.pointSetting?.enable,
      threshold: selectedOrganization.pointSetting?.threshold?.toString() ?? '',
      maxAmount: selectedOrganization.pointSetting?.maxAmount?.toString() ?? '',
    },
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const autoBuy = useWatch({
    control,
    name: 'autoBuy',
  });

  const handleOpenDepositWithdraw =
    (withdraw: boolean = false) =>
    async () => {
      setIsWithdraw(withdraw);
      setOpenDepositWithdraw(true);
    };

  const handleCloseDepositWithdraw = () => {
    setOpenDepositWithdraw(false);
  };

  const onSubmit = async (data: FormSetupAutoBuyValues) => {
    try {
      const { threshold, maxAmount, autoBuy } = data;

      await updateOrganization({
        variables: {
          input: {
            pointSetting: {
              enable: !!autoBuy,
              chainId: Object.values(supportedNetworks)[0].chainId,
              threshold: threshold ? parseFloat(threshold) : undefined,
              maxAmount: maxAmount ? parseFloat(maxAmount) : undefined,
            },
          },
        },
      });
      reset(data);
      showSuccess('my_shop.message.update_successful');
    } catch {}
  };

  const handleSwitch =
    (onChange: MuiSwitchType): MuiSwitchType =>
    (...args) => {
      resetField('threshold');
      resetField('maxAmount');
      onChange(...args);
    };

  const refetchBalance = useCallback(async () => {
    setIsLoading(true);
    await autoBuyPoint();
    const orgWallet = selectedOrganization?.masterWalletAddress;
    const provider = new JsonRpcProvider(latestNetwork.rpcUrl);
    if (orgWallet) {
      const balanceBigInt = await provider.getBalance(orgWallet);
      const _balance = formatEther(balanceBigInt || BigInt(0));
      setBalance(Number(_balance));
      setUpdatedDate(moment());
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestNetwork, selectedOrganization?.masterWalletAddress]);

  useEffect(() => {
    refetchBalance();
  }, [refetchBalance]);

  return (
    <>
      <CustomCardTable
        cardTitle={t('settings.general.title')}
        headerAction={
          <Box display="flex" alignItems="center">
            <Typography marginRight="8px">
              {t('my_shop.last_published_date')}:{' '}
              {updatedDate ? moment(updatedDate).format(t('date_time_format')) : '...'}
            </Typography>
            <IconButton size="medium" onClick={refetchBalance}>
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          </Box>
        }
        cardContent={
          <Box className={classes.wrapper}>
            <Stack alignItems="center">
              <Typography variant="h6">{t('settings.points.joc_points')}</Typography>
              <Typography variant="h2" fontWeight={500} color={colors.primary}>
                {isLoading ? <CircularProgress color="inherit" /> : balance?.toFixed(4)}
              </Typography>
              <Box className="actions">
                {depositable && (
                  <WalletRequired>
                    <Button variant="contained" onClick={handleOpenDepositWithdraw()}>
                      {t('settings.points.buy_points')}
                    </Button>
                  </WalletRequired>
                )}
                {showWithdraw && withdrawable && (
                  <WalletRequired>
                    <Button variant="outlined" onClick={handleOpenDepositWithdraw(true)}>
                      {t('settings.refund')}
                    </Button>
                  </WalletRequired>
                )}
              </Box>
            </Stack>
            {depositable && (
              <>
                <Controller
                  name="autoBuy"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <FormControlLabel
                      sx={{ width: 'fit-content' }}
                      label={t('settings.points.auto_buy')}
                      disabled={isSubmitting}
                      control={<Checkbox checked={field.value} {...field} onChange={handleSwitch(onChange)} />}
                    />
                  )}
                />
                {autoBuy && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="threshold"
                          control={control}
                          render={({ field }) => (
                            <NumberStringTextField
                              fullWidth
                              margin="dense"
                              variant="outlined"
                              error={!!errors.threshold?.message}
                              label={t('settings.points.threshold')}
                              disabled={isSubmitting}
                              helperText={getErrorText(errors.threshold?.message, t)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          control={control}
                          name="maxAmount"
                          render={({ field }) => (
                            <NumberStringTextField
                              fullWidth
                              margin="dense"
                              variant="outlined"
                              error={!!errors.maxAmount?.message}
                              disabled={isSubmitting}
                              label={t('settings.points.maximum_bill')}
                              helperText={getErrorText(errors.maxAmount?.message, t)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Stack marginTop="8px" alignItems="flex-end">
                  <Button
                    variant="contained"
                    disabled={!isDirty || isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('update')}
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        }
      />
      {typeof balance === 'number' && (
        <DepositDialog
          balance={balance}
          isWithdraw={isWithdraw}
          open={openDepositWithdraw}
          refetchBalance={refetchBalance}
          onClose={handleCloseDepositWithdraw}
        />
      )}
    </>
  );
};

export default Balance;
