import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useLoginMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useSession } from '~/hooks/with-session';
import { oidcUserManager } from '~/oidc';
import { shouldShowSnackbar } from '~/utils/system.util';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
  },
  paper: {
    border: '1px solid #D8D8D8',
    borderRadius: 12,
    boxShadow: 'unset',
    overflow: 'hidden',
    '.firebaseui-card-actions': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.firebaseui-form-links': {
      marginBottom: '10px',
    },
  },
  logo: {
    display: 'flex',
    margin: '40px 40px 0',
    justifyContent: 'center',
    img: {
      width: '100%',
      maxWidth: '350px',
      aspectRatio: '630 / 101',
    },
  },
  content: {
    margin: '40px',
    textAlign: 'center',
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  loginText: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '.title': {
      fontSize: 20,
      fontWeight: 1000,
    },
    '.content': {
      fontSize: 14,
      textAlign: 'start',
    },
  },
}));

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useSession();
  const { showError } = useNotify();
  const { t, i18n } = useTranslation();
  const { classes } = useStyles(undefined, { props: {} });

  const [isLoggingIn, setIsLoggingIn] = useState(Boolean(location.state?.user));

  const hasCalled = useRef(false);

  const [login] = useLoginMutation();

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );

  const handleLoginCallback = useCallback(async () => {
    try {
      const user = await oidcUserManager.getUser();
      if (!user) {
        return;
      }

      await login({ variables: { input: { accessToken: user.access_token } } });
      navigate(AppRouteEnum.Home);
    } catch (error: any) {
      showError(error);
    } finally {
      setIsLoggingIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, login, t]);

  const handleLogin = useCallback(async () => {
    setIsLoggingIn(true);
    try {
      await oidcUserManager.signinRedirect();
    } catch (error: any) {
      if (shouldShowSnackbar(error)) {
        showError(error);
      }
      setIsLoggingIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (location.state?.user && !hasCalled.current) {
      handleLoginCallback();
      hasCalled.current = true;
    }
  }, [handleLoginCallback, location.state]);

  useEffect(() => {
    document.title = t('sign_in');
  }, [t]);

  if (!!account && !isLoggingIn) {
    return <Navigate to={AppRouteEnum.Home} replace={true} />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Box className={classes.logo}>
            <img src="images/token-studio-logo.svg" alt="" />
          </Box>
          <div className={classes.content}>
            <Box className={classes.loginText}>
              <Typography className="title">{t('sign_in_page.connect_by_email.title')}</Typography>
              <Typography className="content">{t('sign_in_page.connect_by_email.content')}</Typography>
            </Box>
            <Button
              size="large"
              variant="contained"
              onClick={handleLogin}
              sx={{ width: '100%' }}
              disabled={isLoggingIn}
              endIcon={isLoggingIn && <CircularProgress size={20} color="inherit" />}
            >
              {t('sign_in')}
            </Button>
          </div>
        </Paper>
        <div className={classes.language}>
          <TextField
            name="language"
            onChange={onLanguageChange}
            value={i18n.language}
            select
            color="primary"
            variant="outlined"
            fullWidth
            hiddenLabel
            size="small"
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </Container>
  );
};

export default SignInPage;

export const languageOptions = [
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'en',
    label: 'English',
  },
];
