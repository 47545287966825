import { useMemo } from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider, TypePolicy, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { createUploadLink } from 'apollo-upload-client';

import { Query as MemberQuery, Mutation as MemberMutation } from './member/types';

import { ContextAPIEnum } from '~/enum/common';
import { env } from '~/env';

const customFieldPolicy: Required<TypePolicy>['fields'][string] = {
  keyArgs: (_, context) => {
    return (context.field?.alias?.value || '') + JSON.stringify(context.variables);
  },
};

const cacheMember = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        getMe: customFieldPolicy,
        getMyShop: customFieldPolicy,
        listCollections: customFieldPolicy,
        listMyShopCollections: customFieldPolicy,
        getMyShopCollectionByCollectionId: customFieldPolicy,
      } as Record<keyof MemberQuery /** & HolderQuery */, Required<TypePolicy>['fields'][string]>,
    },
    Mutation: {
      fields: {
        acceptTermOfUse: customFieldPolicy,
        createCollection: customFieldPolicy,
      } as Record<keyof MemberMutation, Required<TypePolicy>['fields'][string]>,
    },
  },
});

export const WithApolloClient: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const client = useMemo(() => {
    const asyncAuthLink = setContext(async () => {
      const organizationUuid = localStorage.getItem('organizationUuid');
      return {
        headers: {
          ...(organizationUuid ? { organization: organizationUuid } : {}),
        },
      };
    });

    const asyncAuthLinkSubgraph = setContext(async (_, prevContext: any) => {
      return {
        uri: prevContext.subgraphUrl,
      };
    });

    const asyncAuthLinkBlockExplorer = setContext(async (_, prevContext: any) => {
      return {
        uri: prevContext.blockExplorerUrl,
      };
    });

    const memberLink = asyncAuthLink.concat(
      createUploadLink({
        credentials: 'include',
        uri: env.REACT_APP_API_MEMBER_SERVER,
        headers: {
          'Apollo-Require-Preflight': 'true',
        },
      })
    );

    const subgraphLink = asyncAuthLinkSubgraph.concat(
      createUploadLink({
        uri: env.REACT_APP_API_SUBGRAPH_SERVER,
      })
    );

    const blockExplorerRestLink = asyncAuthLinkBlockExplorer.concat(
      new RestLink({
        uri: env.REACT_APP_API_BLOCK_EXPLORER_SERVER,
      })
    );

    return new ApolloClient({
      link: ApolloLink.split(
        (operation) => operation.getContext().blockchain === ContextAPIEnum.Subgraph,
        subgraphLink,
        ApolloLink.split(
          (operation) => operation.getContext().blockchain === ContextAPIEnum.BlockExplorer,
          blockExplorerRestLink,
          memberLink
        )
      ),
      cache: cacheMember,
    });
  }, []);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
