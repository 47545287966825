import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';

import { styled, tooltipClasses, TooltipProps } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import CustomDialog from '~/components/dialog/custom-dialog';
import { INumberStringTextField, NumberStringTextField } from '~/components/NumberStringTextField';
import { limitPrice } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { Currency, useExchangeCurrencyQuery } from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { TableImageListShop } from '~/interfaces/collection';
import { priceWithSymbol } from '~/utils/common';

export type ErrorType = {
  min: number;
  max: number;
} | null;

interface ITextEditWithError extends Omit<INumberStringTextField, 'error'> {
  error: ErrorType;
}

type SavePriceFunc = (newPrice: number, withSell?: boolean) => Promise<void>;

interface EditPriceDialogProps {
  open: boolean;
  rowData?: Partial<TableImageListShop> | undefined;
  onClose: () => void;
  onSavePrice?: SavePriceFunc;
}

export interface PriceData {
  id: string;
  price: number;
  currencyUnit: string;
  collectionImageUuid?: string;
}

export const validateLimitPrice = (price: string, currency: Currency) => {
  const numericPrice = parseFloat(price);

  const min = limitPrice[currency]?.min;
  const max = limitPrice[currency]?.max;

  if (isNaN(numericPrice)) {
    return { min, max };
  }

  if (numericPrice === 0) {
    return null;
  }

  if ((min !== undefined && numericPrice < min) || (max !== undefined && numericPrice > max)) {
    return { min, max };
  }

  return null;
};

const ValidationInput = (params: ITextEditWithError) => {
  const { t } = useTranslation();
  const { error, ...others } = params;
  return (
    <StyledTooltip open={false} title={t('form_validation.limit_price', { min: error?.min, max: error?.max })}>
      <NumberStringTextField {...others} />
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '4px !important',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const EditPriceDialog: FC<EditPriceDialogProps> = (props) => {
  const { open, rowData, onClose, onSavePrice } = props;

  const { t } = useTranslation();
  const { show } = usePaymentMethodRequired();
  const { data: myShop } = useShopDetail();

  const [basePrice, setBasePrice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null);
  const [isSavingAndSelling, setIsSavingAndSelling] = useState(false);

  const debounceValue = useDebounce(basePrice);
  const baseCurrency = myShop?.paymentMethod?.baseCurrency;

  const { data: exchangeCurrencyRes } = useExchangeCurrencyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        from: Currency.Jpy,
        to: Currency.Usd,
      },
    },
  });
  const exchangeCurrency = exchangeCurrencyRes?.exchangeCurrency;

  const estimatedPrice = useMemo(
    () => parseFloat(debounceValue || '0') * (exchangeCurrency || 1),
    [debounceValue, exchangeCurrency]
  );

  useEffect(() => {
    if (myShop && open) {
      setBasePrice(rowData?.price?.toString() || '');
    }
  }, [open, myShop, rowData]);

  const handleSubmitting = (status: boolean, withSell?: boolean) => {
    if (withSell) {
      setIsSavingAndSelling(status);
    } else {
      setIsSaving(status);
    }
  };

  const handleSavePrice = (callback?: SavePriceFunc, withSell?: boolean) => async () => {
    handleSubmitting(true, withSell);
    if (!!basePrice && !errorMessage) {
      const basePriceNum = parseFloat(basePrice || '0');
      if (basePriceNum > 0 && myShop?.publish) {
        const openSuccess = await show({
          description: 'payment_method_required.nft_has_price',
        });
        if (openSuccess) {
          setIsSaving(false);
          handleSubmitting(false, withSell);
          return;
        }
      }
      await callback?.(basePriceNum, withSell);
    } else {
      const error = validateLimitPrice(basePrice.toString(), baseCurrency!);
      setErrorMessage(error);
    }
    handleSubmitting(false, withSell);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const newValue = event.target.value;
    const error = validateLimitPrice(newValue.toString(), baseCurrency!);
    setErrorMessage(error);
    setBasePrice(newValue || '');
  };

  const onCloseDialog = () => {
    onClose();
    setErrorMessage(null);
  };

  return (
    <>
      <CustomDialog
        width="md"
        open={open}
        onClose={onCloseDialog}
        dialogTitle={t('collection_screen.image.price_setting')}
        dialogContent={
          <>
            <ValidationInput
              error={null}
              margin="dense"
              label={baseCurrency}
              value={basePrice.toString()}
              decimal={baseCurrency === Currency.Jpy ? undefined : 2}
              onChange={handleChange}
            />
            {errorMessage && (
              <Typography
                variant="caption"
                color="error"
                sx={{ display: 'inline-block', fontSize: '14px', marginTop: '8px' }}
              >
                {t('form_validation.limit_price', { min: errorMessage?.min, max: errorMessage?.max?.toLocaleString() })}
              </Typography>
            )}
            {!!myShop.siteSetting?.showPriceUsd && typeof exchangeCurrency === 'number' && (
              <Typography variant="body2" marginTop="12px">
                <strong>{t('my_shop.est_usd_price')}</strong>
                {` ≈ ${priceWithSymbol(estimatedPrice, Currency.Usd, 2)}`}
              </Typography>
            )}
          </>
        }
        actions={[
          <Button
            color="primary"
            variant="outlined"
            endIcon={isSavingAndSelling && <CircularProgress size={20} color="inherit" />}
            disabled={rowData?.price?.toString() === basePrice || isSaving || isSavingAndSelling}
            onClick={handleSavePrice(onSavePrice, true)}
          >
            {t('save_and_sell')}
          </Button>,
          <Button
            variant="contained"
            endIcon={isSaving && <CircularProgress size={20} color="inherit" />}
            disabled={rowData?.price?.toString() === basePrice || isSaving || isSavingAndSelling}
            onClick={handleSavePrice(onSavePrice)}
          >
            {t('save')}
          </Button>,
        ]}
      />
    </>
  );
};

export default EditPriceDialog;
