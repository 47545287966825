import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { GridColDef, GridCsvExportOptions, jaJP } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';

import ListTable, { IHandleListTable } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { CHIP_COLOR } from '~/constants/common';
import { useConfirmationDialog } from '~/contexts/ConfirmationDialogProvider';
import { VIEW_MODE } from '~/enum/common';
import { env } from '~/env';
import {
  ListTokensSortBy,
  ListTokensQuery,
  useListTokensLazyQuery,
  Pagination,
  Collection,
  useResendEmailVerifyMemberMutation,
  OrderBy,
} from '~/graphql/member/types';
import { MEMBER_SITE_NFT_STATUS } from '~/types/my-shop';
import { NFTToken } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

export interface NFTStatus {
  [key: string]: MEMBER_SITE_NFT_STATUS;
}

export interface IMemberToken extends NFTToken {
  memberTokenUuid: string;
  status: MEMBER_SITE_NFT_STATUS;
  memberUuid: string | undefined;
}

export interface MemberNFT {
  id: string;
  url: string;
  name: string;
  email: string;
  status: string;
  address: string;
  ownerName: string;
  verifyEmail: boolean;
  activatedAt: Date | string;
  memberUuid: string | undefined;
  [ListTokensSortBy.Owner]: string;
  [ListTokensSortBy.TokenId]: string;
  expireDate: string | number | null;
  [ListTokensSortBy.MintTime]: Date | string;
}

export interface ITokensQuery {
  page: number;
  limit: number;
  orderBy: OrderBy;
  searchText: string;
  sortBy: ListTokensSortBy;
  where: {
    shopUuid: string;
    collectionUuid: string;
    mintTimeEnd: Date | null;
    mintTimeStart: Date | null;
  };
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiTypography-subtitle1': {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '26px',
      color: '#333333',
      marginBottom: '16px',
    },
  },
}));

export const nftStatus = (t: TFunction<'translation', undefined>) => {
  return {
    [MEMBER_SITE_NFT_STATUS.NOT_REGISTERED]: {
      title: t('member_site.not_registered'),
      value: MEMBER_SITE_NFT_STATUS.NOT_REGISTERED,
    },
    [MEMBER_SITE_NFT_STATUS.NOT_CONFIRM]: {
      title: t('not_confirmed'),
      value: MEMBER_SITE_NFT_STATUS.NOT_CONFIRM,
    },
    [MEMBER_SITE_NFT_STATUS.UNRESTRICTED]: {
      title: t('member_site.unrestricted'),
      value: MEMBER_SITE_NFT_STATUS.UNRESTRICTED,
    },
    [MEMBER_SITE_NFT_STATUS.INVALID]: {
      title: t('member_site.invalid'),
      value: MEMBER_SITE_NFT_STATUS.INVALID,
    },
    [MEMBER_SITE_NFT_STATUS.VALID]: {
      title: t('member_site.validity'),
      value: MEMBER_SITE_NFT_STATUS.VALID,
    },
    [MEMBER_SITE_NFT_STATUS.EXPIRED]: {
      title: t('member_site.expired'),
      value: MEMBER_SITE_NFT_STATUS.EXPIRED,
    },
  };
};

export interface IMemberSiteCollectionDetail {
  rows: MemberNFT[];
  isLoading: boolean;
  pagination: Pagination;
  nftsList: IMemberToken[];
  tokensQuery: ITokensQuery;
  collectionInfo: Collection | undefined;
  onOpenNFTDialog: () => void;
  updateTokensQuery: (newValue: IHandleListTable) => void;
  setSelectedItem: Dispatch<SetStateAction<IMemberToken | undefined>>;
  handleOpenMenu: (row: MemberNFT) => MouseEventHandler<HTMLButtonElement>;
  formatRows: (
    data: ListTokensQuery | undefined,
    isOriginalDate?: boolean
  ) => { newRows: MemberNFT[]; newNFTsList: IMemberToken[]; pagination: Pagination };
}

const MemberSiteCollectionDetail: FC<IMemberSiteCollectionDetail> = ({
  rows,
  nftsList,
  isLoading,
  pagination,
  tokensQuery,
  collectionInfo,
  formatRows,
  handleOpenMenu,
  setSelectedItem,
  onOpenNFTDialog,
  updateTokensQuery,
}) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog } = useConfirmationDialog();

  const [getListTokens] = useListTokensLazyQuery();
  const [resendEmail] = useResendEmailVerifyMemberMutation();

  const [exportingList, setExportingList] = useState<{ id: string; fileName: string }[]>([]);

  const getAllTokensList = async () => {
    setExportingList((prevState) =>
      prevState.concat([{ id: uuidv4(), fileName: collectionInfo?.contractAddress || '' }])
    );
    const listTokensRes = await getListTokens({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...tokensQuery,
        limit: undefined,
      },
    });
    const formatted = formatRows(listTokensRes.data, false);
    setExportingList((prevState) => prevState.slice(1));

    return formatted?.newRows || [];
  };

  const csvOptions: GridCsvExportOptions & { getAll: () => Promise<any[]> } = {
    getAll: getAllTokensList,
    fileName: collectionInfo?.contractAddress,
  };

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, t]);

  // Handle NFT Dialog
  const handleOpenNFTDialog = useCallback(
    (nftId: string) => {
      if (nftId) {
        setSelectedItem(nftsList.find((nft) => nft.uuid === nftId));
      }
      onOpenNFTDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nftsList]
  );

  const handleResend = useCallback(
    async (newSelectedItem?: IMemberToken) => {
      try {
        if (newSelectedItem?.memberUuid) {
          await resendEmail({
            variables: {
              uuid: newSelectedItem?.memberUuid,
            },
          });
          enqueueSnackbar(t('toast_message.sent_mail_successfully'), { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
      }
    },
    [t, resendEmail, enqueueSnackbar]
  );

  const handleOpenResendDialog = useCallback(
    (row: MemberNFT) => {
      const newSelectedItem = nftsList.find((nft) => nft.uuid === row.id);
      setSelectedItem(newSelectedItem);
      openDialog(t('member_site.resend_email'), t('member_site.resend_email_confirmation'), {
        colorSubmit: 'primary',
        confirmTitle: t('member_site.resend'),
        onConfirm: () => handleResend(newSelectedItem),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nftsList, t, openDialog]
  );

  const columns: GridColDef<MemberNFT>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        valueFormatter: ({ value }) =>
          value.includes('ipfs') ? env.REACT_APP_IPFS_GATEWAY_URL + encodeURI(value.replace('ipfs://', '')) : value,
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage isNFT src={value} onClick={() => handleOpenNFTDialog(row.id)} />
            </Box>
          );
        },
      },
      {
        field: 'name',
        sortable: false,
        headerName: t('my_shop.nft_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        headerName: t('token_id'),
        field: ListTokensSortBy.TokenId,
        width: JSON.parse(columnsSize).name || 100,
      },
      {
        field: 'status',
        sortable: false,
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 150,
        renderCell: ({ row }) => {
          const status = (row.status as MEMBER_SITE_NFT_STATUS) || MEMBER_SITE_NFT_STATUS.VALID;
          return <Chip label={`${nftStatus(t)[status]?.title || ''}`} color={CHIP_COLOR[status]} />;
        },
      },
      {
        sortable: false,
        field: 'expireDate',
        getApplyQuickFilterFn: undefined,
        headerName: t('member_site.effective_date'),
        width: JSON.parse(columnsSize).createdAt || 150,
        valueFormatter: ({ value }) =>
          !value ? '' : value === 'Infinity' ? t('member_site.infinity') : moment(value).format(t('date_time_format')),
      },
      {
        ellipsis: true,
        sortable: false,
        field: ListTokensSortBy.Owner,
        headerName: t('owner_address'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 150,
        renderCell: ({ formattedValue }) =>
          formattedValue ? (
            <Box display="flex" alignItems="center">
              {truncateEthAddress(formattedValue)}
              <CopyToClipboard text={formattedValue} onCopy={onCopyAddress}>
                <IconButton size="small">
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </CopyToClipboard>
            </Box>
          ) : (
            ''
          ),
      },
      {
        sortable: false,
        field: 'ownerName',
        headerName: t('name.person'),
        width: JSON.parse(columnsSize).ownerName || 150,
      },
      {
        field: 'email',
        sortable: false,
        headerName: t('email_address'),
        width: JSON.parse(columnsSize).email || 150,
      },
      {
        sortable: false,
        field: 'address',
        headerName: t('address'),
        width: JSON.parse(columnsSize).address || 150,
      },
      {
        type: 'date',
        sortable: false,
        field: 'activatedAt',
        headerName: t('member_site.activated_at'),
        width: JSON.parse(columnsSize).activatedAt || 150,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_time_format')) : '-'),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: ListTokensSortBy.MintTime,
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 150,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_time_format')) : '-'),
      },
      {
        width: 150,
        align: 'center',
        sortable: false,
        resizable: false,
        disableExport: true,
        disableReorder: true,
        field: t('member_site.resend_email'),
        headerName: t('member_site.resend_email'),
        renderCell: ({ row }) =>
          !row.verifyEmail && row.email ? (
            <Button color="primary" variant="contained" onClick={() => handleOpenResendDialog(row)}>
              {t('member_site.resend_email')}
            </Button>
          ) : null,
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableExport: true,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => {
          return [
            <IconButton onClick={handleOpenMenu(row)}>
              <MoreVertIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            </IconButton>,
          ];
        },
      },
    ];
  }, [t, handleOpenMenu, onCopyAddress, handleOpenNFTDialog, handleOpenResendDialog]);

  return (
    <Box className={classes.wrapper}>
      <ListTable
        noBorder
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        csvOptions={csvOptions}
        onlyMode={VIEW_MODE.LIST}
        paginationData={pagination}
        search={tokensQuery.searchText}
        tableName="membersite_collection_detail"
        pageSizeOptions={[10, 20, 50, 100]}
        noRowsMessage={t('member_site.no_transaction')}
        searchLabel={`${t('token_id')}, ${t('owner_address')}`}
        isFiltering={!!tokensQuery.where.mintTimeStart || !!tokensQuery.where.mintTimeEnd}
        localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
        sort={{
          sortBy: tokensQuery.sortBy,
          orderBy: tokensQuery.orderBy,
        }}
        onSort={updateTokensQuery}
        onPagination={updateTokensQuery}
        onSearch={(v) => updateTokensQuery({ page: 1, searchText: v || '' })}
      />
      <Snackbar open={exportingList?.length > 0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Box
          sx={{
            gap: '8px',
            display: 'flex',
            borderRadius: '8px',
            padding: '14px 20px',
            flexDirection: 'column',
            backgroundColor: 'white',
            border: '1px solid #D7D7D7',
          }}
        >
          <Typography variant="subtitle2">{t('member_site.export_csv_all')}</Typography>
          {exportingList.map((item) => (
            <Box key={item.id} gap="8px" display="flex" alignItems="center">
              <CircularProgress size={20} />
              <Typography variant="body1" sx={{ flex: 1, wordBreak: 'break-word' }}>
                {item.fileName + '.csv'}
              </Typography>
            </Box>
          ))}
        </Box>
      </Snackbar>
    </Box>
  );
};

export default MemberSiteCollectionDetail;
