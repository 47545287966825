import { FC, memo, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { useIpfsGateways } from '@gu-corp/react-ipfs-media';
import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount as useWalletAccount, WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridColDef } from '@mui/x-data-grid';
import { JsonRpcProvider } from 'ethers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddNFTDialog from '~/components/add-nft-dialog';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import MintedNFTDialog from '~/components/dialog/minted-nft-dialog';
import { GalleryData } from '~/components/gallery-list';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { IHandleListTable } from '~/components/list-table';
import PermissionsView from '~/components/PermissionsView';
import SquareImage from '~/components/SquareImage';
import WrapperWithFab from '~/components/WrapperWithFab';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { VIEW_MODE, ContextAPIEnum } from '~/enum/common';
import { Collection } from '~/graphql/member/types';
import {
  _SubgraphErrorPolicy_,
  OrderDirection,
  Token_OrderBy,
  TokensQuery,
  useTokensLazyQuery,
  useTokensQuery,
} from '~/graphql/subgraph/types';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { CustomAnchorElProps } from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { GRANT_PERMISSION } from '~/types/my-shop';
import {
  convertOrderToAPI,
  convertOrderToSubgraph,
  getLocalStorage,
  setLocalStorageItems,
  verifySortKey,
  verifySubgraphOrderKey,
} from '~/utils/common';
import { getNFTMetadata, NFTToken } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

interface ExistingCollectionDetailProps {
  collectionData: Collection & { status?: GRANT_PERMISSION };
}

interface ITableTokensList extends NFTToken {
  id: string;
  url: string;
  name: string;
  description: string;
  networkSymbol: string;
  [Token_OrderBy.Owner]: string;
  [Token_OrderBy.MintTime]: Date;
  [Token_OrderBy.TokenId]: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  wrapperTable: {
    width: '100%',
    '& .MuiDataGrid-root': {
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
    '.MuiTypography-subtitle1': {
      fontSize: '20px',
      fontWeight: 400,
      letterSpacing: 0.5,
      marginBottom: '12px',
    },
  },
  wrapperContent: {
    '.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const initQuery: IHandleListTable = {
  page: 1,
  searchText: '',
  limit: ITEMS_PER_PAGE.LIST,
  sortBy: verifySortKey(Token_OrderBy, getLocalStorage('nft_list_sort'), Token_OrderBy.MintTime),
  orderBy: verifySubgraphOrderKey(getLocalStorage('nft_list_order')),
};

const ExistingCollectionDetail: FC<ExistingCollectionDetailProps> = ({ collectionData }) => {
  const { supportedNetworks } = useSupportedNetworks();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { collectionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { ipfsGateways } = useIpfsGateways();
  const { selectedOrganization } = useAccount();
  const { account: ethAddress } = useWalletAccount();

  const [rowCount, setRowCount] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [tokensQuery, setTokensQuery] = useState(initQuery);
  const [openAddNFTDialog, setOpenAddNFTDialog] = useState(false);
  const [openDialogInfo, setOpenDialogInfo] = useState<NFTToken>();
  const [openRequiredWallet, setOpenRequiredWallet] = useState(false);
  const [tokensList, setTokensList] = useState<ITableTokensList[]>([]);
  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [nftListMenuAnchorEl, setNftListMenuAnchorEl] = useState<CustomAnchorElProps | null>(null);
  const [customAnchorEl, setCustomAnchorEl] = useState<{
    info: GalleryData;
    anchorEl: null | HTMLElement;
  } | null>(null);

  const isErc721 = !!collectionData?.isErc721;
  const currentNetwork = supportedNetworks[collectionData.network];
  const pagination = { currentPage: tokensQuery.page, itemsPerPage: ITEMS_PER_PAGE.LIST };

  const [getTokens] = useTokensLazyQuery();

  const subgraphVariables = useMemo(() => {
    const _skip =
      Number(tokensQuery.page) && Number(tokensQuery.limit)
        ? ((tokensQuery.page || 1) - 1) * (tokensQuery.limit || 1)
        : undefined;

    return {
      skip: _skip,
      first: tokensQuery.limit,
      subgraphError: _SubgraphErrorPolicy_.Deny,
      orderBy: tokensQuery.sortBy as Token_OrderBy,
      orderDirection: tokensQuery.orderBy as OrderDirection,
      where: {
        contract_: {
          id: collectionData.contractAddress.toLowerCase(),
        },
        tokenID: tokensQuery.searchText?.trim() || undefined,
      },
    };
  }, [collectionData.contractAddress, tokensQuery]);

  const handleTokensData = useCallback(
    async (data: TokensQuery) => {
      setProcessing(true);
      const items = await Promise.all(
        data.tokens.map(async (token) => {
          const metadata = await getNFTMetadata(collectionData.uuid, token, ipfsGateways);
          return {
            ...metadata,
            id: metadata.tokenId,
            tokenId: metadata.tokenId,
            name: metadata.metadataContent.name,
            [Token_OrderBy.Owner]: token.owner.id,
            [Token_OrderBy.TokenId]: token.tokenID,
            networkSymbol: currentNetwork.tokenSymbol || '',
            description: metadata.metadataContent.description,
            [Token_OrderBy.MintTime]: moment.unix(token.mintTime).toDate(),
            url: metadata.metadataContent.image || metadata.metadataContent.animation_url,
          };
        })
      );
      setTokensList(items);

      const _skip =
        Number(tokensQuery.page) && Number(tokensQuery.limit)
          ? (tokensQuery.page || 1) * (tokensQuery.limit || 1)
          : undefined;
      await getTokens({
        fetchPolicy: 'cache-and-network',
        context: {
          blockchain: ContextAPIEnum.Subgraph,
          subgraphUrl: currentNetwork.subgraphUrl,
        },
        variables: {
          skip: _skip,
          first: tokensQuery.limit,
          subgraphError: _SubgraphErrorPolicy_.Deny,
          orderBy: tokensQuery.sortBy as Token_OrderBy,
          orderDirection: tokensQuery.orderBy as OrderDirection,
          where: {
            contract_: {
              id: collectionData.contractAddress.toLowerCase(),
            },
            tokenID: tokensQuery.searchText?.trim() || undefined,
          },
        },
        onCompleted: (nextData) => {
          const hasNextPage = !!nextData?.tokens?.length;
          const currentPage = tokensQuery.page || 1;
          const totalPreviousPages = ITEMS_PER_PAGE.LIST * (currentPage - 1);
          const currentPageLength = data?.tokens.length || 0;
          const estimateNextPage = hasNextPage ? ITEMS_PER_PAGE.LIST : 0;

          const _rowCount = totalPreviousPages + currentPageLength + estimateNextPage;
          setRowCount(_rowCount);
        },
      });
      setProcessing(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokensQuery, collectionData, currentNetwork, ipfsGateways]
  );

  const { loading: loadingTokens, refetch: refetchTokens } = useTokensQuery({
    fetchPolicy: 'cache-and-network',
    context: {
      blockchain: ContextAPIEnum.Subgraph,
      subgraphUrl: currentNetwork.subgraphUrl,
    },
    variables: subgraphVariables,
    onCompleted: handleTokensData,
    onError: () => {
      setTokensList([]);
      setProcessing(false);
    },
  });

  const isLoading = loadingTokens || processing;

  const updateTokensQuery = ({ orderBy, ...newValue }: IHandleListTable) => {
    const orderDirection = !!orderBy ? { orderBy: convertOrderToSubgraph(orderBy) } : {};
    return setTokensQuery((value) => ({
      ...value,
      ...newValue,
      ...orderDirection,
    }));
  };

  useEffect(() => {
    setLocalStorageItems({
      nft_list_sort: tokensQuery?.sortBy,
      nft_list_order: tokensQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensQuery?.orderBy, tokensQuery?.sortBy]);

  const openNFTDialog = useCallback(
    (id: string) => {
      setCustomAnchorEl(null);
      setIsOpenNFTDialog(true);
      setNftListMenuAnchorEl(null);
      setOpenDialogInfo((tokensList || []).find((item: any) => item.id === id));
    },
    [tokensList]
  );

  const columns: GridColDef<ITableTokensList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ row }) => (
          <SquareImage isNFT onClick={() => openNFTDialog(row.id)} src={row.url || '/default/favicon-shop.png'} />
        ),
      },
      {
        field: 'name',
        sortable: false,
        headerName: t('my_shop.nft_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        headerName: t('token_id'),
        field: Token_OrderBy.TokenId,
        width: JSON.parse(columnsSize).tokenId || 150,
      },
      {
        sortable: false,
        field: 'description',
        headerName: t('description'),
        width: JSON.parse(columnsSize).description || 150,
      },
      {
        ellipsis: true,
        sortable: false,
        field: Token_OrderBy.Owner,
        headerName: t('owner_address'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 200,
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {truncateEthAddress(formattedValue)}
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: Token_OrderBy.MintTime,
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        align: 'center',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getApplyQuickFilterFn: undefined,
        getActions: ({ row }) => [
          <IconButton
            onClick={(event) => {
              setNftListMenuAnchorEl({ id: row.id, anchorEl: event.currentTarget });
            }}
          >
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
  }, [t, openNFTDialog]);

  const onCloseNFTDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const onOpenAddNFTDialog = () => {
    setOpenAddNFTDialog(true);
  };

  const onCloseAddNFTDialog = useCallback(
    async (params?: { isCreated?: boolean }) => {
      setOpenAddNFTDialog(false);
      if (collectionData && params?.isCreated) {
        updateTokensQuery({ page: 1, searchText: '' });
        refetchTokens();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionData]
  );

  const handleOpenRequiredWallet = () => {
    setOpenRequiredWallet(true);
  };

  const handleCloseRequiredWallet = () => {
    setOpenRequiredWallet(false);
  };
  const handleClickMenu =
    (info: GalleryData): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      const newAnchorEl = {
        info,
        anchorEl: event.currentTarget,
      };
      setCustomAnchorEl(newAnchorEl);
      setNftListMenuAnchorEl({ id: newAnchorEl.info.id as string, anchorEl: event.currentTarget });
    };

  const handleOpenCreateNFT = async () => {
    if (ethAddress) {
      const provider = new JsonRpcProvider(supportedNetworks?.[collectionData.network]?.rpcUrl);
      const contract = ERC721G__factory.connect(collectionData.contractAddress, provider as any);
      const ownerAddress = await contract.owner();
      const currentWallet = ethAddress.toLowerCase();
      const ownerLowerCase = ownerAddress.toLowerCase();
      const masterWalletLowerCase = selectedOrganization.masterWalletAddress?.toLowerCase();
      if ([currentWallet, masterWalletLowerCase].includes(ownerLowerCase)) {
        onOpenAddNFTDialog();
      } else {
        enqueueSnackbar(t('you_are_not_the_owner_of_this_collection'), { variant: 'error' });
      }
    } else {
      handleOpenRequiredWallet();
    }
  };

  const onCloseNftListMenu = () => {
    setNftListMenuAnchorEl(null);
    setCustomAnchorEl(null);
  };

  const [isCanViewBtnCreate] = useCheckPermissions([SCREEN_PERMISSION.COLLECTIONS.MINTED_NFT.ADD]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.wrapperTable}>
        <WrapperWithFab onClick={handleOpenCreateNFT} hidden={!isCanViewBtnCreate || isErc721}>
          <CustomCardTable
            cardTitle={t('minted_nft_title')}
            headerAction={
              !isErc721 && (
                <PermissionsView roles={SCREEN_PERMISSION.COLLECTIONS.MINTED_NFT.ADD}>
                  <HeaderAction menus={[{ title: t('create_nft'), onClick: handleOpenCreateNFT }]} />
                </PermissionsView>
              )
            }
            cardContent={
              <Box className={classes.wrapperContent}>
                <ListTable
                  noBorder
                  columns={columns}
                  rows={tokensList}
                  rowCount={rowCount}
                  isLoading={isLoading}
                  onlyMode={VIEW_MODE.LIST}
                  searchLabel={t('token_id')}
                  paginationData={pagination}
                  tableName="collection_minted"
                  search={tokensQuery.searchText}
                  noDataProps={
                    !isErc721
                      ? {
                          title: t('create_new_nft'),
                          description: t('my_shop.message.no_nft'),
                          buttonTitle: t('create_nft'),
                          onClick: isCanViewBtnCreate ? handleOpenCreateNFT : undefined,
                        }
                      : undefined
                  }
                  noRowsMessage={t('my_shop.message.no_nft')}
                  sort={{
                    sortBy: tokensQuery.sortBy,
                    orderBy: convertOrderToAPI(tokensQuery.orderBy),
                  }}
                  onSort={updateTokensQuery}
                  onClickMenu={handleClickMenu}
                  onPagination={updateTokensQuery}
                  onSearch={(v) => updateTokensQuery({ page: 1, searchText: v || '' })}
                />
              </Box>
            }
          />
        </WrapperWithFab>
      </Box>
      <MintedNFTDialog
        open={isOpenNFTDialog}
        nftInfo={openDialogInfo!}
        collectionInfo={collectionData!}
        onClose={onCloseNFTDialog}
      />
      <Menu open={!!nftListMenuAnchorEl} anchorEl={nftListMenuAnchorEl?.anchorEl} onClose={onCloseNftListMenu}>
        <MenuItem onClick={() => openNFTDialog(nftListMenuAnchorEl?.id || '')}>{t('my_shop.show_detail')}</MenuItem>
      </Menu>
      <Menu open={!!customAnchorEl} anchorEl={customAnchorEl?.anchorEl} onClose={onCloseNftListMenu}>
        <MenuItem onClick={() => openNFTDialog(nftListMenuAnchorEl?.id || '')}>{t('my_shop.show_detail')}</MenuItem>
      </Menu>
      {!isErc721 && (
        <AddNFTDialog collectionId={collectionId || ''} open={openAddNFTDialog} onClose={onCloseAddNFTDialog} />
      )}
      <WalletConnectorDialog
        open={openRequiredWallet}
        onNext={onOpenAddNFTDialog}
        onClose={handleCloseRequiredWallet}
      />
    </Box>
  );
};

export default memo(ExistingCollectionDetail);
