import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CustomCardTable from '~/components/custom-card-table';
import AddPaymentConfigDialog, { PAYMENT_GATEWAY_INFO } from '~/components/dialog/add-payment-config-dialog';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import CustomDialog from '~/components/dialog/custom-dialog';
import ListDependentShopsDialog, { IDependentShop } from '~/components/dialog/list-dependent-shops-dialog';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable from '~/components/list-table';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { VIEW_MODE } from '~/enum/common';
import {
  ListPaymentConfigsDocument,
  MyShopQueryKey,
  PaymentGateway,
  QueryOperator,
  useListMyShopsLazyQuery,
  useListPaymentConfigsQuery,
  useRemovePaymentConfigMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useCheckPermissions } from '~/hooks/with-account';
import { SHOP_TYPE } from '~/types/my-shop';

export interface IPaymentConfig {
  id: string;
  name: string;
  secretKey: string;
  publicKey: string;
  paymentGateway: string;
}

const hideBodyOfString = (value: string) => `${value.slice(0, 7)}****${value.slice(-4)}`;

const PaymentConfigs = () => {
  const { t } = useTranslation();
  const { showError } = useNotify();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [addable, editable, deletable] = useCheckPermissions([
    SCREEN_PERMISSION.SETTING.SHOP_PAYMENT.ADD,
    SCREEN_PERMISSION.SETTING.SHOP_PAYMENT.EDIT,
    SCREEN_PERMISSION.SETTING.SHOP_PAYMENT.DELETE,
  ]);

  const [selectedItemId, setSelectedItemId] = useState<string>();
  const [openAddConfigDialog, setOpenAddConfigDialog] = useState(false);
  const [listDependentShops, setListDependentShops] = useState<IDependentShop[]>([]);
  const [openListDependentShopsDialog, setOpenListDependentShopsDialog] = useState(false);
  const [openRemoveConfirmationDialog, setOpenRemoveConfirmationDialog] = useState(false);
  const [displayedPaymentConfig, setDisplayedPaymentConfig] = useState<{ publicKey: string; secretKey: string }>();

  const snackbarKey = useRef<SnackbarKey>();

  const [listMyShops] = useListMyShopsLazyQuery();
  const [removePaymentConfig] = useRemovePaymentConfigMutation({ refetchQueries: [ListPaymentConfigsDocument] });

  const { data: listPaymentConfigsRes, loading: isLoading } = useListPaymentConfigsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const listPaymentConfigs: IPaymentConfig[] = useMemo(
    () =>
      listPaymentConfigsRes?.listPaymentConfigs?.map(({ uuid, name, secretKey, publicKey, paymentGateway }) => ({
        id: uuid,
        name,
        secretKey,
        publicKey,
        paymentGateway,
      })) || [],
    [listPaymentConfigsRes?.listPaymentConfigs]
  );

  const closeProcessingSnackbar = () => {
    if (!!snackbarKey.current) {
      closeSnackbar(snackbarKey.current);
    }
  };

  const selectItem = (id: string) => {
    setSelectedItemId(id);
  };

  const unselectItem = () => {
    setSelectedItemId(undefined);
  };

  const handleOpenAddConfigDialog = () => {
    setOpenAddConfigDialog(true);
  };

  const handleCloseAddConfigDialog = () => {
    setOpenAddConfigDialog(false);
  };

  const handleOpenListDependentShopsDialog = () => {
    setOpenListDependentShopsDialog(true);
  };

  const handleCloseListDependentShopsDialog = () => {
    setOpenListDependentShopsDialog(false);
  };

  const handleOpenRemoveConfirmationDialog = () => {
    setOpenRemoveConfirmationDialog(true);
  };

  const handleCloseRemoveConfirmationDialog = () => {
    unselectItem();
    setOpenRemoveConfirmationDialog(false);
  };

  const confirmRemove = async () => {
    try {
      if (!!selectedItemId) {
        await removePaymentConfig({
          variables: {
            uuid: selectedItemId,
          },
        });
      }
    } catch (err) {
      showError(err);
    }
  };

  const openShowKeysDialog =
    ({ secretKey, publicKey }: IPaymentConfig) =>
    () => {
      setDisplayedPaymentConfig({
        secretKey,
        publicKey,
      });
    };

  const closeShowKeysDialog = () => {
    setDisplayedPaymentConfig(undefined);
  };

  const handleRemovePaymentConfig = useCallback(
    (rowId: string) => async () => {
      try {
        closeProcessingSnackbar();
        const _snackbarKey = enqueueSnackbar(`${t('processing')}...`, {
          action: <></>,
          persist: true,
          variant: 'info',
          preventDuplicate: true,
        });
        snackbarKey.current = _snackbarKey;

        const listMyShopsRes = await listMyShops({
          fetchPolicy: 'cache-and-network',
          variables: {
            where: {
              fields: [
                { key: MyShopQueryKey.ShopType, operator: QueryOperator.Equals, value: [SHOP_TYPE.SHOP] },
                { key: MyShopQueryKey.PaymentConfigUuid, operator: QueryOperator.Equals, value: [rowId] },
              ],
            },
          },
        });
        const listMyShopsData = listMyShopsRes.data?.listMyShops.items || [];
        const myShopsLength = listMyShopsData.length;
        if (myShopsLength > 0) {
          setListDependentShops(
            listMyShopsData.map((myShop) => ({
              uuid: myShop.uuid,
              domain: myShop.domain?.name || '',
              name: myShop.siteSetting?.title || '',
            }))
          );
          handleOpenListDependentShopsDialog();
        } else {
          selectItem(rowId);
          handleOpenRemoveConfirmationDialog();
        }
        closeProcessingSnackbar();
      } catch (err) {
        closeProcessingSnackbar();
        showError(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const columns: GridColDef<IPaymentConfig>[] = useMemo(
    () => [
      {
        width: 150,
        field: 'paymentGateway',
        headerName: t('settings.shop_payment.payment_gateway'),
        renderCell: ({ value }) => (
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            {PAYMENT_GATEWAY_INFO[value as PaymentGateway].icons}
            <Typography variant="body1">{PAYMENT_GATEWAY_INFO[value as PaymentGateway].title}</Typography>
          </Stack>
        ),
      },
      {
        width: 300,
        field: 'name',
        sortable: false,
        headerName: t('name.thing'),
      },
      {
        width: 300,
        sortable: false,
        field: 'publicKey',
        headerName: t('settings.shop_payment.public_key'),
        valueFormatter: ({ value }) => hideBodyOfString(value),
      },
      {
        width: 300,
        sortable: false,
        field: 'secretKey',
        headerName: t('settings.shop_payment.secret_key'),
        valueFormatter: ({ value }) => hideBodyOfString(value),
      },
      {
        width: 100,
        headerName: '',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        renderCell: ({ row }) =>
          editable && (
            <Stack direction="row" gap={2}>
              <IconButton size="small" onClick={openShowKeysDialog(row)}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
              {deletable && (
                <IconButton size="small" color="error" onClick={handleRemovePaymentConfig(row.id)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              )}
            </Stack>
          ),
      },
    ],
    [editable, deletable, t, handleRemovePaymentConfig]
  );

  useEffect(() => {
    return () => {
      closeProcessingSnackbar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomCardTable
      cardTitle={t('settings.shop_payment.payment_configs')}
      cardContent={
        <Box paddingBottom="16px">
          <ListTable
            noBorder
            columns={columns}
            sortingMode="client"
            sortModel={undefined}
            isLoading={isLoading}
            pagination={undefined}
            rows={listPaymentConfigs}
            onlyMode={VIEW_MODE.LIST}
            tableName="payment_configs"
            slots={{
              toolbar: undefined,
            }}
            onSortModelChange={undefined}
          />
          <CustomDialog
            width="md"
            open={!!displayedPaymentConfig}
            dialogTitle={t('settings.shop_payment.payment_keys')}
            dialogContent={
              <Stack gap={1}>
                <Stack flexDirection="row" gap={2} alignItems="flex-start" width="100%">
                  <Typography variant="subtitle1" fontWeight={700} width="100px">
                    {t('settings.shop_payment.public_key')}
                  </Typography>
                  <Stack flex={1} flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                      {displayedPaymentConfig?.publicKey || ''}
                    </Typography>
                    <IconBtnCopy text={displayedPaymentConfig?.publicKey || ''} />
                  </Stack>
                </Stack>
                <Stack flexDirection="row" gap={2} alignItems="flex-start" width="100%">
                  <Typography variant="subtitle1" fontWeight={700} width="100px">
                    {t('settings.shop_payment.secret_key')}
                  </Typography>
                  <Stack flex={1} flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                      {displayedPaymentConfig?.secretKey || ''}
                    </Typography>
                    <IconBtnCopy text={displayedPaymentConfig?.secretKey || ''} />
                  </Stack>
                </Stack>
              </Stack>
            }
            onClose={closeShowKeysDialog}
          />
          {addable && (
            <Stack alignItems="flex-end">
              <Button variant="contained" onClick={handleOpenAddConfigDialog}>
                {t('settings.shop_payment.add_payment_config')}
              </Button>
            </Stack>
          )}
          <ConfirmationDialog
            confirmTitle={t('remove')}
            open={openRemoveConfirmationDialog}
            title={t('settings.shop_payment.remove_payment_config')}
            content={t('settings.shop_payment.remove_payment_config_desc')}
            onConfirm={confirmRemove}
            onClose={handleCloseRemoveConfirmationDialog}
          />
          <ListDependentShopsDialog
            open={openListDependentShopsDialog}
            dependentShops={listDependentShops}
            onClose={handleCloseListDependentShopsDialog}
          />
          <AddPaymentConfigDialog open={openAddConfigDialog} onClose={handleCloseAddConfigDialog} />
        </Box>
      }
    />
  );
};

export default PaymentConfigs;
