import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import InsufficientLicenseDialog from '~/components/dialog/insufficient-license-dialog';
import LicenseStatementDialog from '~/components/dialog/license-statement-dialog';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { ContextAPIEnum, VIEW_MODE } from '~/enum/common';
import {
  LicenseType,
  ListMembersDocument,
  ListMembersQuery,
  MemberQueryKey,
  MyShopCollectionQueryKey,
  QueryOperator,
  ToggleStatus,
  useBuyLicenseMutation,
  useGetInfoUsageLazyQuery,
  useListMembersQuery,
  useListMyShopCollectionsQuery,
  useUpdateMemberMutation,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_, useOwnerPerTokenContractsLazyQuery } from '~/graphql/subgraph/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { SHOP_TYPE } from '~/types/my-shop';
import {
  generateRoute,
  getAllSubgraphData,
  getLocalStorage,
  setLocalStorageItems,
  verifyOrderKey,
  verifySortKey,
} from '~/utils/common';
import { truncateEthAddress } from '~/utils/string.utils';

export interface DisplayedMembersList {
  id: string;
  address?: string;
  numberOfNFTs: number;
  verifyEmail?: boolean;
  [MemberQueryKey.Email]: string;
  [MemberQueryKey.Status]: string;
  [MemberQueryKey.Wallet]: string;
  [MemberQueryKey.OwnerUid]: string;
  [MemberQueryKey.LastName]?: string;
  [MemberQueryKey.FirstName]?: string;
  [MemberQueryKey.CreatedAt]: string;
}

interface IMembersPagination {
  items: DisplayedMembersList[];
  pagination: ListTablePagination;
}

const getInitQuery = (shopId: string) => {
  return {
    page: 1,
    searchText: '',
    limit: ITEMS_PER_PAGE.LIST,
    orderBy: verifyOrderKey(getLocalStorage('member_management_order')),
    sortBy: verifySortKey(MemberQueryKey, getLocalStorage('member_management_sort')),
    where: {
      fields: [
        {
          value: [shopId ?? ''],
          operator: QueryOperator.Contains,
          key: MemberQueryKey.ShopUuid,
        },
      ],
    },
  };
};

const STATUS_INFO: Record<ToggleStatus, { label: string; color: 'success' | 'error' }> = {
  [ToggleStatus.Enable]: {
    label: 'Enable',
    color: 'success',
  },
  [ToggleStatus.Disable]: {
    label: 'Disable',
    color: 'error',
  },
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    height: '100%',
    'a:-webkit-any-link': {
      color: 'inherit',
    },
    '.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

const MemberManagement: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id: memberSiteId } = useParams();
  const { show } = usePaymentMethodRequired();
  const supportedNetworks = useSupportedNetworks();
  const { plan: currentPlan, isLocked } = useAccount();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const [isLicenseWarningOpen, setIsLicenseWarningOpen] = useState(false);
  const [processingMembersData, setProcessingMembersData] = useState(true);
  const [isLicenseStatementOpen, setIsLicenseStatementOpen] = useState(false);
  const [membersPagination, setMembersPagination] = useState<IMembersPagination>({
    items: [],
    pagination: {},
  });
  const [membersQuery, setMembersQuery] = useState(getInitQuery(memberSiteId || ''));

  const snackbarKey = useRef<SnackbarKey>();
  const selectedItem = useRef<{ row: DisplayedMembersList; newStatus: ToggleStatus }>();

  const latestNetwork = Object.values(supportedNetworks || {})?.[0];

  const licenseInfo = {
    quantity: 1,
    type: LicenseType.AdditionalMemberFee,
  };

  const updateMembersQuery = (newValue: any) => {
    setMembersQuery((value) => {
      return { ...value, ...newValue };
    });
  };

  const [buyLicense] = useBuyLicenseMutation();
  const [getOwnersPerTokenContracts] = useOwnerPerTokenContractsLazyQuery();
  const [getInfoUsage] = useGetInfoUsageLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateMember] = useUpdateMemberMutation({
    refetchQueries: [ListMembersDocument],
  });
  const { data: listMyShopCollectionsRes, loading: loadingListMyShopCollections } = useListMyShopCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        shopType: SHOP_TYPE.MEMBER,
        fields: [
          {
            value: [memberSiteId || ''],
            operator: QueryOperator.Equals,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });
  const listMyShopCollection = listMyShopCollectionsRes?.listMyShopCollections.items || [];

  const handleMembersData = useCallback(
    async (data: ListMembersQuery) => {
      try {
        setProcessingMembersData(true);
        const walletsSet = new Set<string>();
        data.listMembers.items.forEach((member) => walletsSet.add(member.wallet?.toLowerCase() || ''));

        const contractsSet = new Set<string>();
        listMyShopCollection.forEach((myShopCollection) =>
          contractsSet.add(myShopCollection.collection?.contractAddress.toLowerCase() || '')
        );

        const ownersPerTokenContracts = await getAllSubgraphData(getOwnersPerTokenContracts, {
          fetchPolicy: 'cache-and-network',
          context: {
            blockchain: ContextAPIEnum.Subgraph,
            subgraphUrl: latestNetwork.subgraphUrl,
          },
          variables: {
            where: {
              owner_in: Array.from(walletsSet),
              contract_in: Array.from(contractsSet),
            },
            subgraphError: _SubgraphErrorPolicy_.Deny,
          },
        });

        const numberOfNFTsEachWallet =
          ownersPerTokenContracts?.reduce((result, item) => {
            const numTokens = Number(item.numTokens) || 0;
            if (!result[item.owner.id]) {
              result[item.owner.id] = numTokens;
            } else {
              result[item.owner.id] += numTokens;
            }
            return result;
          }, {} as Record<string, number>) || {};

        const _items = data.listMembers.items.map((member) => {
          const lowerCaseWallet = member.wallet?.toLowerCase() || '';
          const numberOfNFTs = numberOfNFTsEachWallet[lowerCaseWallet] || 0;
          return {
            numberOfNFTs,
            id: member.uuid,
            verifyEmail: member.verifyEmail,
            [MemberQueryKey.Email]: member.email,
            [MemberQueryKey.Status]: member.status,
            [MemberQueryKey.Wallet]: member.wallet,
            [MemberQueryKey.OwnerUid]: member.ownerUid,
            [MemberQueryKey.LastName]: member.lastName,
            [MemberQueryKey.CreatedAt]: member.createdAt,
            [MemberQueryKey.FirstName]: member.firstName,
            address: ((member.address?.postalCode || '') + ' ' + (member.address?.address || '')).trim(),
          } as DisplayedMembersList;
        });

        setMembersPagination({ items: _items, pagination: data.listMembers.pagination || {} });
      } catch (err) {
        showError(err);
      }
      setProcessingMembersData(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listMyShopCollection, latestNetwork.subgraphUrl]
  );

  const { loading: loadingMembersList } = useListMembersQuery({
    variables: membersQuery,
    fetchPolicy: 'cache-and-network',
    skip: loadingListMyShopCollections,
    onError(error) {
      showError(error.message);
    },
    onCompleted: handleMembersData,
  });

  const openLicenseWarningDialog = () => {
    setIsLicenseWarningOpen(true);
  };
  const closeLicenseWarningDialog = () => {
    setIsLicenseWarningOpen(false);
  };

  const openLicenseStatementDialog = () => {
    closeLicenseWarningDialog();
    setIsLicenseStatementOpen(true);
  };
  const closeLicenseStatementDialog = () => {
    setIsLicenseStatementOpen(false);
  };

  const closeProcessingSnackbar = () => {
    if (!!snackbarKey.current) {
      closeSnackbar(snackbarKey.current);
    }
  };

  const validateAvailability = async () => {
    if (!selectedItem.current) {
      return;
    }

    try {
      const infoUsageRes = await getInfoUsage();
      const infoUsage = infoUsageRes.data?.getInfoUsage;
      if (!!infoUsageRes.error || !infoUsage) {
        closeProcessingSnackbar();
        showError(infoUsageRes.error);
        return;
      }

      const notEnoughFreeLicenses = infoUsage.memberPerSiteLicenses >= (currentPlan?.numberOfMemberPerSite || 0);
      if (notEnoughFreeLicenses) {
        const openSuccess = await show({
          title: 'license_limit_reached',
          description: 'members_per_member_site_reached_limit_miss_payment',
        });
        closeProcessingSnackbar();
        if (openSuccess) {
          return;
        }
        openLicenseWarningDialog();
      } else {
        await buyLicense({
          variables: {
            input: licenseInfo,
          },
        });
        await updateStatus(selectedItem.current.row, selectedItem.current.newStatus);
      }
    } catch (err) {
      closeProcessingSnackbar();
      showError(err);
    }
  };

  const updateStatus = async (row: DisplayedMembersList, newStatus: ToggleStatus) => {
    try {
      closeProcessingSnackbar();
      const _snackbarKey = enqueueSnackbar(`${t('processing')}...`, {
        action: <></>,
        persist: true,
        variant: 'info',
        preventDuplicate: true,
      });
      snackbarKey.current = _snackbarKey;

      if (isLocked) {
        showErrorByKey('toast_message.organization_locked');
        return;
      }

      selectedItem.current = {
        row,
        newStatus,
      };

      await updateMember({
        variables: {
          input: {
            uuid: row.id,
            status: newStatus,
          },
        },
      });

      selectedItem.current = undefined;
      closeProcessingSnackbar();
      showSuccess('toast_message.updated_successfully');
    } catch (error: any) {
      if (error?.graphQLErrors?.[0]?.extensions?.originalError?.error === 'MaximumMember') {
        await validateAvailability();
      } else {
        closeProcessingSnackbar();
        showError(error);
      }
    }
  };

  const handleSelect = (row: DisplayedMembersList) => async (event: SelectChangeEvent<ToggleStatus>) => {
    const value = event.target.value as ToggleStatus;
    await updateStatus(row, value);
  };

  const handleBuyLicenseSuccess = async () => {
    if (!selectedItem.current) {
      return;
    }
    await updateStatus(selectedItem.current.row, selectedItem.current.newStatus);
  };

  const columns: GridColDef<DisplayedMembersList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 150,
        resizable: false,
        field: MemberQueryKey.Wallet,
        headerName: t('member_site.wallet_address'),
        renderCell: ({ value, row }) =>
          value ? (
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
              <Link
                to={generateRoute(AppRouteEnum.MemberSiteMemberDetail, { id: memberSiteId || '', memberId: row.id })}
              >
                {truncateEthAddress(value)}
              </Link>
              <IconBtnCopy text={value} />
            </Box>
          ) : (
            ''
          ),
      },
      {
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 120,
        field: MemberQueryKey.Status,
        renderCell: ({ row }) => {
          const status: ToggleStatus = (row[MemberQueryKey.Status] as ToggleStatus) || ToggleStatus.Disable;
          return (
            <Select variant="standard" disableUnderline fullWidth value={status} onChange={handleSelect(row)}>
              {Object.values(ToggleStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Chip label={STATUS_INFO[status].label} color={STATUS_INFO[status].color} />
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        sortable: false,
        field: 'numberOfNFTs',
        headerName: t('member_site.number_of_nfts'),
        width: JSON.parse(columnsSize).numberOfNFTs || 150,
      },
      {
        field: MemberQueryKey.Email,
        headerName: t('email_address'),
        width: JSON.parse(columnsSize).emailAddress || 200,
      },
      {
        field: MemberQueryKey.FirstName,
        headerName: t('my_shop.operation.first_name'),
        width: JSON.parse(columnsSize).firstName || 150,
      },
      {
        field: MemberQueryKey.LastName,
        headerName: t('my_shop.operation.last_name'),
        width: JSON.parse(columnsSize).lastName || 150,
      },
      {
        field: 'address',
        headerName: t('address'),
        width: JSON.parse(columnsSize).address || 150,
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: MemberQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      member_management_order: membersQuery?.orderBy,
      member_management_sort: membersQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersQuery?.orderBy, membersQuery?.sortBy]);

  useEffect(() => {
    return () => {
      closeProcessingSnackbar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loadingMembersList || processingMembersData;

  return (
    <Box className={classes.wrapper}>
      <CustomCardTable
        cardTitle={t('member_site.member_management')}
        cardContent={
          <ListTable
            isMenu
            noBorder
            columns={columns}
            isLoading={isLoading}
            onlyMode={VIEW_MODE.LIST}
            rows={membersPagination?.items}
            search={membersQuery.searchText}
            tableName="member_management_tabs"
            searchLabel={t('member_site.wallet_address')}
            paginationData={membersPagination.pagination}
            sort={{
              sortBy: membersQuery.sortBy,
              orderBy: membersQuery.orderBy,
            }}
            onSort={updateMembersQuery}
            onPagination={updateMembersQuery}
            onSearch={(v) => updateMembersQuery({ page: 1, searchText: v || '' })}
          />
        }
      />
      <InsufficientLicenseDialog
        open={isLicenseWarningOpen}
        type={LicenseType.AdditionalMemberFee}
        onClose={closeLicenseWarningDialog}
        onSubmit={openLicenseStatementDialog}
      />
      <LicenseStatementDialog
        license={licenseInfo}
        open={isLicenseStatementOpen}
        onNext={handleBuyLicenseSuccess}
        onClose={closeLicenseStatementDialog}
      />
    </Box>
  );
};

export default memo(MemberManagement);
