import { Stack } from '@mui/material';

import PaymentConfigs from './PaymenConfigs';
import PaymentForm from './PaymentForm';

const ShopPayment = () => {
  return (
    <Stack gap="24px">
      <PaymentConfigs />
      <PaymentForm />
    </Stack>
  );
};

export default ShopPayment;
