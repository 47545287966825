import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import PaymentMethodRequired from './PaymentMethodRequired';
import SupportedNetworksProvider from './SupportedNetworksProvider';

import UserLayout from '~/components/app-layout/user-layout';
import WrapperPage from '~/components/WrapperPage';
import { WithAccount } from '~/hooks/with-account';

const PrivateWrapper: FC = () => (
  <WrapperPage>
    <WithAccount>
      <SupportedNetworksProvider>
        <PaymentMethodRequired>
          <UserLayout>
            <Outlet />
          </UserLayout>
        </PaymentMethodRequired>
      </SupportedNetworksProvider>
    </WithAccount>
  </WrapperPage>
);

export default PrivateWrapper;
