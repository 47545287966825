import { LazyQueryExecFunction, LazyQueryHookOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { TFunction } from 'i18next';

import { CURRENCY_ICONS } from '~/constants/common';
import { OrderByGrid } from '~/constants/tableKeys';
import { InvoiceType } from '~/enum/common';
import { Currency, OrderBy } from '~/graphql/member/types';
import { OrderDirection } from '~/graphql/subgraph/types';
import { ArrayElement } from '~/types/my-shop';

interface SortConfig {
  key: string;
  order?: 'asc' | 'desc' | null;
}

type ExtractParams<T extends string> = T extends `${string}:${infer Param}/${infer Rest}`
  ? Param | ExtractParams<`/${Rest}`>
  : T extends `${string}:${infer Param}`
  ? Param
  : never;

type Params<T extends string> = {
  [K in ExtractParams<T>]: string;
};

interface localStorageItems {}

export const removeParentPath = (parentPath: string, path: string) => {
  const param = /\/$/.test(parentPath) ? parentPath : `${parentPath}/`;
  const regex = new RegExp(param, 'g');
  return path.replace(regex, '');
};

export const setLocalStorageItems = (items: localStorageItems) => {
  for (const [key, value] of Object.entries(items)) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }
};
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const verifySortKey = (keys: any, value: any, defaultValue?: any) => {
  return Object.values(keys).includes(value) ? value : defaultValue || keys.CreatedAt;
};

export const verifyOrderKey = (orderKey?: any) => {
  return orderKey === OrderBy.Asc ? OrderBy.Asc : OrderBy.Desc;
};

export const verifySubgraphOrderKey = (orderKey?: any) => {
  return orderKey === OrderDirection.Asc ? OrderDirection.Asc : OrderDirection.Desc;
};

export const convertOrderToAPI = (value?: string) => {
  return !!value ? (value === OrderDirection.Asc ? OrderBy.Asc : OrderBy.Desc) : undefined;
};

export const convertOrderToSubgraph = (value?: string) => {
  return !!value ? (value === OrderBy.Asc ? OrderDirection.Asc : OrderDirection.Desc) : undefined;
};

export const fetchWithTimeout = async (url: string, timeout: number = 8000) => {
  const options: { timeout: number; signal?: AbortSignal } = { timeout };
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  options.signal = controller.signal;

  try {
    const response = await fetch(url, options);
    clearTimeout(id);
    return response;
  } catch (error: any) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out');
    }
    throw error;
  }
};

export const generateQueryString = (obj: Record<string, any>) => {
  return `?${Object.keys(obj)
    .reduce((result, key) => {
      if (![undefined, null].includes(obj[key])) {
        result.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
      }
      return result;
    }, [] as string[])
    .join('&')}`;
};

export const generateRoute = <T extends string>(template: T, params: Params<T>): string => {
  return template.replace(/:([a-zA-Z0-9_-]+)/g, (_, key: string) => {
    if (!(key in params)) {
      throw new Error(`Missing value for parameter: ${key}`);
    }
    return params[key as keyof Params<T>] as string; // Cast key to keyof Params<T>
  });
};

export const currencySymbol = (currency: Currency) =>
  CURRENCY_ICONS[currency.toUpperCase()] || CURRENCY_ICONS[Currency.Usd];

export const formatNumberWithCommas = (value: string) => {
  if (!value) return '';
  const isIncludeDecimal = value.includes('.');
  const [integer, decimal] = value.toString().split('.');
  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (isIncludeDecimal ? '.' + decimal : '');
};

export const priceWithSymbol = (price: number | undefined | null, currency: Currency, fixed?: number) => {
  const currencyUpperCase = currency.toUpperCase() as Currency;
  const symbol = currencySymbol(currency);
  if (typeof price !== 'number') {
    return `${symbol}0`;
  }
  const isNegative = price < 0;
  const absPrice = Math.abs(price);
  const amount =
    currencyUpperCase === Currency.Jpy
      ? absPrice.toFixed(0)
      : typeof fixed === 'number'
      ? absPrice.toFixed(Math.abs(fixed))
      : absPrice.toString();
  return (isNegative ? '-' : '') + `${symbol}${formatNumberWithCommas(amount)}`;
};

export const replaceLicenseName = (inputString: string, t: TFunction): string => {
  const keyMap: { [key: string]: string } = {
    additionalShopFee: t('license.shop', { lng: 'en' }),
    additionalUserFee: t('license.user', { lng: 'en' }),
    additionalSiteFee: t('license.member_site', { lng: 'en' }),
    [InvoiceType.PurchaseShopLicense]: t('license.shop', { lng: 'en' }),
    [InvoiceType.PurchaseUserLicense]: t('license.user', { lng: 'en' }),
    additionalMemberFee: t('license.member_per_member_site', { lng: 'en' }),
    [InvoiceType.PurchaseMemberSiteLicense]: t('license.member_site', { lng: 'en' }),
    [InvoiceType.PurchaseMemberPerSiteLicense]: t('license.member_per_member_site', { lng: 'en' }),
  };

  const regex = new RegExp(Object.keys(keyMap).join('|'), 'g');

  return inputString.replace(regex, (matched) => keyMap[matched]);
};

interface GroupedPayload<T> {
  group: T[];
  totalSize: number;
}

export const getSize = (item: Record<string, any>): number => {
  return new TextEncoder().encode(JSON.stringify(item)).length;
};

export const groupRecords = <T = any>(
  recordsWithSizes: { item: T; size: number }[],
  maxPayloadSize: number
): GroupedPayload<T>[] => {
  recordsWithSizes.sort((a, b) => b.size - a.size);

  const groups: GroupedPayload<T>[] = [];

  for (const { item, size } of recordsWithSizes) {
    let added = false;

    for (const group of groups) {
      if (group.totalSize + size <= maxPayloadSize) {
        group.group.push(item);
        group.totalSize += size;
        added = true;
        break;
      }
    }

    if (!added) {
      groups.push({
        group: [item],
        totalSize: size,
      });
    }
  }

  return groups;
};

export const redirectToNewTab = (url: string) => {
  const newTab = window.open(url, '_blank');

  if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
    window.location.href = url;
  }
};

export const getAllSubgraphData = async <TData, TVariables>(
  callback: LazyQueryExecFunction<TData, TVariables>,
  params: Partial<LazyQueryHookOptions<TData, Omit<TVariables, 'first' | 'skip'>>>,
  pageSize: number = 100
) => {
  type ArrayKey = keyof TData;

  let allData: ArrayElement<TData[ArrayKey]>[] = [];
  let page = 0;

  while (true) {
    const variables = !!params?.variables
      ? {
          ...params.variables,
          first: pageSize,
          skip: page * pageSize,
        }
      : undefined;

    const newParams = {
      ...params,
      variables,
    } as Partial<LazyQueryHookOptions<TData, TVariables>>;

    const { data } = await callback(newParams);
    if (!data) break;

    const key = Object.keys(data)[0] as ArrayKey;
    const pageData = data[key] as ArrayElement<TData[ArrayKey]>[];
    allData = allData.concat(pageData);

    if (pageData.length < pageSize) break;
    page++;
  }

  return allData;
};
