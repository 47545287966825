import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useDisconnect } from '@gusdk/gu-wallet-connector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useConfirmationDialog } from '~/contexts/ConfirmationDialogProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useLogoutMutation } from '~/graphql/member/types';
import { oidcUserManager } from '~/oidc';

export const useLogout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { disconnect } = useDisconnect();
  const { openDialog } = useConfirmationDialog();

  const [logout] = useLogoutMutation();

  const onLogout = useCallback(
    async (options: { disableConfirmDialog?: boolean; logoutOidc?: boolean } = {}) => {
      let shouldLogoutGUAccount = false;
      const { disableConfirmDialog = false, logoutOidc = true } = options;
      if (!disableConfirmDialog) {
        let shouldLogoutUser = false;

        await openDialog(t('logout'), t('logout_confirm'), {
          colorSubmit: 'primary',
          confirmTitle: t('confirm'),
          onConfirm: async () => {
            shouldLogoutGUAccount = true;
            shouldLogoutUser = true;
          },
          onCancel: async () => {
            shouldLogoutGUAccount = false;
            shouldLogoutUser = true;
          },
        });

        if (!shouldLogoutUser) {
          return;
        }
      }
      await logout();
      if (logoutOidc) {
        await oidcUserManager.signoutRedirect({
          extraQueryParams: {
            shouldLogoutGUAccount,
          },
        });
      }
      await client.clearStore();
      await disconnect();
      navigate(AppRouteEnum.SignIn);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, t, logout, openDialog]
  );

  return onLogout;
};
