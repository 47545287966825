import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

import { AppRouteEnum } from './enum/AppRouteEnum';
import { env } from './env';

export const oidcUserManager = new UserManager({
  client_id: env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + AppRouteEnum.OidcCallback,
  response_type: 'code',
  scope: 'openid profile email',
  authority: env.REACT_APP_OIDC_ISSUER_URL,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  post_logout_redirect_uri: window.location.origin + AppRouteEnum.SignIn,
  automaticSilentRenew: true,
  monitorSession: true,
  prompt: 'login',
});
