type EnvType = {
  REACT_APP_FIREBASE_CLIENT_CREDENTIALS: string;
  REACT_APP_TERMS_OF_USE_JA: string;
  REACT_APP_TERMS_OF_USE_EN: string;
  REACT_APP_PROJECT_ID_WALLET_CONNECT: string;
  REACT_APP_SUPPORTED_NETWORK: string;
  REACT_APP_CLIENT_URL: string;
  REACT_APP_API_MEMBER_SERVER: string;
  REACT_APP_API_CLIENT_SERVER: string;
  REACT_APP_API_SUBGRAPH_SERVER: string;
  REACT_APP_API_BLOCK_EXPLORER_SERVER: string;
  REACT_APP_API_MEDIA: string;
  REACT_APP_MASTER_WALLET_ADDRESS: string;
  REACT_APP_IPFS_GATEWAY_URL: string;
  REACT_APP_USER_MANUAL_URL: string;
  REACT_APP_OIDC_CLIENT_ID: string;
  REACT_APP_OIDC_ISSUER_URL: string;
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? (window as any)['env'] : process.env;
