import { FC, SetStateAction, memo, useEffect, useState, SyntheticEvent } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import SalesCollection from './components/SalesCollection';
import AdvancedSetting from './components/shop-detail-tabs/AdvancedSetting';
import BasicSiteSetting from './components/shop-detail-tabs/BasicSiteSetting';
import LegalInformation from './components/shop-detail-tabs/LegalInformation';

import HomeBtn from '~/components/home-btn';
import LinkButton from '~/components/link-button';
import LoaderCenter from '~/components/loader-center';
import { useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { AcquisitionInformation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { ExpireDateMethod, NFT_SHOP_TYPE, SHOP_TYPE, ShopDetailTab, Unit } from '~/types/my-shop';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: '8px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
      margin: '30px 0 20px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 400,
      marginRight: 40,
    },
  },
  ogpBox: {
    height: 212,
    padding: 16,
    display: 'flex',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(21, 21, 21, 0.15)',
    '.ogp-image': {
      height: 180,
      width: 340,
      borderRadius: 8,
      marginRight: 16,
      '.ogp-title': {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.46px',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'unset',
      '.ogp-image': {
        width: '100%',
        marginBottom: '8px',
      },
    },
  },
  infoBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '.wrapperButton': {
      [theme.breakpoints.down('md')]: {
        marginTop: '8px',
      },
      [theme.breakpoints.up('md')]: {
        alignSelf: 'flex-end',
      },
    },
    [theme.breakpoints.down('md')]: {
      '.MuiButton-root': {
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: '100% !important',
        textOverflow: 'ellipsis',
        '&:first-of-type': {
          marginBottom: '8px',
        },
      },
    },
  },
  info: {
    display: 'flex',
    height: '269px',
    fontWeight: 400,
    h6: {
      fontSize: 16,
      lineHeight: '20px',
    },
    span: {
      fontSize: 12,
      marginTop: 8,
      lineHeight: '133.4%',
    },
    '& > div': {
      flex: 1,
      borderRadius: '8px',
      border: '1px solid rgba(21, 21, 21, 0.15)',
      overflow: 'hidden',
      '&:not(:last-of-type)': {
        marginRight: 16,
        padding: 16,
        '& > div': {
          marginBottom: 16,
        },
      },
      '&:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& .banner': {
          width: '100%',
          height: '150px',
          backgroundSize: 'cover',
        },
        '& .customizeAction': {
          flex: 1,
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > div': {
            alignSelf: 'end',
          },
        },
      },
    },
  },
  tabs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > .MuiBox-root': {
      flex: 1,
      minWidth: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: '24px',
      },
    },
  },
  editorBtn: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  editorBtnMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

type OrUndefined<T> = T | undefined;

const steps = (t: TFunction) => {
  return {
    [ShopDetailTab.BASIC]: {
      stepTitle: t('my_shop.basic'),
      value: ShopDetailTab.BASIC,
      component: <BasicSiteSetting />,
    },
    [ShopDetailTab.COLLECTION]: {
      stepTitle: t('collections_tab'),
      value: ShopDetailTab.COLLECTION,
      component: <SalesCollection />,
    },
    [ShopDetailTab.LEGAL]: {
      stepTitle: t('my_shop.shop_information.legal_information'),
      value: ShopDetailTab.LEGAL,
      component: <LegalInformation />,
    },
    [ShopDetailTab.ADVANCED]: {
      stepTitle: t('my_shop.advanced_setting'),
      value: ShopDetailTab.ADVANCED,
      component: <AdvancedSetting />,
    },
  };
};

const ShopDetail: FC = () => {
  const { data, loading } = useShopDetail();
  const { setFormData } = useMyShopFormWrapper();

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const { showError } = useNotify();

  const [areDataUpdated, setAreDataUpdated] = useState(false);
  const [tab, setTab] = useState(ShopDetailTab.BASIC);

  const isMemberSite = data?.shopType === SHOP_TYPE.MEMBER;

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: string) => {
    setTab(value as SetStateAction<ShopDetailTab>);
    navigate(`${location.pathname}?tab=${value}`);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(tabFromUrl as SetStateAction<ShopDetailTab>);
    }
  }, [location.search]);

  useEffect(() => {
    (async () => {
      try {
        if (!loading) {
          if (data) {
            const { siteSetting, shopInformation, domain, nftActivationSettings, paymentMethod, paymentConfigUuid } =
              data;
            setFormData({
              paymentConfigUuid: paymentConfigUuid || '',
              siteSetting: {
                ogpFile: undefined,
                logoFile: undefined,
                faviconFile: undefined,
                domainName: domain?.name!,
                ogp: siteSetting?.ogp ?? '',
                logo: siteSetting?.logo ?? '',
                name: siteSetting?.name ?? '',
                meta: siteSetting?.meta ?? '',
                siteName: siteSetting?.title!,
                favicon: siteSetting?.favicon ?? '',
                showPriceUsd: !!siteSetting?.showPriceUsd,
                metaDescription: siteSetting?.description!,
                category: siteSetting?.category as NFT_SHOP_TYPE,
              },
              shopInformation: {
                policy: shopInformation?.policy as string,
                terms: shopInformation?.terms as string,
                information: shopInformation?.information as string,
              },
              paymentMethod: {
                baseCurrency: paymentMethod?.baseCurrency,
              },
              nftActivationSettings: {
                acquisitionInformation: nftActivationSettings?.acquisitionInformation.filter(
                  (info) => !['LAST_NAME', 'FIRST_NAME'].includes(info.type!)
                ) as AcquisitionInformation[],
                expireUnit: nftActivationSettings?.expireUnit as Unit,
                expireMethod: nftActivationSettings?.expireMethod as ExpireDateMethod,
                expireValue: nftActivationSettings?.expireValue as OrUndefined<string>,
                expireDate: !!nftActivationSettings?.expireDate ? dayjs(nftActivationSettings?.expireDate) : null,
              },
            });
            setAreDataUpdated(true);
          } else {
            navigate(AppRouteEnum.MyShop);
          }
        }
      } catch (err) {
        showError(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t(isMemberSite ? 'member_site.member_site' : 'my_shop.shops')}
        </Link>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {data?.siteSetting?.title || ''}
        </Typography>
      </Breadcrumbs>
      <LinkButton
        variant="outlined"
        endIcon={<ChevronRightIcon />}
        to={AppRouteEnum.EditShop.replace(/:id/g, id || '')}
        className={classes.editorBtnMobile}
      >
        {t('design_editor')}
      </LinkButton>
      <Box className={classes.tabs}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" scrollButtons={false} value={tab} onChange={handleChangeTab}>
            {Object.values(steps(t)).map((tab) => (
              <Tab key={tab.stepTitle} label={tab.stepTitle} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <LinkButton
          variant="outlined"
          endIcon={<ChevronRightIcon />}
          to={AppRouteEnum.EditShop.replace(/:id/g, id || '')}
          className={classes.editorBtn}
        >
          {t('design_editor')}
        </LinkButton>
      </Box>
      {areDataUpdated ? <Box className={classes.wrapper}>{steps(t)[tab].component}</Box> : <LoaderCenter />}
    </>
  );
};

export default memo(ShopDetail);
