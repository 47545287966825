import { WithApolloClient } from '~/graphql/client';
import { WithSession } from '~/hooks/with-session';

const WrapperPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <WithApolloClient>
      <WithSession>{children}</WithSession>
    </WithApolloClient>
  );
};

export default WrapperPage;
