import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import AuthLayout from '~/components/auth-layout';

interface Props {
  actions?: ReactNode;
  code: '401' | '404' | '403';
}

const CODE = {
  '401': {
    message: 'not_found',
  },
  '404': {
    message: 'not_found',
  },
  '403': {
    message: 'permission_denied',
  },
};

const ErrorPage: React.FC<Props> = (props) => {
  const { code, actions } = props;
  const { t } = useTranslation();
  return (
    <AuthLayout headerTitle={t(CODE[code].message)} hideLanguage>
      <div>
        {t('error_code')}: {code}
      </div>
      {actions}
    </AuthLayout>
  );
};

export default ErrorPage;
