import { useEffect } from 'react';

import { useRoutes, useLocation, RouteObject } from 'react-router-dom';

import PermissionsView from '../components/PermissionsView';

import WrapperPage from '~/components/WrapperPage';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import MyShopFormWrapper from '~/contexts/MyShopFormWrapper';
import PrivateWrapper from '~/contexts/PrivateWrapper';
import ShopDetailWrapper from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { WithoutAccount } from '~/hooks/without-account';
import AboutPage from '~/pages/about';
import CollectionsPage from '~/pages/collection';
import CollectionDetail from '~/pages/collection/CollectionDetail';
import EditShop from '~/pages/edit-shop';
import ErrorPage from '~/pages/error';
import HomePage from '~/pages/home';
import MyShop from '~/pages/my-shop';
import CreateMemberSite from '~/pages/my-shop/create-member-site';
import CreateShop from '~/pages/my-shop/create-shop';
import MemberSiteDetail from '~/pages/my-shop/member-site-detail';
import WrapperMemberSiteCollectionDetail from '~/pages/my-shop/member-site-detail/components/CollectionDetail/WrapperMemberSiteCollectionDetail';
import MemberDetail from '~/pages/my-shop/member-site-detail/components/MemberDetail';
import ShopDetail from '~/pages/my-shop/shop-detail';
import ShopCollectionDetail from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import OidcCallbackPage from '~/pages/oidc-callback';
import Plans from '~/pages/plans';
import SettingPage from '~/pages/setting';
import SignInPage from '~/pages/sign-in';
import { removeParentPath } from '~/utils/common';

const { SHOPS, MEMBER_SITES, SETTING, PLANS } = SCREEN_PERMISSION;

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.OidcCallback,
    element: <OidcCallbackPage />,
  },
  {
    path: AppRouteEnum.SignIn,
    element: (
      <WrapperPage>
        <WithoutAccount>
          <SignInPage />,
        </WithoutAccount>
      </WrapperPage>
    ),
  },
  {
    path: AppRouteEnum.Home,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: removeParentPath(AppRouteEnum.Home, AppRouteEnum.Plans),
        element: (
          <PermissionsView roles={PLANS.VIEW} children403={<ErrorPage code="403" />}>
            <Plans />
          </PermissionsView>
        ),
      },
      {
        path: removeParentPath(AppRouteEnum.Home, AppRouteEnum.MyShop),
        element: <PermissionsView roles={SHOPS.VIEW} children403={<ErrorPage code="403" />} />,
        children: [
          {
            index: true,
            element: <MyShop />,
          },
          {
            path: removeParentPath(AppRouteEnum.MyShop, AppRouteEnum.CreateShop),
            element: (
              <MyShopFormWrapper>
                <CreateShop />
              </MyShopFormWrapper>
            ),
          },
          {
            path: removeParentPath(AppRouteEnum.MyShop, AppRouteEnum.ShopDetail),
            element: <ShopDetailWrapper />,
            children: [
              {
                index: true,
                element: (
                  <MyShopFormWrapper>
                    <ShopDetail />
                  </MyShopFormWrapper>
                ),
              },
              {
                path: removeParentPath(AppRouteEnum.ShopDetail, AppRouteEnum.EditShop),
                element: <EditShop />,
              },
              {
                path: removeParentPath(AppRouteEnum.ShopDetail, AppRouteEnum.ShopCollectionDetail),
                element: <ShopCollectionDetail />,
              },
            ],
          },
        ],
      },
      {
        path: removeParentPath(AppRouteEnum.Home, AppRouteEnum.MemberSite),
        element: <PermissionsView roles={MEMBER_SITES.VIEW} children403={<ErrorPage code="403" />} />,
        children: [
          {
            index: true,
            element: <MyShop />,
          },
          {
            path: removeParentPath(AppRouteEnum.MemberSite, AppRouteEnum.CreateMemberSite),
            element: (
              <MyShopFormWrapper>
                <CreateMemberSite />
              </MyShopFormWrapper>
            ),
          },
          {
            path: removeParentPath(AppRouteEnum.MemberSite, AppRouteEnum.MemberSiteDetail),
            element: <ShopDetailWrapper />,
            children: [
              {
                index: true,
                element: (
                  <MyShopFormWrapper>
                    <MemberSiteDetail />
                  </MyShopFormWrapper>
                ),
              },
              {
                path: removeParentPath(AppRouteEnum.MemberSiteDetail, AppRouteEnum.EditMemberSite),
                element: <EditShop />,
              },
              {
                path: removeParentPath(AppRouteEnum.MemberSiteDetail, AppRouteEnum.MemberSiteCollectionDetail),
                element: <WrapperMemberSiteCollectionDetail />,
              },
              {
                path: removeParentPath(AppRouteEnum.MemberSiteDetail, AppRouteEnum.MemberSiteMemberDetail),
                element: <MemberDetail />,
              },
            ],
          },
        ],
      },
      {
        path: removeParentPath(AppRouteEnum.Home, AppRouteEnum.Collection),
        children: [
          {
            index: true,
            element: <CollectionsPage />,
          },
          {
            path: removeParentPath(AppRouteEnum.Collection, AppRouteEnum.CollectionDetail),
            element: <CollectionDetail />,
          },
        ],
      },
      {
        path: removeParentPath(AppRouteEnum.Home, AppRouteEnum.Setting),
        element: (
          <PermissionsView roles={SETTING.VIEW} children403={<ErrorPage code="403" />}>
            <SettingPage />
          </PermissionsView>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage code="404" />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
