import { makeStyles } from 'tss-react/mui';

export const useBasicSiteSettingStyles = makeStyles<{ isCreate?: boolean }>()((theme, { isCreate }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root': {
      marginBottom: '24px',
      '& > .MuiCardHeader-root': {
        minHeight: '52px',
        padding: '8px 16px',
        borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
        [theme.breakpoints.up('sm')]: {
          padding: '0px 16px',
        },
        '.MuiCardHeader-action': {
          alignSelf: 'center',
        },
      },
      '.MuiCardHeader-title': {
        display: isCreate ? '' : 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'unset',
        },
      },
    },
    '.MuiCardContent-root': {
      gap: '16px',
      padding: '16px 16px 0',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
      '.cardContent': {
        '&__left': {
          gap: '16px',
          width: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&-wrapperSiteImage': {
            '&--siteImage': {
              aspectRatio: '408 / 235',
              '.MuiFormControl-root': {
                height: '100%',
              },
            },
          },
          '&-wrapperLogo': {
            gap: '16px',
            display: 'flex',
            '&--item': {
              flex: 1,
              '& > .MuiBox-root': {
                aspectRatio: '1 / 1',
                '.MuiFormControl-root': {
                  height: '100%',
                },
              },
            },
          },
        },
        '&__right': {
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          '&-wrapperInput': {
            '.MuiTextField-root': {
              '.MuiInputAdornment-root': {
                '.MuiTypography-root': {
                  fontSize: '16px',
                },
                '.MuiTypography-caption': {
                  fontSize: '12px',
                  [theme.breakpoints.up('sm')]: {
                    fontSize: '18px',
                  },
                },
              },
            },
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
    '.MuiCardHeader-action': {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  cardHeader: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));
